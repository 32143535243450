function getParameterByName(name: string, url = window.location.href) {
	const transformedName = name.replace(/[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${transformedName}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default getParameterByName;
