import React from 'react';
import '../../assets/scss/Components/GalleryComponent.scss';
import { getCurrentRadius } from '../../helpers';
import SwiperGallery from '../SwiperGallery/SwiperGallery';

const GalleryComponent = ({ images, paginationColor, style }: any) => (
	<div
		className='gallery-component'
		style={{
			borderRadius: getCurrentRadius(style.borderRadius),
		}}
	>
		<SwiperGallery
			images={images}
			paginationColor={paginationColor}
			borderRadius={getCurrentRadius(style.borderRadius)}
		/>
	</div>
);

export default GalleryComponent;
