import React from 'react';

export function useFetch(request: () => Promise<unknown>, initData: object) {
	const defaultData = { data: initData, loading: false, error: false };

	const [fetchData, setFetchData] = React.useState({
		...defaultData,
	});

	const fetching = async () => {
		setFetchData({
			...defaultData,
			loading: true,
		});

		await request()
			.then(({ data }: any) => {
				setFetchData({
					...defaultData,
					data,
				});
			})
			.catch((error: any) => {
				if (error.message === undefined) {
					// if request was canceled
					setFetchData({
						...defaultData,
						loading: true,
						error: error.message,
					});
				} else {
					setFetchData({
						...defaultData,
						error: error.message,
					});
				}
			});
	};

	return [fetching, fetchData];
}
