import React from 'react';
import type { IItem } from '../../../types';
import { Item } from '../Item';
import styles from './WithoutCategory.module.scss';

// Types
interface IProps {
	category: {
		id: string;
		items: IItem[];
	};
}

export const WithoutCategory: React.FC<IProps> = ({ category }) => {
	const { id, items } = category;

	return (
		<div id={id} className={styles['category']}>
			<div className={styles['category__list']}>
				{items.map(item => (
					<Item key={item.id} item={item} />
				))}
			</div>
		</div>
	);
};
