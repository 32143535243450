import React from 'react';
import { DynamicStar } from 'react-dynamic-star';
import { ReactComponent as RatingIcon } from '../../../assets/svg/googleRating.svg';
import { ReactComponent as PinIcon } from '../../../assets/svg/pin.svg';
import styles from './GoogleInformation.module.scss';

// Props Types
type DataTypes = {
	googleMapsUrl: string;
	address: string;
	rating: number;
};

interface GoogleInformationProps {
	data: DataTypes | null;
	style: any;
}

export const GoogleInformation: React.FC<GoogleInformationProps> = ({
	data,
	style,
}) => {
	const { googleMapsUrl, address, rating } = data || {
		googleMapsUrl: '',
		address: '',
		rating: 0,
	};
	const color = style?.page?.color;
	const align = style?.page?.align;

	return (
		<div
			data-testid='GoogleInformation'
			className={styles['google']}
			style={{
				color,
				alignItems: align === 'left' ? 'flex-start' : 'center',
			}}
		>
			{rating !== 0 ? (
				<div
					data-testid='GoogleInformation-rating'
					className={`${styles['google__rating']} ${styles['rating']}`}
				>
					<span className={styles['rating__number']}>{rating}</span>
					<DynamicStar
						rating={rating}
						outlined
						width={13}
						height={13}
						fullStarColor={color}
						emptyStarColor='transparent'
					/>
					<RatingIcon className={styles['rating__icon']} />
				</div>
			) : (
				''
			)}
			{address ? (
				<div className={`${styles['google__address']} ${styles['address']}`}>
					<PinIcon className={styles['address__icon']} />
					<a
						data-testid='GoogleInformation-link'
						className={styles['address__link']}
						href={googleMapsUrl}
						target='_blank'
						rel='noreferrer'
						title={address}
					>
						{address}
					</a>
				</div>
			) : (
				''
			)}
		</div>
	);
};
