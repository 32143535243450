import React from 'react';
import { isDesktop } from 'react-device-detect';
import { OPEN_LINK_IN_EDITOR } from '../../../../../../../constants/postMessageNames';
import { capitalizeFirstLetter } from '../../../../../../../helpers';
import getParameterByName from '../../../../../../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../../../../../../hooks';
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import { sendPostMessage } from '../../../../../../../services/sendPostMessage';
import { SvgInline } from '../SvgInline';
import styles from './Link.module.scss';

// Props Types
interface LinkProps {
	data: any;
	linkData: any;
	style: any;
	getCurrentStyles: any;
	openPopup: any;
}

export const Link: React.FC<LinkProps> = ({
	data,
	style,
	getCurrentStyles,
	linkData,
	openPopup,
}) => {
	const { button, url } = linkData;

	// States
	const [textDots, setTextDots] = React.useState(false);

	// Helpers
	const windowSize = useWindowSize();
	const refTextDummy: any = React.useRef(null);
	const refLink: any = React.useRef(null);
	const isGradient = style?.background?.indexOf('linear-gradient') > -1;
	// - hooks
	const sendAnalyticsAndOpenLink: (event: any) => void = useSendAnalyticsEvent({
		type: data.type,
		url,
		data: {
			blockId: data.id,
			eventAction: 'out-click',
		},
	});
	// - methods
	const getTextGradientStyles = () => {
		if (isGradient) {
			return {
				background: style.background,
				WebkitTextFillColor: 'transparent',
			};
		}

		return {
			background: getCurrentStyles('background', style),
		};
	};
	const getLinkAttr = () => {
		if (url !== 'popup' && isDesktop) {
			return { href: url, target: '_blank' };
		}
		return {};
	};

	// Actions
	const onClick = (event: any) => {
		if (url === 'popup') {
			openPopup();

			return;
		}

		if (getParameterByName('previewMode')) {
			event?.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url });

			return;
		}

		sendAnalyticsAndOpenLink(event);
	};

	// Lifecycle hooks
	React.useEffect(() => {
		if (refLink?.current && refTextDummy?.current) {
			const extraOffset = 30;

			const parent =
				(refLink.current?.getBoundingClientRect().width || 0) - extraOffset;
			const children = refTextDummy.current?.getBoundingClientRect().width;

			setTextDots(children > parent);
		}
	}, [button.value, windowSize.width]);

	return (
		<a
			{...getLinkAttr()}
			ref={refLink}
			onClick={onClick}
			className={styles['link']}
			style={{
				background: getCurrentStyles('background', style),
				color: getCurrentStyles('color', style),
			}}
		>
			{button.type === 'image' ? (
				<SvgInline
					url={button.value.monochrome}
					classes={styles['link__image']}
					color={getCurrentStyles('color', style)}
					isGradient={isGradient}
				/>
			) : (
				<span
					className={`
            ${styles['link__text']}
            ${textDots ? styles['link__text_dots'] : ''}
          `}
					style={{
						...getTextGradientStyles(),
					}}
					title={button.value.length ? button.value : 'Link'}
				>
					{/* needed to get the actual width of the text, for dots */}
					<span ref={refTextDummy} className={styles['link__text-dummy']}>
						{button.value.length ? button.value : 'Link'}
					</span>
					<span className={styles['link__text-original']}>
						{button.value.length ? capitalizeFirstLetter(button.value) : 'Link'}
					</span>
				</span>
			)}
		</a>
	);
};
