import React from 'react';
import { capitalizeFirstLetter } from '../../../helpers';

type HourTypes = {
	hour: number | string;
	minute: number | string;
};
type FromToTypes = {
	from: HourTypes;
	to: HourTypes;
};
type WeeksTypes = {
	[day: string]: FromToTypes | null;
};
type DataTypes = {
	format: string;
	weeks: WeeksTypes;
};

const days = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
];
const currentDay = days[new Date().getDay()];

function timeConvert(time: string) {
	// Check correct time format and split into components
	let newTime = time
		.toString()
		.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

	if (newTime.length > 1) {
		// If time format correct
		// @ts-ignore
		newTime = newTime.slice(1); // Remove full string match value
		newTime[5] = +newTime[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
		newTime[0] = String(+newTime[0] % 12 || 12); // Adjust hours
	}
	return newTime.join(''); // return adjusted time or original string
}
function sortDaysByToday(): string[] {
	const currentDayIndex = days.indexOf(currentDay);
	return days.slice(currentDayIndex).concat(days.slice(0, currentDayIndex));
}

function normalizedFormat(time: string, format: string): string {
	if (format === '24H') {
		return time;
	}

	return timeConvert(time);
}
function normalizedTime(direction: string, units: string, hours: any): string {
	return hours[direction][units].toString().length === 1
		? `0${hours[direction][units]}`
		: hours[direction][units];
}
function normalizedHours(hours: FromToTypes | null, format: string): string {
	let hoursObject: { from: string; to: string } = {
		from: '',
		to: '',
	};

	if (hours) {
		Object.keys(hours).forEach(timeType => {
			hoursObject = {
				...hoursObject,
				[timeType]: `${normalizedTime(
					timeType,
					'hour',
					hours,
				)}:${normalizedTime(timeType, 'minute', hours)}`,
			};
		});
	}

	return `${normalizedFormat(hoursObject.from, format)} - ${normalizedFormat(
		hoursObject.to,
		format,
	)}`;
}

function getTodayDayName(shortFormat?: boolean): string {
	if (shortFormat) {
		return capitalizeFirstLetter(currentDay.slice(0, 3));
	}

	return capitalizeFirstLetter(currentDay);
}
function getTodayOpeningHours(weeks: WeeksTypes, format: string): string {
	const todayOpeningHours = weeks[getTodayDayName().toLowerCase()];

	if (todayOpeningHours) {
		return normalizedHours(todayOpeningHours, format);
	}

	return 'Closed';
}
function getTodayTemplate(weeks: WeeksTypes, format: string): string {
	if (getTodayOpeningHours(weeks, format) === 'Closed') {
		return `${getTodayDayName(true)} - ${getTodayOpeningHours(weeks, format)}`;
	}

	return `${getTodayDayName(true)} ${getTodayOpeningHours(weeks, format)}`;
}

export const useGetOpeningHours: (
	data: DataTypes,
) => [string, { day: string; time: string }[]] = (data: DataTypes) => {
	const [openingHoursToday, setOpeningHoursToday] = React.useState<string>('');
	const [openingHours, setOpeningHours] = React.useState<
		{ day: string; time: string }[]
	>([]);

	React.useEffect(() => {
		setOpeningHoursToday(getTodayTemplate(data.weeks, data.format));

		const normalizedOpeningHours = sortDaysByToday().map(day => ({
			day,
			time: data.weeks[day]
				? normalizedHours(data.weeks[day], data.format)
				: 'Closed',
		}));
		setOpeningHours(normalizedOpeningHours);
	}, [data]);

	return [openingHoursToday, openingHours];
};
