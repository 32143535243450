import { motion } from 'framer-motion';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { mainTransition } from '../../../../framer-motion/transition';
import { Button } from '../../../elements/Button';
import styles from './Success.module.scss';

interface SuccessProps {
	resetReport: () => void;
}
const contentAnimation = {
	enter: {
		opacity: 0,
		y: 25,
	},
	animate: {
		opacity: 1,
		y: 0,
	},
	exit: {
		opacity: 0,
		y: 25,
	},
};

export const Success: React.FC<SuccessProps> = React.forwardRef(
	({ resetReport }, ref: any) => {
		React.useEffect(() => () => resetReport(), []);

		return (
			<div
				ref={ref}
				className={`
        ${styles['success']}
        ${isMobile ? styles['success_mobile'] : ''}
      `}
			>
				<motion.div
					className={styles['success__content']}
					variants={contentAnimation}
					transition={{
						...mainTransition,
						mass: 0.5,
					}}
				>
					<div className={styles['success__icon']}>✊</div>
					<div className={styles['success__title']}>your Report submitted</div>
				</motion.div>
				<Button
					text='Ok, great'
					onclick={resetReport}
					classes={{
						button: styles['success__button'],
					}}
				/>
			</div>
		);
	},
);

export const MotionSuccess = motion(Success);
