import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useEffect, useState } from 'react';
import '../../assets/scss/Components/ModalGallery.scss';
import { OPEN_MODAL_IN_EDITOR } from '../../constants/postMessageNames';
import { swipePower } from '../../helpers';
import { sendPostMessage } from '../../services/sendPostMessage';

type ModalGalleryItemProps = {
	images: any[];
	isVisible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	slide: number;
};

export const variants = {
	enter: (direction: number) => ({
		x: direction > 0 ? 1000 : -1000,
		opacity: 0,
	}),
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1,
	},
	exit: (direction: number) => ({
		zIndex: 0,
		x: direction < 0 ? 1000 : -1000,
		opacity: 0,
	}),
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;

const ModalGallery: React.FC<ModalGalleryItemProps> = ({
	slide,
	isVisible,
	setVisible,
	images,
}) => {
	const [[page, direction], setPage] = useState([slide, 0]);
	useEffect(() => {
		setPage([slide, 0]);
	}, [slide]);

	const imageIndex = wrap(0, images.length, page);
	const paginate = (newDirection: number) => {
		setPage([page + newDirection, newDirection]);
	};
	const closePopup = () => {
		setVisible(false);
		sendPostMessage(OPEN_MODAL_IN_EDITOR, { isOpen: false });
	};
	return (
		<AnimatePresence custom={direction} exitBeforeEnter>
			{isVisible ? (
				<motion.div
					key='modal-gallery'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className='modal-gallery'
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					transition={{ duration: 0.5 }}
				>
					<div className='inner-container'>
						<button
							type='button'
							className='close-btn'
							onClick={closePopup}
							data-testid='close-btn'
						>
							&#10005;
						</button>
						<motion.img
							key={imageIndex}
							src={images[imageIndex].image}
							custom={direction}
							variants={variants}
							initial='enter'
							animate='center'
							exit='exit'
							transition={{
								x: { type: 'spring', stiffness: 300, damping: 30 },
								opacity: { duration: 0.2 },
							}}
							drag='x'
							dragConstraints={{ left: 0, right: 0 }}
							dragElastic={1}
							onDragEnd={(e, { offset, velocity }) => {
								const swipe = swipePower(offset.x, velocity.x);
								if (swipe < -swipeConfidenceThreshold) {
									paginate(1);
								}
								if (swipe > swipeConfidenceThreshold) {
									paginate(-1);
								}
							}}
						/>
						<div className='controls'>
							<div
								data-testid='next'
								className='next'
								onClick={() => paginate(1)}
							>
								‣
							</div>
							<div
								data-testid='prev'
								className='prev'
								onClick={() => paginate(-1)}
							>
								‣
							</div>
						</div>
					</div>
				</motion.div>
			) : (
				''
			)}
		</AnimatePresence>
	);
};

export default ModalGallery;
