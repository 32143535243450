import { sendEvent, setDeviceId, setUserProperty } from '../helpers/amplitude';
import getParameterByName from '../helpers/getParameterByName';

export async function useAmplitudeForABTest() {
	await setDeviceId(getParameterByName('uuid') || '');
	await setUserProperty({
		utm_source: getParameterByName('utm_source'),
		utm_campaign: getParameterByName('utm_campaign') || '',
		utm_user_referrer: getParameterByName('utm_user_referrer') || '',
	});
	sendEvent('landing shown');
}
