import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import defaultImage from '../../../../../../assets/images/Image_sceleton.jpg';
import { ReactComponent as ShopIcon } from '../../../../../../assets/svg/shoppble.svg';
import { useIsPortrait } from '../../../../../../hooks/useCustomMediaQuery';
import useWasSeen from '../../../../../../hooks/useWasSeen';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import styles from './ShopCardZone.module.scss';

// Props Types
interface ShopCardZoneProps {
	data: any;
	openPopup: any;
}

export const ShopCardZone: React.FC<ShopCardZoneProps> = ({
	data,
	openPopup,
}) => {
	const { image, tags } = data || {};

	const { width } = useWindowSize();
	const zone: any = useRef(null);
	const [tagWidth, setTagWidth] = useState(-100);
	const [tagHeight, setTagHeight] = useState(-100);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const isPortrait = useIsPortrait();

	const [wasSeen, ref] = useWasSeen();

	useEffect(() => {
		if (imagesLoaded) {
			setTagHeight(zone.current.offsetHeight);
			setTagWidth(zone.current.offsetWidth);
		}
	}, [imagesLoaded, width, isPortrait]);

	return (
		<div
			className={styles['shop-card-zone']}
			onClick={() => openPopup(1)}
			ref={zone}
		>
			<div className={styles['shop-card-zone__tags']}>
				<AnimatePresence>
					{tags?.map((tag: any) => {
						if (!tag?.hidden && wasSeen && imagesLoaded) {
							return (
								<motion.div
									key={tag.id}
									initial={{
										scale: 0,
									}}
									animate={{
										scale: 1,
										x: tag.pointCoordinates.x * tagWidth - 22,
										y: tag.pointCoordinates.y * tagHeight - 22,
										transition: {
											scale: {
												type: 'spring',
												duration: 0.5,
												delay: 1,
											},
											x: {
												type: 'spring',
												duration: 0.5,
											},
											y: {
												type: 'spring',
												duration: 0.5,
											},
										},
									}}
									exit={{
										scale: 0,
									}}
									onClick={e => {
										e.stopPropagation();
										openPopup(tag.position);
									}}
									className={imagesLoaded ? styles['shop-card-zone__tag'] : ''}
								/>
							);
						}
						return '';
					})}
				</AnimatePresence>
			</div>
			<img
				className={styles['shop-card-zone__image']}
				onLoad={() => setImagesLoaded(true)}
				src={imagesLoaded ? image : defaultImage}
				alt='img'
			/>
			<div ref={ref} className={styles['shop-card-zone__icon']}>
				<ShopIcon
					className={`${styles['shop-card-zone__icon-svg']} ${styles['shop-card-zone__icon-svg_shop']}`}
				/>
			</div>
		</div>
	);
};
