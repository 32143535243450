import React from 'react';
import SVG from 'react-inlinesvg';
import styles from './SvgInline.module.scss';

// Props Types
interface SvgInlineProps {
	url: string;
	classes?: string;
	color?: string;
	isGradient?: boolean;
}

// const svg
export const SvgInline: React.FC<SvgInlineProps> = ({
	url,
	classes,
	color,
	isGradient,
}) => (
	<span
		className={`
        ${styles['svg-inline']}
        ${isGradient ? styles['svg-inline_hide'] : ''}
        ${classes || ''}
     `}
		style={
			isGradient
				? {
						WebkitMaskImage: `url(${url})`,
						background: color,
				  }
				: {}
		}
	>
		<SVG
			key={color}
			className={styles['svg-inline__icon']}
			src={url}
			preProcessor={icon => {
				const regex = /((?:fill)="(?!none)[#a-zA-Z0-9]+")/g;
				return icon.replace(regex, `fill="${color}"`);
			}}
		/>
	</span>
);
