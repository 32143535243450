import axios, { CancelToken } from 'axios';

const requestInstance = (
	url: string,
	data: any,
	method: any,
	cancelToken?: CancelToken,
) =>
	axios({
		method,
		url,
		data,
		cancelToken,
	});

export default requestInstance;
