import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { EffectCards, Lazy, Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import '../../assets/scss/Components/SwiperGallery.scss';
import { OPEN_MODAL_IN_EDITOR } from '../../constants/postMessageNames';
import { sendPostMessage } from '../../services/sendPostMessage';
import ModalGallery from '../ModalGallery/ModalGallery';
import SwipeGalleryItem from '../SwipeGalleryItem/SwipeGalleryItem';
import 'swiper/modules/effect-cards/effect-cards.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.scss';

const SwiperGallery = ({ images, paginationColor, borderRadius }: any) => {
	const [isVisible, setVisible] = useState(false);
	const [activeSlide, setActiveSlide] = useState(0);

	const openPopUp = (e: any, slide: number) => {
		e.stopPropagation();
		setActiveSlide(slide);
		setVisible(true);
		sendPostMessage(OPEN_MODAL_IN_EDITOR, { isOpen: true });
	};

	useEffect(() => {
		if (isVisible) {
			document.body.classList.add('removeScroll');
		} else {
			document.body.classList.remove('removeScroll');
		}
	}, [isVisible]);

	return (
		<div className='swiper-gallery'>
			<style>
				{`
           .dot {
              border-color: ${paginationColor} !important
           }
           .dot-active {
              background-color: ${paginationColor} !important
           }
        `}
			</style>
			<ModalGallery
				images={images}
				isVisible={isVisible}
				setVisible={setVisible}
				slide={activeSlide}
			/>
			<Swiper
				initialSlide={activeSlide}
				loop={false}
				lazy={{
					loadPrevNext: true,
					loadPrevNextAmount: 1,
				}}
				pagination={{
					clickable: true,
					bulletActiveClass: 'dot-active',
					bulletClass: 'dot',
				}}
				effect='cards'
				cardsEffect={{ rotate: false }}
				grabCursor={isMobile}
				simulateTouch={isMobile}
				allowTouchMove={isMobile}
				noSwiping={isMobile}
				mousewheel={
					!isMobile
						? {
								forceToAxis: true,
								sensitivity: 3,
								thresholdDelta: 0,
								thresholdTime: 0,
						  }
						: false
				}
				speed={650}
				roundLengths
				modules={[EffectCards, Pagination, Lazy, Mousewheel]}
				className='mySwiper'
			>
				{images.map((image: any, index: number) => (
					<SwiperSlide
						key={image.id}
						data-testid='open-pop-up'
						onClick={e => openPopUp(e, index)}
					>
						<SwipeGalleryItem
							key={image.id}
							image={images[index].image}
							borderRadius={borderRadius}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default SwiperGallery;
