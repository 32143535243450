import React from 'react';
import { isDesktop } from 'react-device-detect';
import { ReactComponent as ShopIcon } from '../../../../../assets/svg/shoppble.svg';
import { OPEN_LINK_IN_EDITOR } from '../../../../../constants/postMessageNames';
import {
	beautifyPrice,
	getCurrentBorder,
	getCurrentRadius,
} from '../../../../../helpers';
import getParameterByName from '../../../../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../../../../hooks';
import { sendPostMessage } from '../../../../../services/sendPostMessage';
import styles from './SingleProduct.module.scss';

interface SingleProductProps {
	type: 'single' | 'grid' | 'carousel';
	style: any;
	data: any;
	blockId?: string;
}

export const SingleProduct: React.FC<SingleProductProps> = ({
	type,
	style,
	data,
	blockId,
}) => {
	const isSingleProduct = type === 'single';
	const imageBlur = style.buttonStylesAdditionally === 'default';

	const { title, description, price, url, image, id } = data || {};
	const normalizedAnalyticsData: any = () => {
		if (isSingleProduct && !blockId) {
			return {
				blockId: id,
				eventAction: 'out-click',
			};
		}

		return {
			blockId,
			eventAction: 'out-click',
			subBlockId: id,
		};
	};
	const sendAnalyticsAndOpenLink: (event: any) => void = useSendAnalyticsEvent({
		type: data?.type,
		url,
		data: {
			...normalizedAnalyticsData(),
		},
	});
	const getLinkAttr = () => {
		if (isDesktop) {
			return { href: url, target: '_blank', rel: 'noreferrer' };
		}
		return {};
	};

	const onClick = (event: any) => {
		if (getParameterByName('previewMode')) {
			event.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url });

			return;
		}

		sendAnalyticsAndOpenLink(event);
	};

	const productContent = (
		<>
			<div
				className={`
          ${styles['product__header']}
          ${imageBlur ? styles['product__header_blur'] : ''}
        `}
			>
				<img className={styles['product__image']} src={image} alt={title} />
				{price?.value ? (
					<div className={styles['product__icon']}>
						<ShopIcon
							className={`${styles['product__icon-svg']} ${styles['product__icon-svg_shop']}`}
						/>
					</div>
				) : (
					''
				)}
			</div>
			<div
				className={styles['product__body']}
				style={{
					background: style.background,
				}}
			>
				<h4
					className={`${styles['product__title']} ${
						!description && price?.value === 0
							? styles['product__title_no-price']
							: ''
					}`}
				>
					{title}
				</h4>

				{description && isSingleProduct ? (
					<p
						className={`${styles['product__description']}  ${
							price?.value === 0 ? styles['product__description_no-price'] : ''
						}`}
					>
						{description}
					</p>
				) : (
					''
				)}

				{price?.value !== 0 ? (
					<p className={styles['product__price']} title={price?.currency}>
						<span className={styles['product__price-currency']}>
							{price?.currencySymbol}
						</span>{' '}
						<span className={styles['product__price-number']}>
							{beautifyPrice(price?.value)}
						</span>
					</p>
				) : (
					''
				)}
			</div>
		</>
	);

	return url ? (
		<a
			{...getLinkAttr()}
			className={`
        ${styles['product']}
        ${styles[`product_${type}`]}
      `}
			data-testid='product'
			style={{
				boxShadow: style.boxShadow,
				color: style.color,
				borderRadius: getCurrentRadius(style.borderRadius),
				border: getCurrentBorder(style),
			}}
			onClick={onClick}
		>
			{productContent}
		</a>
	) : (
		<div
			className={`
        ${styles['product']}
        ${styles[`product_${type}`]}
        ${styles['product_no-hover']}
      `}
			style={{
				boxShadow: style.boxShadow,
				color: style.color,
				borderRadius: getCurrentRadius(style.borderRadius),
				border: getCurrentBorder(style),
			}}
		>
			{productContent}
		</div>
	);
};
