import React from 'react';

export function useDebounce(callback: any, delay: number) {
	const timer = React.useRef();

	return React.useCallback(
		(...args) => {
			if (timer.current) {
				clearTimeout(timer.current);
			}

			// @ts-ignore
			timer.current = setTimeout(() => {
				callback(...args);
			}, delay);
		},
		[callback, delay],
	);
}
