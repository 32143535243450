import { motion } from 'framer-motion';
import React from 'react';
import { beautifyPrice } from '../../../../../../../helpers';
import type { IItem } from '../../../types';
import { usePopupContext } from '../../context';
import { Diet } from '../Diet';
import styles from './Item.module.scss';

// Types
interface IProps {
	item: IItem;
}

export const Item: React.FC<IProps> = ({ item }) => {
	const { id, title, dietaryInfo, description, price, image } = item;

	// Context
	const { updateActiveItem, currency, currencySymbol } = usePopupContext();

	// Actions
	const onClick = () => {
		updateActiveItem(true, item);
	};

	return (
		<motion.button
			initial='init'
			whileHover='hover'
			whileTap='tap'
			id={id}
			type='button'
			onClick={onClick}
			className={`
        ${styles['item']}
        ${!image ? styles['item_no-image'] : ''}
     `}
		>
			<div className={styles['item__info']}>
				<div className={styles['item__wrapper']}>
					<h4 className={styles['item__title']}>{title}</h4>
					<div className={styles['item__price']}>
						{currencySymbol || currency} {beautifyPrice(price)}
					</div>
					{description ? (
						<p className={styles['item__description']}>{description}</p>
					) : null}
				</div>
				{dietaryInfo.length ? (
					<Diet
						options={dietaryInfo}
						classes={{
							diet: styles['item__diet'],
							item: styles['item__diet-item'],
						}}
					/>
				) : null}
			</div>
			{image ? (
				<motion.picture
					layoutId={`product-image-${id}`}
					className={styles['item__picture']}
				>
					<motion.img
						src={image}
						alt={title}
						className={styles['item__image']}
						variants={{
							init: {
								scale: 1,
							},
							hover: {
								scale: 1.25,
							},
							tap: {
								scale: 1,
							},
						}}
						transition={{
							type: 'spring',
							bounce: 0.1,
							duration: 0.5,
						}}
					/>
				</motion.picture>
			) : null}
		</motion.button>
	);
};
