import React, { useEffect, useState } from 'react';
import oppsIcon from '../../../assets/svg/opps.svg';
import { getCurrentRadius } from '../../../helpers';
import styles from './FormError.module.scss';

interface FormErrorProps {
	stylesButtons: any;
	stylesAdditionally: string;
	borderColor: string;
	setSubmitted: React.Dispatch<React.SetStateAction<string>>;
	formHeight: number;
	className?: string;
}

export const FormError = ({
	stylesButtons,
	stylesAdditionally,
	borderColor,
	setSubmitted,
	formHeight,
	className,
}: FormErrorProps) => {
	const btnClick = () => {
		setSubmitted('');
	};
	const [innerStyles, setInnerStyles] = useState(stylesButtons);

	useEffect(() => {
		setInnerStyles((state: any) => ({
			...state,
			minHeight: formHeight,
		}));
	}, [stylesButtons]);
	return (
		<div
			className={`
        ${className ? styles[className] : ''}
        ${styles['form-error']}
        form-error
        radius-${getCurrentRadius(stylesButtons.borderRadius)}
        `}
			style={innerStyles}
		>
			<style>
				{stylesAdditionally === 'filled-with-border'
					? `.form-error  {border-color:${borderColor}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-error .form-error__button {background:${stylesButtons.background}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-error .form-error__button {color:${stylesButtons.color}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-error .form-error__button {border:${stylesButtons.border}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? '.form-error .form-error__button {border-width:2px !important}'
					: ''}
			</style>
			<div className={`${styles['content-wrapper']}`}>
				<img className={styles['form-error__avatar']} src={oppsIcon} alt='' />
				<h2 className={styles['form-error__title']}>Opps!</h2>
				<p className={styles['form-error__message']}>
					Something went wrong. Please try again later.
				</p>
			</div>
			<a
				onClick={btnClick}
				style={{
					backgroundColor: stylesButtons.color,
					color: stylesButtons.background,
					borderRadius: stylesButtons.borderRadius,
				}}
				className={`form-error__button ${styles['form-error__button']}`}
			>
				<span
					style={
						stylesButtons.background.indexOf('linear-gradient') > -1
							? { background: stylesButtons.background }
							: {}
					}
					className={`
            ${styles['form-error__button_text']}
            ${
							stylesButtons.background.indexOf('linear-gradient') > -1
								? `${styles['form-error__button_text-gradient']}`
								: ''
						}
          `}
				>
					Retry
				</span>
			</a>
		</div>
	);
};
