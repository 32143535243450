import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { mainTransition } from '../../../../framer-motion/transition';
import { MainPopup } from '../../MainPopup';
import { MobileMainPopup } from '../../MobileMainPopup';
import { Form, MotionForm } from '../Form';
import { MotionSuccess, Success } from '../Success';
import styles from './PopupWrapper.module.scss';

interface PopupWrapperProps {
	isSent: boolean;
	isOpened: boolean;
	onClose: () => void;
	resetReport: () => void;
	formDataSent: () => void;
}

// Animations
const changeAnimation = {
	enter: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};

export const PopupWrapper: React.FC<PopupWrapperProps> = ({
	resetReport,
	isSent,
	isOpened,
	onClose,
	formDataSent,
}) => {
	if (isMobile) {
		return (
			<>
				<MobileMainPopup
					name='success'
					isOpened={isSent}
					onClose={resetReport}
					classes={styles['popup-wrapper']}
				>
					<Success resetReport={resetReport} />
				</MobileMainPopup>
				<MobileMainPopup
					name='form'
					isOpened={isOpened}
					onClose={onClose}
					title='Report'
				>
					<Form onClose={formDataSent} />
				</MobileMainPopup>
			</>
		);
	}

	return (
		<MainPopup
			isOpened={isOpened}
			onClose={onClose}
			title='Report'
			showHeader={!isSent}
		>
			<AnimatePresence exitBeforeEnter>
				{isSent ? (
					<MotionSuccess
						key='success'
						initial='enter'
						animate='animate'
						exit='exit'
						variants={changeAnimation}
						transition={{
							...mainTransition,
							mass: 0.5,
						}}
						// props
						resetReport={resetReport}
					/>
				) : (
					<MotionForm
						key='form'
						initial='enter'
						animate='animate'
						exit='exit'
						variants={changeAnimation}
						transition={{
							...mainTransition,
							mass: 0.5,
						}}
						// props
						onClose={formDataSent}
					/>
				)}
			</AnimatePresence>
		</MainPopup>
	);
};
