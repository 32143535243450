import { motion } from 'framer-motion';
import React from 'react';
import closeIcon from '../../../../assets/svg/cross.svg';
import { mainTransition } from '../../../../framer-motion/transition';
import styles from './Header.module.scss';

interface HeaderProps {
	onClose: any;
	title: string;
	showHeader?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	onClose,
	title,
	showHeader = true,
}) => (
	<div
		className={`${styles['header']} ${showHeader ? styles['header_show'] : ''}`}
	>
		<div className={styles['header__title']}>{title}</div>
		<button type='button' onClick={onClose} className={styles['header__close']}>
			<motion.img
				initial={false}
				animate={{
					rotate: 0,
					scale: 1,
				}}
				whileHover={{ rotate: 90 }}
				whileTap={{ scale: 0.9 }}
				transition={mainTransition}
				src={closeIcon}
				alt='close'
			/>
		</button>
	</div>
);
