import React from 'react';
import styles from './ProgressBar.module.scss';

interface SpinnerProps {
	animationDuration: number;
	classes?: any;
}

const Spinner: React.FC<SpinnerProps> = ({ animationDuration, classes }) => (
	<div
		className={`
      ${styles['spinner']}
      ${classes ? classes.spinner : ''}
    `}
	>
		<div
			className={`
        ${styles['spinner__body']}
        ${classes ? classes.body : ''}
      `}
			style={{
				animation: `${animationDuration * 2}ms linear infinite App-logo-spin`,
			}}
		/>
	</div>
);

export default Spinner;
