import React from 'react';
import Bar from './Bar';
import Container from './Container';
import { useNProgress } from './logic/useNProgress';
import Spinner from './Spinner';

interface ProgressBarProps {
	isAnimating: boolean;
	spinner: boolean;
	steps: boolean;
	incrementDuration: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
	isAnimating,
	spinner,
	steps,
	incrementDuration,
}) => {
	const { animationDuration, isFinished, progress } = useNProgress({
		isAnimating,
		steps,
		incrementDuration,
	});
	return (
		<Container animationDuration={animationDuration} isFinished={isFinished}>
			<Bar animationDuration={animationDuration} progress={progress} />
			{spinner && <Spinner animationDuration={animationDuration} />}
		</Container>
	);
};
