import React from 'react';
import {
	useDebounce,
	useEventScroll,
	useKeyPress,
} from '../../../../../../hooks';
import { IActiveCategory, IActiveProduct, IItem, IMenu } from '../../types';
import { CONSTANTS, NODE_ID } from '../constants';
import { scrollToActiveCategoryButton } from '../helpers';
import { PopupContext } from './PopupContext';

// Types
interface IProps {
	data: IMenu;
	onClose: () => void;
	children: React.ReactNode;
}

let canScrollHighlight = true;
export const PopupProvider: React.FC<IProps> = ({
	children,
	data,
	onClose,
}) => {
	// Destructure
	const { categories, currencySymbol, currency, items, title } = data;

	// Helpers
	const normalizedData = React.useMemo(() => {
		const sortedCategories = [...categories].sort(
			(a, b) => a.position - b.position,
		);
		const visibleCategories = sortedCategories.filter(
			category => !category.hidden,
		);
		const notEmptyCategories = visibleCategories.filter(category =>
			Boolean(
				[...items].filter(item => item.categoryId === category.id).length,
			),
		);

		return notEmptyCategories.map(category => {
			const categoryItems = items.filter(
				item => item.categoryId === category.id,
			);
			const sortedItems = [...categoryItems].sort(
				(a, b) => a.position - b.position,
			);
			const visibleItems = sortedItems.filter(item => !item.hidden);

			return {
				...category,
				items: visibleItems,
			};
		});
	}, [categories, currencySymbol, currency, items, title]);
	const normalizedItemsWithoutCategory = React.useMemo(() => {
		const withoutCategoryItems = items.filter(item => !item.categoryId);
		const sortedItems = [...withoutCategoryItems].sort(
			(a, b) => a.position - b.position,
		);
		const visibleItems = sortedItems.filter(item => !item.hidden);

		return [...visibleItems];
	}, [items]);
	const defaultActiveCategoryId =
		normalizedData[0]?.id ?? CONSTANTS.NONE_CATEGORY;

	// States
	const [activeCategory, setActiveCategory] = React.useState<IActiveCategory>({
		id: defaultActiveCategoryId,
	});
	const [activeProduct, setActiveProduct] = React.useState<IActiveProduct>({
		isShown: false,
		item: null,
	});

	// Helpers
	const updateActiveCategory = (id: string) => {
		// scroll to active category in header
		scrollToActiveCategoryButton(id);
		canScrollHighlight = false;

		// show active category
		setActiveCategory({
			id,
		});
	};
	const updateActiveItem = (isShown: boolean, item?: IItem) => {
		// show active item
		setActiveProduct(state => ({
			...state,
			isShown,
			item: item || state.item,
		}));
	};
	// - hooks
	const scrollToActiveCategoryWithDelay: (popup: Element) => void = useDebounce(
		(popup: HTMLElement) => {
			// Get container scroll position
			const popupTopOffset = popup.scrollTop + CONSTANTS.EXTRA_OFFSET + 10;

			normalizedData.forEach(item => {
				const element = document.getElementById(item.id);
				const categoryOffsetTop = element?.offsetTop ?? 0;
				const categoryOffsetHeight = element?.offsetHeight ?? 0;

				if (
					canScrollHighlight &&
					popupTopOffset > categoryOffsetTop &&
					popupTopOffset <= categoryOffsetTop + categoryOffsetHeight
				) {
					updateActiveCategory(item.id);
				}
			});

			canScrollHighlight = true;
		},
		250,
	);
	useKeyPress(
		'Escape',
		() => {
			if (activeProduct.isShown) {
				updateActiveItem(false);

				return;
			}

			onClose();
		},
		[activeProduct.isShown],
	);
	useEventScroll(NODE_ID.POPUP, e => {
		scrollToActiveCategoryWithDelay(e.target);
	});

	// Context value
	const memoizedValue = React.useMemo(
		() => ({
			title,
			categories: normalizedData,
			itemsWithoutCategory: normalizedItemsWithoutCategory,
			currencySymbol,
			currency,
			items,
			onClose,
			activeCategory,
			activeProduct,
			updateActiveCategory,
			updateActiveItem,
		}),
		[
			activeCategory,
			activeProduct,
			normalizedData,
			items,
			normalizedItemsWithoutCategory,
		],
	);

	return (
		<PopupContext.Provider value={memoizedValue}>
			{children}
		</PopupContext.Provider>
	);
};
