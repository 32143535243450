import React from 'react';
import { ReactComponent as ShopIcon } from '../../../../../assets/svg/shoppble.svg';
import {
	beautifyPrice,
	getCurrentBorder,
	getCurrentRadius,
} from '../../../../../helpers';
import { Actions } from './Actions';
import styles from './MultiLinkProduct.module.scss';

interface MultiLinkProductProps {
	data: any;
	style: any;
}

export const MultiLinkProduct: React.FC<MultiLinkProductProps> = ({
	data,
	style,
}) => {
	const { title, description, price, image } = data || {};

	// Helpers
	const imageBlur = style.buttonStylesAdditionally === 'default';

	return (
		<div
			className={`${styles['product']}`}
			style={{
				boxShadow: style.boxShadow,
				color: style.color,
				borderRadius: getCurrentRadius(style.borderRadius),
				border: getCurrentBorder(style),
			}}
		>
			<div
				className={`
          ${styles['product__header']}
          ${imageBlur ? styles['product__header_blur'] : ''}
        `}
			>
				<img className={styles['product__image']} src={image} alt={title} />

				{price?.value ? (
					<div className={styles['product__icon']}>
						<ShopIcon
							className={`${styles['product__icon-svg']} ${styles['product__icon-svg_shop']}`}
						/>
					</div>
				) : (
					''
				)}
			</div>
			<div
				className={styles['product__body']}
				style={{
					background: style.background,
				}}
			>
				<h4
					className={`${styles['product__title']} ${
						!description && price.value === 0
							? styles['product__title_no-price']
							: ''
					}`}
				>
					{title}
				</h4>

				{description ? (
					<p
						className={`${styles['product__description']}  ${
							price.value === 0 ? styles['product__description_no-price'] : ''
						}`}
					>
						{description}
					</p>
				) : (
					''
				)}

				{price.value !== 0 ? (
					<p className={styles['product__price']} title={price.currency}>
						<span className={styles['product__price-currency']}>
							{price.currencySymbol}
						</span>{' '}
						<span className={styles['product__price-number']}>
							{beautifyPrice(price.value)}
						</span>
					</p>
				) : (
					''
				)}

				<Actions data={data} style={style} />
			</div>
		</div>
	);
};
