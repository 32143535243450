import React from 'react';
import { isDesktop } from 'react-device-detect';
import { SiteContext } from '../context/siteContext';
import { blockClickEvent } from '../services/trackingApi';
import { useGetUserId } from './useGetUserId';

interface UseSendAnalyticEventProps {
	type: string;
	url?: string;
	data: {
		blockId: string;
		eventAction: 'out-click' | 'copy';
		subBlockId?: string;
	};
}
export const useSendAnalyticsEvent = ({
	type,
	url,
	data,
}: UseSendAnalyticEventProps) => {
	// Context
	const { siteDataState } = React.useContext(SiteContext);

	// Helpers
	const { getUserId } = useGetUserId();

	return (event?: any) => {
		blockClickEvent({
			userId: getUserId(),
			siteId: siteDataState.id,
			...data,
		})
			.then()
			.catch(error => console.log(`${type} blockClickEvent - `, error))
			.finally(() => {
				if (!isDesktop && url?.length) {
					event?.preventDefault();
					window.location.href = url;
				}
			});
	};
};
