import React from 'react';
import { ReactComponent as LoadingIcon } from '../../../assets/svg/loading.svg';
import { useMounted } from '../../../hooks/useMounted';
import { AutoFocusTypes } from '../TextField/TextField';
import styles from './Button.module.scss';

// TODO fix any
interface ButtonProps {
	size?: 'small';
	text: string;
	Icon?: any;
	disabled?: boolean;
	loading?: boolean;
	classes?: ClassesTypes;
	secondary?: boolean;
	onclick: () => void;
	autoFocus?: AutoFocusTypes | null;
}
interface ClassesTypes {
	button?: string;
	icon?: string;
	text?: string;
}

export const Button: React.FC<ButtonProps> = ({
	onclick,
	disabled,
	text,
	Icon,
	classes,
	loading,
	secondary,
	size,
	autoFocus,
}) => {
	const mounted = useMounted();

	// Actions
	const getFieldRef = (input: any) => {
		// Set focus
		if (autoFocus?.focus && input && !mounted) {
			setTimeout(() => {
				input?.focus();
			}, autoFocus?.delay);
		}
	};
	return (
		<button
			ref={getFieldRef}
			type='button'
			className={`
            ${styles['button']}
            ${size ? styles[`button_${size}`] : ''}
            ${secondary ? styles['button_secondary'] : ''}
            ${loading ? styles['button_loading'] : ''}
            ${classes?.button ? classes.button : ''}
        `}
			onClick={onclick}
			disabled={disabled}
		>
			{Icon && (
				<Icon
					className={`${styles['button__icon']} ${
						classes?.icon ? classes.icon : ''
					}`}
				/>
			)}
			<span
				className={`${styles['button__text']} ${
					classes?.text ? classes.text : ''
				}`}
			>
				{text}
			</span>
			<span className={styles['button__loading']}>
				<LoadingIcon className={styles['button__loading-icon']} />
			</span>
		</button>
	);
};
