const fontData = [
	'Alegreya',
	'Courier',
	'Garamond',
	'Inter',
	'Lora',
	'Merriweather',
	'NotoSerif',
	'Oswald',
	'Poppins',
	'Roboto',
	'Rubik',
	'Spectral',
	'Tinos',
	'Yanone',
	'NotoSans',
];

export default fontData;
