export function focusAndOpenKeyboard(el: any, timeout = 150) {
	if (el) {
		// Align temp input element approximately where the input element is
		// so the cursor doesn't jump around
		const dummyInput = document.createElement('input');
		dummyInput.style.position = 'fixed';
		dummyInput.style.height = '0';
		dummyInput.style.opacity = '0';
		// Put this temp element as a child of the page <body> and focus on it
		document.body.appendChild(dummyInput);
		dummyInput.focus();

		// The keyboard is open. Now do a delayed focus on the target element
		setTimeout(() => {
			el.focus();
			el.click();
			// Remove the temp element
			document.body.removeChild(dummyInput);
		}, timeout);
	}
}
