import { useEffect } from 'react';

const getScrollBarWidth = () => {
	if (window.innerHeight >= document.body.clientHeight) {
		return 0;
	}

	const el = document.createElement('div');
	el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
	document.body.appendChild(el);
	const width = el.offsetWidth - el.clientWidth;
	el.remove();
	return width;
};

const updateCssScrollWidthVariable = () => {
	const scrollBarWidth = getScrollBarWidth();
	document.documentElement.style.setProperty(
		'--scrollBarWidthPreview',
		`${scrollBarWidth}px`,
	);
};

export const useUpdateCssScrollWidthVariable = () => {
	useEffect(() => {
		const resizeObserver = new ResizeObserver(updateCssScrollWidthVariable);

		resizeObserver.observe(document.body);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);
};
