import React from 'react';
import { isIOS } from 'react-device-detect';
import arrowIcon from '../../../assets/svg/arrow2.svg';
import { useGetUserId } from '../../../hooks';
import { wannaSiteLikeClickEvent } from '../../../services/trackingApi';
import styles from './CreateBioSiteLink.module.scss';

interface CreateBioSiteLinkProps {
	siteData: any;
	icon: string;
}

export const CreateBioSiteLink: React.FC<CreateBioSiteLinkProps> = ({
	siteData,
	icon,
}) => {
	const { getUserId } = useGetUserId();

	const linkClick = () => {
		wannaSiteLikeClickEvent({
			siteId: siteData.id,
			userId: getUserId(),
		}).then();
	};

	const defaultUrl = `https://${process.env.REACT_APP_BUILD_ENV}edit.lift.bio/funnel-split-router?utm_source=referral_lift_bio&utm_user_referrer=${siteData.url}`;
	const iosUrl = 'https://lift.onelink.me/KndT/gjnlxs63';

	return (
		<a
			href={isIOS ? iosUrl : defaultUrl}
			rel='noreferrer'
			target='_blank'
			className={`${styles['create-bio-site-link']} create-bio-site-link`}
			data-testid='create-bio-site-link '
			onClick={linkClick}
		>
			<img
				className={`${styles['create-bio-site-link__logo']}`}
				src={icon}
				alt=''
			/>
			<p className={`${styles['create-bio-site-link__text']}`}>
				Create a Lift bio site
				<img
					data-testid='create-bio-site-link__icon-arrow'
					src={arrowIcon}
					alt='arrow'
					className={styles['create-bio-site-link__icon-arrow']}
				/>
			</p>
		</a>
	);
};
