import React from 'react';

export function useKeyPress(
	key: string,
	callback: any,
	deps: any[] = [],
	options?: any,
) {
	const { event, target, eventOptions } = options ?? {
		event: 'keydown',
		target: window ?? null,
		eventOptions: {},
	};

	React.useEffect(() => {
		const handler = (e: any) => {
			if (e.key === key) {
				callback();
			}
		};

		target.addEventListener(event, handler, eventOptions);

		return () => {
			target.removeEventListener(event, handler);
		};
	}, [key, ...deps]);
}
