import facebookIcon from '../assets/svg/socialMedia/facebook.svg';
import linkedinIcon from '../assets/svg/socialMedia/linkedin.svg';
import mailIcon from '../assets/svg/socialMedia/mail.svg';
import twitterIcon from '../assets/svg/socialMedia/twitter.svg';
import whatsappIcon from '../assets/svg/socialMedia/whatsapp.svg';

export const shareSocialList = [
	{
		name: 'Facebook',
		icon: facebookIcon,
		href: `https://www.facebook.com/sharer.php?u=${window.location.href}`,
		target: '_blank',
	},
	{
		name: 'Twitter',
		icon: twitterIcon,
		href: `https://twitter.com/intent/tweet?text=Check out this Lift.bio - ${window.location.href}`,
		target: '_blank',
	},
	{
		name: 'Linkedin',
		icon: linkedinIcon,
		href: `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
		target: '_blank',
	},
	{
		name: 'Whatsapp',
		icon: whatsappIcon,
		href: `https://wa.me/?text=Check out this Lift.bio! - ${window.location.href}`,
		target: '_blank',
	},
	{
		name: 'Email',
		icon: mailIcon,
		href: `mailto:?subject=Check out this Lift.bio!&body=Check out this Lift.bio - ${window.location.href}`,
	},
];
