import React from 'react';

export function useMounted(
	mountedCallback?: () => void,
	unmountCallback?: () => void,
) {
	const [mounted, setMounted] = React.useState(false);

	React.useEffect(() => {
		setMounted(true);

		if (mountedCallback) {
			mountedCallback();
		}

		return unmountCallback;
	}, []);

	return mounted;
}
