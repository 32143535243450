import requestInstance from '../helpers/requestInstance';

const getCountryCode = async () => {
	const result = await requestInstance(
		'https://www.iplocate.io/api/lookup/',
		'',
		'get',
	);
	return result;
};

export default getCountryCode;
