import TextareaAutosize from '@mui/material/TextareaAutosize';
import {
	AnimatePresence,
	motion,
	transform,
	useAnimation,
} from 'framer-motion';
import React from 'react';
import { focusAndOpenKeyboard } from '../../../helpers/focusAndOpenKeyboard';
import { useMounted } from '../../../hooks/useMounted';
import styles from './TextField.module.scss';

// TODO fix any
interface TextFieldProps {
	type?: 'text' | 'textarea' | 'number' | 'email';
	name: string;
	value: string;
	label?: string;
	optional?: string;
	error?: string | null;
	placeholder?: string;
	classes?: ClassesTypes;
	disabled?: boolean;
	style?: any;
	maxLength?: {
		length: number | undefined;
		showIndicator: boolean;
	};
	maxNumber?: number;
	minNumber?: number;
	onChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	autoFocus?: AutoFocusTypes | null;
	setFocus?: any;
	autoHeight?: boolean;
	inputMode?:
		| 'none'
		| 'text'
		| 'tel'
		| 'url'
		| 'email'
		| 'numeric'
		| 'decimal'
		| 'search';
}
interface ClassesTypes {
	main?: string;
	textarea?: string;
	input?: string;
}
export interface AutoFocusTypes {
	focus: boolean;
	delay?: number;
}

export const TextField = ({
	type = 'text',
	name,
	value,
	label,
	optional,
	error,
	placeholder,
	classes,
	disabled,
	maxLength = {
		length: undefined,
		showIndicator: false,
	},
	maxNumber,
	minNumber,
	onChange,
	autoFocus,
	setFocus,
	inputMode = 'text',
	autoHeight,
	style,
}: TextFieldProps) => {
	// Utils
	const controls = useAnimation();
	const mapRemainingToColor = transform(
		[maxLength.length ?? 0, 0],
		['#40BCA3', '#98A0BC'],
	);
	const mapRemainingToSpringVelocity = transform([1, 0], [8, 0]);
	const mounted = useMounted();

	// Animations
	const maxLengthAnimation = () => {
		const lastTenSymbols = (maxLength.length ?? 0) - 10;
		if (value.length < lastTenSymbols) return;

		controls.start({
			scale: 1,
			transition: {
				type: 'spring',
				velocity: mapRemainingToSpringVelocity(value.length),
				stiffness: 700,
				damping: 80,
			},
		});
	};
	const errorAnimation = {
		init: {
			opacity: 0,
			x: -5,
		},
		animate: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: 5,
		},
	};

	// Actions
	const getFieldRef = (input: any) => {
		// Set focus
		if (autoFocus?.focus && input && (!mounted || error)) {
			focusAndOpenKeyboard(input, autoFocus.delay);
			// setTimeout(() => {
			//   input?.focus();
			// }, autoFocus?.delay);
		}
	};
	const onWheelAction = (event: React.ChangeEvent<any>) => event.target.blur();
	const onInputAction = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (name === 'discount') {
			if (+event.target.value === 100) {
				event.target.value = event.target.value.slice(0, 3); // eslint-disable-line no-param-reassign
				return;
			}

			event.target.value = event.target.value.slice(0, 2); // eslint-disable-line no-param-reassign
		}
	};

	// Hooks
	React.useEffect(() => {
		if (maxLength.length) {
			maxLengthAnimation();
		}
	}, [value.length]);

	return (
		<label
			className={`
        ${styles['input']}
        ${name === 'discount' ? styles['input_percent'] : ''}
        ${classes?.main ? classes.main : ''}
      `}
			htmlFor={name}
		>
			{label && (
				<div className={styles['input__label']}>
					<span className={styles['input__label-text']}>{label}</span>{' '}
					<span
						className={`${styles['input__label-text']} ${styles['input__label-text_optional']}`}
					>
						{optional || ''}
					</span>
				</div>
			)}
			{type === 'textarea' && autoHeight ? (
				<TextareaAutosize
					ref={getFieldRef}
					id={name}
					name={name}
					value={value}
					className={`
            ${styles['input__field']}
            ${styles['input__field_textarea']}
            ${error ? styles['input__field_error'] : ''}
            ${classes?.textarea ? classes.textarea : ''}
          `}
					placeholder={placeholder}
					disabled={disabled}
					maxLength={maxLength?.length}
					onChange={onChange}
					onBlur={() => (setFocus ? setFocus(false) : {})}
					onFocus={() => (setFocus ? setFocus(true) : {})}
					inputMode={inputMode}
					style={style}
				/>
			) : (
				''
			)}
			{type === 'textarea' && !autoHeight ? (
				<textarea
					ref={getFieldRef}
					id={name}
					name={name}
					value={value}
					className={`
            ${styles['input__field']}
            ${styles['input__field_textarea']}
            ${error ? styles['input__field_error'] : ''}
            ${classes?.textarea ? classes.textarea : ''}
          `}
					placeholder={placeholder}
					disabled={disabled}
					maxLength={maxLength?.length}
					onChange={onChange}
					onBlur={() => (setFocus ? setFocus(false) : {})}
					onFocus={() => (setFocus ? setFocus(true) : {})}
					inputMode={inputMode}
					style={style}
				/>
			) : (
				''
			)}
			{type === 'text' || type === 'email' ? (
				<input
					ref={getFieldRef}
					id={name}
					name={name}
					type={type}
					value={value}
					className={`
            ${styles['input__field']}
            ${error ? styles['input__field_error'] : ''}
            ${classes?.input ? classes.input : ''}
          `}
					data-testid='input__field'
					placeholder={placeholder}
					disabled={disabled}
					maxLength={maxLength?.length}
					onChange={onChange}
					onBlur={() => (setFocus ? setFocus(false) : {})}
					onFocus={() => (setFocus ? setFocus(true) : {})}
					inputMode={inputMode}
					style={style}
				/>
			) : (
				''
			)}
			{type === 'number' ? (
				<input
					ref={getFieldRef}
					id={name}
					name={name}
					type='number'
					value={value}
					className={`
            ${styles['input__field']}
            ${error ? styles['input__field_error'] : ''}
            ${classes?.input ? classes.input : ''}
          `}
					onWheel={onWheelAction}
					placeholder={placeholder}
					disabled={disabled}
					min={minNumber}
					max={maxNumber}
					onChange={onChange}
					onInput={onInputAction}
					onBlur={() => (setFocus ? setFocus(false) : {})}
					onFocus={() => (setFocus ? setFocus(true) : {})}
					inputMode={inputMode}
					style={style}
				/>
			) : (
				''
			)}
			<AnimatePresence exitBeforeEnter>
				{error && (
					<motion.div
						initial='init'
						animate='animate'
						exit='exit'
						variants={errorAnimation}
						transition={{ duration: 0.35 }}
						className={styles['input__error']}
					>
						{error}
					</motion.div>
				)}
			</AnimatePresence>
			{type !== 'number' && maxLength.showIndicator ? (
				<motion.div
					className={styles['input__max-length']}
					animate={controls}
					style={{ color: mapRemainingToColor(value.length) }}
				>
					{value?.length ?? 0}/{maxLength.length}
				</motion.div>
			) : (
				''
			)}
		</label>
	);
};
