import React from 'react';

// Types
interface IProps {
	children: React.ReactNode;
}

export const Link: React.FC<
	IProps | React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, ...props }) => {
	return <a {...props}>{children}</a>;
};
