import React from 'react';
import unUploadImage from '../../assets/images/blurUnUploadImage.jpg';
import '../../assets/scss/Components/SwipeGalleryItem.scss';
import getParameterByName from '../../helpers/getParameterByName';

type SwipeGalleryItemProps = {
	image: string;
	borderRadius: string;
};

const SwipeGalleryItem: React.FC<SwipeGalleryItemProps> = ({
	image,
	borderRadius,
}) => {
	const previewMode = !!getParameterByName('previewMode');

	return (
		<div
			className='swipe-gallery-item'
			style={{
				borderRadius,
			}}
		>
			<img
				className='swiper-lazy-preloader'
				src={previewMode ? image : unUploadImage}
				alt=''
			/>
			<img data-src={image} className='swiper-lazy' alt='' />
		</div>
	);
};

export default SwipeGalleryItem;
