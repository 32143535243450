import React, { useContext } from 'react';
import { isDesktop } from 'react-device-detect';
import '../../assets/scss/Components/LinksList.scss';
import { OPEN_LINK_IN_EDITOR } from '../../constants/postMessageNames';
import { SiteContext } from '../../context/siteContext';
import socialLinks from '../../data/socialLinks';
import getParameterByName from '../../helpers/getParameterByName';
import { useGetUserId } from '../../hooks';
import { sendPostMessage } from '../../services/sendPostMessage';
import { socialLinkClickEvent } from '../../services/trackingApi';

type LinkComponentProps = {
	link: any;
	color: string;
	id: string;
};

const LinkComponent: React.FC<LinkComponentProps> = ({ link, color, id }) => {
	// Context
	const { siteDataState } = useContext(SiteContext);

	// Helpers
	const { getUserId } = useGetUserId();
	const getLinkAttr = () => {
		if (isDesktop) {
			return { href: link.url, target: '_blank', rel: 'noreferrer' };
		}
		return {};
	};
	const styles = {
		rect: {
			fill: color,
			svg: color,
			path: color,
		},
	};

	// Actions
	const linkClick = (event: any) => {
		if (getParameterByName('previewMode')) {
			event?.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url: link.url });
		} else {
			socialLinkClickEvent({
				siteId: siteDataState.id,
				socialLinkId: id,
				userId: getUserId(),
			})
				.then()
				.catch(error => console.log('socialLinkClickEvent - ', error))
				.finally(() => {
					if (!isDesktop) {
						event?.preventDefault();
						window.location.href = link.url;
					}
				});
		}
	};

	return (
		<a
			data-testid='link-component'
			{...getLinkAttr()}
			className='link-component'
			style={styles.rect}
			onClick={linkClick}
		>
			<style>
				.link-component path
				{`{fill:${color}}`}
			</style>
			{socialLinks.map((item, index) =>
				link.type === item.name ? <item.icon key={index} /> : '',
			)}
		</a>
	);
};

export default LinkComponent;
