import { motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as BackIcon } from '../../../../../../../assets/svg/menu/arrow.svg';
import { beautifyPrice } from '../../../../../../../helpers';
import { useEventScroll } from '../../../../../../../hooks';
import type { IItem } from '../../../types';
import { NODE_ID } from '../../constants';
import { usePopupContext } from '../../context';
import { Diet } from '../Diet';
import styles from './ActiveProduct.module.scss';

// Types
interface IProps {
	data: IItem;
}

// Animation
const productMotion = {
	initial: 'init',
	animate: 'animate',
	exit: 'exit',
	variants: {
		init: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			transition: {
				type: 'spring',
				bounce: 0.1,
				duration: 0.25,
			},
		},
		exit: {
			opacity: 0,
			transition: {
				type: 'spring',
				bounce: 0.1,
				duration: 0.25,
			},
		},
	},
};

export const ActiveProduct: React.FC<IProps> = ({ data }) => {
	const { id, title, dietaryInfo, description, price, image } = data;

	// States
	const [sticky, setSticky] = React.useState(false);

	// Context
	const { updateActiveItem, currency, currencySymbol } = usePopupContext();

	// Actions
	const goBack = () => {
		updateActiveItem(false);
	};

	// Helpers
	useEventScroll(NODE_ID.POPUP_ACTIVE_PRODUCT, e => {
		setSticky(e.target.scrollTop > 1);
	});

	return (
		<motion.div
			id={NODE_ID.POPUP_ACTIVE_PRODUCT}
			className={styles['wrapper']}
			// Animations
			{...productMotion}
		>
			<motion.div
				animate={{
					boxShadow: sticky
						? '0 2px 10px rgba(13, 13, 13, 0.15)'
						: '0 2px 10px rgba(13, 13, 13, 0)',
				}}
				transition={{
					type: 'tween',
					duration: 0.25,
				}}
				className={styles['header']}
			>
				<motion.button
					whileTap={{ scale: 0.8 }}
					whileHover={{ x: '-5%' }}
					transition={{ duration: 0.25 }}
					type='button'
					onClick={goBack}
					aria-label='go back'
					className={styles['header__button-back']}
				>
					<BackIcon className={styles['header__button-icon']} />
				</motion.button>
			</motion.div>

			<div className={styles['item']}>
				{image ? (
					<motion.picture
						layoutId={`product-image-${id}`}
						className={styles['item__picture']}
					>
						<img src={image} className={styles['item__image']} alt={title} />
					</motion.picture>
				) : null}

				<div className={styles['item__info']}>
					<h3 className={styles['item__title']}>{title}</h3>
					{description ? (
						<p className={styles['item__description']}>{description}</p>
					) : null}
					<div className={styles['item__price']}>
						{currencySymbol || currency} {beautifyPrice(price)}
					</div>
					<Diet
						options={dietaryInfo}
						classes={{
							diet: styles['diet'],
							item: styles['diet__item'],
							icon: styles['diet__icon'],
						}}
					/>
				</div>
			</div>
		</motion.div>
	);
};
