import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';
import { mainTransition } from '../../../framer-motion/transition';
import { useKeys } from '../../../hooks';
import styles from './Popup.module.scss';

interface PopupProps {
	children: React.ReactNode;
	isOpened: boolean;
	onClose: any;
	mobile?: boolean;
	name?: string;
}

export const Popup: React.FC<PopupProps> = ({
	children,
	isOpened,
	onClose,
	mobile,
}) => {
	// Helpers
	const refPopup = React.useRef<any>(null);
	useKeys('Escape', () => onClose());

	// Lifecycle hooks
	React.useEffect(() => {
		if (isOpened) {
			document.body.classList.add('removeScroll');
			refPopup?.current?.classList.remove('pointer-none');
		} else {
			document.body.classList.remove('removeScroll');
			refPopup?.current?.classList.add('pointer-none');
		}
	}, [isOpened]);
	React.useEffect(
		() => () => {
			document.body.classList.remove('removeScroll');
			refPopup?.current?.classList.add('pointer-none');
		},
		[],
	);

	// Animations
	const popupAnimation = {
		enter: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
	};

	return ReactDOM.createPortal(
		<AnimatePresence exitBeforeEnter>
			{isOpened && (
				<motion.div
					key='popup'
					initial='enter'
					animate='animate'
					exit='exit'
					variants={popupAnimation}
					transition={mainTransition}
					role='dialog'
					ref={refPopup}
					className={`
              ${styles['popup']}
              ${mobile ? styles['popup_mobile'] : ''}
            `}
				>
					{!mobile ? (
						<button
							type='button'
							className={styles['popup__overlay']}
							aria-label='Close'
							onClick={onClose}
						/>
					) : (
						''
					)}
					{children}
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};
