import { useMediaQuery } from './useMediaQuery';

export const useMedia = (size: string) => {
	if (size === 'xl') {
		return useMediaQuery(`(max-width: ${1920}px)`);
	}
	if (size === 'l') {
		return useMediaQuery(`(max-width: ${1280}px)`);
	}
	if (size === 'm') {
		return useMediaQuery(`(max-width: ${1024}px)`);
	}
	if (size === 's') {
		return useMediaQuery(`(max-width: ${679}px)`);
	}
	if (size === 'xs') {
		return useMediaQuery(`(max-width: ${375}px)`);
	}
	if (size === 'xxs') {
		return useMediaQuery(`(max-width: ${320}px)`);
	}
};

export const useIsLandscape = () =>
	useMediaQuery('(orientation: landscape), (max-height: 600px)');
export const useIsPortrait = () => useMediaQuery('(orientation: portrait)');
