import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { isDesktop } from 'react-device-detect';
import { ReactSVG } from 'react-svg';
import '../../assets/scss/Components/ButtonComponent.scss';
import { OPEN_LINK_IN_EDITOR } from '../../constants/postMessageNames';
import { getCurrentBorder } from '../../helpers';
import getParameterByName from '../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../hooks';
import { sendPostMessage } from '../../services/sendPostMessage';

type ButtonComponentProps = {
	title: string;
	icon?: any;
	url: string;
	style?: any;
	id: string;
	withIcon: boolean;
};

export const ButtonComponent: React.FC<ButtonComponentProps> = forwardRef(
	({ title, icon, url, style, id, withIcon }, ref: any) => {
		// Helpers
		const getLinkAttr = () => {
			if (isDesktop) {
				return { href: url, target: '_blank' };
			}
			return {};
		};
		// - hooks
		const sendAnalyticsAndOpenLink: (event: any) => void =
			useSendAnalyticsEvent({
				type: 'button',
				url,
				data: {
					blockId: id,
					eventAction: 'out-click',
				},
			});

		// Actions
		const buttonClick = async (event: any) => {
			if (getParameterByName('previewMode')) {
				event?.preventDefault();
				sendPostMessage(OPEN_LINK_IN_EDITOR, { url });
				return;
			}

			sendAnalyticsAndOpenLink(event);
		};

		return (
			<a
				{...getLinkAttr()}
				ref={ref}
				style={{
					boxShadow: style.boxShadow,
					color: style.color,
					background: style.background,
					borderRadius: style.borderRadius,
					border: getCurrentBorder(style),
				}}
				className={`button-component ${
					!icon ? 'button-component_no-icon' : ''
				}`}
				onClick={buttonClick}
				data-testid='button-component'
			>
				{withIcon && icon ? (
					<ReactSVG
						data-testid='button-component-icon'
						className='button-component__icon'
						src={icon}
						beforeInjection={(svg: any) => {
							svg.classList.add('button-component__icon-svg');

							const getFillPath = [...svg.querySelectorAll('path[fill]')];
							getFillPath?.map(item => item?.setAttribute('fill', style.color));

							const getFillRect = [...svg.querySelectorAll('rect[fill]')];
							getFillRect?.map(item => item?.setAttribute('fill', style.color));

							const getEllipse = [...svg.querySelectorAll('ellipse')];
							getEllipse?.map(item =>
								item?.setAttribute('stroke', style.color),
							);

							const getCircleStroke = [
								...svg.querySelectorAll('circle[stroke]'),
							];
							getCircleStroke?.map(item =>
								item?.setAttribute('stroke', style.color),
							);

							const getStrokePath = [...svg.querySelectorAll('path[stroke]')];
							getStrokePath?.map(item =>
								item?.setAttribute('stroke', style.color),
							);

							const getStrokeRect = [...svg.querySelectorAll('rect[stroke]')];
							getStrokeRect?.map(item =>
								item?.setAttribute('stroke', style.color),
							);
						}}
					/>
				) : (
					icon && (
						<img
							src={icon}
							alt='button icon'
							style={{ borderRadius: style.borderRadius }}
							className='button-component__icon'
						/>
					)
				)}
				<span className='button-component__text'>{title}</span>
			</a>
		);
	},
);

export const MButtonComponent = motion(ButtonComponent);
