import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import { NODE_ID } from '../../constants';
import { usePopupContext } from '../../context';
import { ActiveProduct } from '../ActiveProduct';
import { Diet } from '../Diet';
import { Header } from '../Header';
import { List } from '../List';
import styles from './Body.module.scss';

// Types
interface IProps {}

export const Body: React.FC<IProps> = () => {
	// Context
	const { activeProduct, categories, itemsWithoutCategory } = usePopupContext();

	return (
		<AnimateSharedLayout type='crossfade'>
			<div id={NODE_ID.POPUP} className={styles['body']}>
				<Header />

				<div className={styles['body__container']}>
					<List
						categories={categories}
						itemsWithoutCategory={itemsWithoutCategory}
					/>

					<Diet
						withTitle
						classes={{
							diet: styles['diet'],
							item: styles['diet__item'],
						}}
					/>
				</div>
			</div>

			<AnimatePresence exitBeforeEnter initial={false}>
				{activeProduct.item && activeProduct.isShown ? (
					<ActiveProduct data={activeProduct.item} />
				) : null}
			</AnimatePresence>
		</AnimateSharedLayout>
	);
};
