import React from 'react';
import { SingleProduct } from '../SingleProduct';
import styles from './GridProducts.module.scss';

interface GridProductsProps {
	style: any;
	data: any;
	blockId: string;
}

export const GridProducts: React.FC<GridProductsProps> = ({
	style,
	data,
	blockId,
}) => {
	const lengthOfActiveProducts = data.filter(
		(product: any) => product.hidden === false,
	).length;
	if (data.length === 1 || lengthOfActiveProducts === 1) {
		return (
			<SingleProduct
				type='single'
				style={style}
				data={data[0]}
				blockId={blockId}
			/>
		);
	}

	return (
		<div className={styles['grid']}>
			{data.map(
				(product: any, index: number) =>
					!product.hidden && (
						<SingleProduct
							key={index}
							type='grid'
							style={style}
							data={product}
							blockId={blockId}
						/>
					),
			)}
		</div>
	);
};
