import React from 'react';
import styles from './ProgressBar.module.scss';

interface BarProps {
	animationDuration: number;
	progress: number;
}

const Bar: React.FC<BarProps> = ({ animationDuration, progress }) => (
	<div
		className={styles.bar}
		style={{
			transform: `translate3d(${(-1 + progress) * 100}%,0,0)`,
			transition: `transform ${animationDuration}ms linear`,
		}}
	>
		<div className={styles.bar__shadow} />
	</div>
);

export default Bar;
