// Hat-tip:
// https://github.com/streamich/react-use/blob/master/src/useGetSetState.ts.
import { useCallback, useRef, useState } from 'react';

/* eslint-disable */
const incrementParameter = (num: number): number => ++num % 1_000_000;
/* eslint-enable */

const useUpdate = () => {
	const [, setState] = useState(0);
	return useCallback(() => setState(incrementParameter), []);
};

export const useGetSetState = <T extends object>(
	initialState: T = {} as T,
	/* eslint-disable */
): [() => T, (patch: Partial<T>) => void] => {
  /* eslint-enable */
	const update = useUpdate();
	const state = useRef<T>({ ...(initialState as object) } as T);
	const get = useCallback(() => state.current, []);
	const set = useCallback((patch: Partial<T>) => {
		if (!patch) {
			return;
		}
		Object.assign(state.current, patch);
		update();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [get, set];
};
