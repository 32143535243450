import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import shareIcon from '../../../assets/svg/share.svg';
import { useGetUserId } from '../../../hooks';
import useOutsideClicker from '../../../hooks/useOutsideClicker';
import { shareClickEvent } from '../../../services/trackingApi';
import { ShareMenu } from '../ShareMenu';
import styles from './Share.module.scss';

interface ShareProps {
	siteData: any;
	showSharedMenu: boolean;
	setShowSharedMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Share: React.FC<ShareProps> = ({
	siteData,
	showSharedMenu,
	setShowSharedMenu,
}) => {
	const { getUserId } = useGetUserId();
	const menuRef = useRef(null);
	const shareClick = () => {
		setShowSharedMenu(true);
		shareClickEvent({
			siteId: siteData.id,
			platform: 'desktop',
			type: 'share',
			userId: getUserId(),
		}).then();
	};
	useOutsideClicker(menuRef, showSharedMenu, setShowSharedMenu);

	useEffect(() => {
		if (showSharedMenu) {
			document.body.classList.add('removeScroll');
		} else {
			document.body.classList.remove('removeScroll');
		}
	}, [showSharedMenu]);

	return (
		<div
			ref={(el: any) => {
				menuRef.current = el;
			}}
			className={styles['share']}
		>
			<button
				data-testid='share__btn'
				type='button'
				className={`${styles['share__btn']} share__btn`}
				onClick={shareClick}
				disabled={showSharedMenu}
			>
				<img
					data-testid='share__btn_icon'
					className={`${styles['share__btn_icon']} share__btn_icon`}
					src={shareIcon}
					alt='share'
				/>
			</button>
			<AnimatePresence>
				{showSharedMenu && (
					<motion.div
						data-testid='animation-wrapper'
						className={`${styles['animation-wrapper']} animation-wrapper`}
						initial={{ top: '-175px' }}
						animate={{ top: 0 }}
						exit={{ top: '-715px' }}
						transition={{
							type: 'spring',
							damping: 10,
							stiffness: 100,
						}}
					>
						<ShareMenu
							siteData={siteData}
							setShowSharedMenu={setShowSharedMenu}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
