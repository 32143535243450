import React from 'react';
import Cookies from 'universal-cookie';
import { SiteContext } from '../context/siteContext';

export function useGetUserId() {
	const cookies = new Cookies();
	const { userId } = React.useContext(SiteContext) || { userId: '' };

	const getUserId = () => cookies.get('uid') ?? userId;
	return { getUserId };
}
