import React from 'react';
import { isDesktop } from 'react-device-detect';
import { OPEN_LINK_IN_EDITOR } from '../../../../../../../constants/postMessageNames';
import { capitalizeFirstLetter } from '../../../../../../../helpers';
import getParameterByName from '../../../../../../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../../../../../../hooks';
import { sendPostMessage } from '../../../../../../../services/sendPostMessage';
import styles from './Item.module.scss';

// Props Types
interface ItemProps {
	item: any;
	data: any;
}

export const Item: React.FC<ItemProps> = ({ item, data }) => {
	const { button, url } = item;

	// Helpers
	const withImage = button.type === 'image';
	const getLinkAttr = () => {
		if (url !== 'popup' && isDesktop) {
			return { href: url, target: '_blank' };
		}
		return {};
	};
	// - hooks
	const sendAnalyticsAndOpenLink: (event: any) => void = useSendAnalyticsEvent({
		type: data.type,
		url,
		data: {
			blockId: data.id,
			eventAction: 'out-click',
		},
	});

	// Actions
	const onClick = (event: any) => {
		if (url === 'popup') return;

		if (getParameterByName('previewMode')) {
			event?.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url });

			return;
		}

		sendAnalyticsAndOpenLink(event);
	};

	return (
		<a
			{...getLinkAttr()}
			onClick={onClick}
			className={`
        ${styles['item']}
        ${withImage ? styles['item_with-image'] : ''}
      `}
			data-testid='item'
		>
			{withImage ? (
				<img
					className={styles['item__image']}
					src={button.value.colorful}
					alt={url}
				/>
			) : (
				<span className={styles['item__text']}>
					{capitalizeFirstLetter(button.value)}
				</span>
			)}
		</a>
	);
};
