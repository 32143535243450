import React, { useState } from 'react';
import crossIcon from '../../../assets/svg/cross.svg';
import { ReactComponent as ShareIcon } from '../../../assets/svg/share2.svg';
import copyIcon from '../../../assets/svg/socialMedia/copy.svg';
import { shareSocialList } from '../../../data/shareSocialList';
import { useGetUserId } from '../../../hooks';
import { shareClickEvent } from '../../../services/trackingApi';
import styles from './ShareMenu.module.scss';

interface ShareMenuProps {
	siteData: any;
	setShowSharedMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShareMenu: React.FC<ShareMenuProps> = ({
	siteData,
	setShowSharedMenu,
}) => {
	const { getUserId } = useGetUserId();
	const [copyText, setCopyText] = useState('Copy');

	const sendEvent = (name: string) => {
		shareClickEvent({
			siteId: siteData.id,
			platform: 'desktop',
			type: name.toLocaleLowerCase(),
			userId: getUserId(),
		}).then();
	};

	const copyUrlClick = () => {
		navigator.clipboard?.writeText(window.location.href);
		setCopyText('Copied');
		sendEvent('copy');
	};

	const shareClick = (name: string) => {
		sendEvent(name);
	};

	const closeClick = () => {
		setShowSharedMenu(false);
	};

	return (
		<div className={`${styles['share-menu']} share-menu`}>
			<button
				onClick={closeClick}
				type='button'
				className={styles['share-menu__cross-btn']}
			>
				<img src={crossIcon} alt='close' />
			</button>
			<h3 className={styles['share-menu__title']}>Share to</h3>
			<ul>
				{shareSocialList.map(item => (
					<li className={styles['share-menu__item']} key={item.name}>
						<a
							onClick={() => shareClick(item.name)}
							href={item.href}
							target={item.target}
							className={styles['share-wrapper']}
							rel='noreferrer'
						>
							<img src={item.icon} alt={item.name} />
							<span className={styles['text']}>{item.name}</span>
							<div className={styles['icon-wrapper']}>
								<ShareIcon width={12} height={12} />
							</div>
						</a>
					</li>
				))}
				<li className={styles['share-menu__item']} key='copy'>
					<div className={styles['share-menu__item_btn']}>
						<img src={copyIcon} alt='copy' />
						<span className={styles['text']}>Copy link</span>
					</div>
					<button
						onClick={copyUrlClick}
						type='button'
						data-testid='share-menu__item_copy-btn'
						className={styles['share-menu__item_copy-btn']}
					>
						{copyText}
					</button>
				</li>
			</ul>
		</div>
	);
};
