import { ReactComponent as AirbnbIcon } from '../assets/svg/socialLinks/airbnb.svg';
import { ReactComponent as AlibabaIcon } from '../assets/svg/socialLinks/alibaba.svg';
import { ReactComponent as AliexpressIcon } from '../assets/svg/socialLinks/aliexpress.svg';
import { ReactComponent as AmazonIcon } from '../assets/svg/socialLinks/amazon.svg';
import { ReactComponent as AmazonMusicIcon } from '../assets/svg/socialLinks/amazonMusic.svg';
import { ReactComponent as AppleMusicIcon } from '../assets/svg/socialLinks/appleMusic.svg';
import { ReactComponent as ApplePodcastIcon } from '../assets/svg/socialLinks/applePodcast.svg';
import { ReactComponent as ArtStationIcon } from '../assets/svg/socialLinks/artStation.svg';
import { ReactComponent as BandcampIcon } from '../assets/svg/socialLinks/bandcamp.svg';
import { ReactComponent as BehanceIcon } from '../assets/svg/socialLinks/behance.svg';
import { ReactComponent as CameoIcon } from '../assets/svg/socialLinks/cameo.svg';
import { ReactComponent as CashappIcon } from '../assets/svg/socialLinks/cashapp.svg';
import { ReactComponent as ClubhouseIcon } from '../assets/svg/socialLinks/clubhouse.svg';
import { ReactComponent as CodepenIcon } from '../assets/svg/socialLinks/codepen.svg';
import { ReactComponent as DeviantartIcon } from '../assets/svg/socialLinks/devianart.svg';
import { ReactComponent as DiscordIcon } from '../assets/svg/socialLinks/diskord.svg';
import { ReactComponent as DribbbletIcon } from '../assets/svg/socialLinks/dribbble.svg';
import { ReactComponent as EtsyIcon } from '../assets/svg/socialLinks/etsy.svg';
import { ReactComponent as FacebookIcon } from '../assets/svg/socialLinks/facebook.svg';
import { ReactComponent as GithubIcon } from '../assets/svg/socialLinks/github.svg';
import { ReactComponent as GoogleIcon } from '../assets/svg/socialLinks/google.svg';
import { ReactComponent as GooglePodcastIcon } from '../assets/svg/socialLinks/googlePodcast.svg';
import { ReactComponent as InstagramIcon } from '../assets/svg/socialLinks/instagram.svg';
import { ReactComponent as ITunesStoreIcon } from '../assets/svg/socialLinks/itunes.svg';
import { ReactComponent as LikeeIcon } from '../assets/svg/socialLinks/likee.svg';
import { ReactComponent as LinkedinIcon } from '../assets/svg/socialLinks/linkedin.svg';
import { ReactComponent as MailIcon } from '../assets/svg/socialLinks/mail.svg';
import { ReactComponent as MediumIcon } from '../assets/svg/socialLinks/medium.svg';
import { ReactComponent as MixcloudIcon } from '../assets/svg/socialLinks/mixcloud.svg';
import { ReactComponent as OnlyFansIcon } from '../assets/svg/socialLinks/onlyFans.svg';
import { ReactComponent as PandoraIcon } from '../assets/svg/socialLinks/pandora.svg';
import { ReactComponent as PatreonIcon } from '../assets/svg/socialLinks/patreon.svg';
import { ReactComponent as PhoneIcon } from '../assets/svg/socialLinks/phone.svg';
import { ReactComponent as PinterestIcon } from '../assets/svg/socialLinks/pinterest.svg';
import { ReactComponent as PoshmarkIcon } from '../assets/svg/socialLinks/poshmark.svg';
import { ReactComponent as RedditIcon } from '../assets/svg/socialLinks/reddit.svg';
import { ReactComponent as ShopifyIcon } from '../assets/svg/socialLinks/shopify.svg';
import { ReactComponent as SignalIcon } from '../assets/svg/socialLinks/signal.svg';
import { ReactComponent as SkypeIcon } from '../assets/svg/socialLinks/skype.svg';
import { ReactComponent as SnapIcon } from '../assets/svg/socialLinks/snapchat.svg';
import { ReactComponent as SoundcloudIcon } from '../assets/svg/socialLinks/soundcloud.svg';
import { ReactComponent as SpotifyIcon } from '../assets/svg/socialLinks/spotify.svg';
import { ReactComponent as SubstackIcon } from '../assets/svg/socialLinks/substack.svg';
import { ReactComponent as TelegramIcon } from '../assets/svg/socialLinks/telegram.svg';
import { ReactComponent as TidalIcon } from '../assets/svg/socialLinks/tidal.svg';
import { ReactComponent as TiktokIcon } from '../assets/svg/socialLinks/tik-tok.svg';
import { ReactComponent as TwitchIcon } from '../assets/svg/socialLinks/twitch.svg';
import { ReactComponent as TwitterIcon } from '../assets/svg/socialLinks/twitter.svg';
import { ReactComponent as UnsplashIcon } from '../assets/svg/socialLinks/unspash.svg';
import { ReactComponent as ViberIcon } from '../assets/svg/socialLinks/viber.svg';
import { ReactComponent as VimeoIcon } from '../assets/svg/socialLinks/vimeo.svg';
import { ReactComponent as WebsiteIcon } from '../assets/svg/socialLinks/website.svg';
import { ReactComponent as WechatIcon } from '../assets/svg/socialLinks/wechat.svg';
import { ReactComponent as WhatsappIcon } from '../assets/svg/socialLinks/whatsapp.svg';
import { ReactComponent as YoutubeIcon } from '../assets/svg/socialLinks/youtube.svg';
import { ReactComponent as YouTubeMusicIcon } from '../assets/svg/socialLinks/youtubeMusic.svg';

const socialLinks = [
	{
		name: 'Tidal',
		icon: TidalIcon,
		category: 'music',
		popular: true,
		url: 'url/',
	},
	{
		name: 'Airbnb',
		icon: AirbnbIcon,
		category: 'other',
		popular: true,
		url: 'airbnb.com/',
	},
	{
		name: 'Behance',
		icon: BehanceIcon,
		category: 'other',
		popular: true,
		url: 'behance.net/',
	},
	{
		name: 'Codepen',
		icon: CodepenIcon,
		category: 'other',
		popular: false,
		url: 'codepen.io/',
	},
	{
		name: 'DeviantArt',
		icon: DeviantartIcon,
		category: 'creative',
		popular: false,
		url: 'deviantart.com/',
	},
	{
		name: 'Deviantart',
		icon: DeviantartIcon,
		category: 'creative',
		popular: false,
		url: 'deviantart.com/',
	},
	{
		name: 'Dribbble',
		icon: DribbbletIcon,
		category: 'creative',
		popular: true,
		url: 'dribbble.com/',
	},
	{
		name: 'Facebook',
		icon: FacebookIcon,
		category: 'other',
		popular: false,
		url: 'facebook.com/profile.php?',
	},
	{
		name: 'Github',
		icon: GithubIcon,
		category: 'other',
		popular: false,
		url: 'github.com/',
	},
	{
		name: 'GitHub',
		icon: GithubIcon,
		category: 'other',
		popular: false,
		url: 'github.com/',
	},
	{
		name: 'Google',
		icon: GoogleIcon,
		category: 'other',
		popular: true,
		url: 'plus.google.com/',
	},
	{
		name: 'Instagram',
		icon: InstagramIcon,
		category: 'creative',
		popular: true,
		url: 'instagram.com/@',
	},
	{
		name: 'Linkedin',
		icon: LinkedinIcon,
		category: 'other',
		popular: false,
		url: 'linkedin.com/in/',
	},
	{
		name: 'Mail',
		icon: MailIcon,
		category: 'other',
		popular: true,
		url: 'Your email',
	},
	{
		name: 'Medium',
		icon: MediumIcon,
		category: 'other',
		popular: false,
		url: 'medium.com/',
	},
	{
		name: 'Patreon',
		icon: PatreonIcon,
		category: 'other',
		popular: true,
		url: 'patreon.com/',
	},
	{
		name: 'Phone',
		icon: PhoneIcon,
		category: 'messangers',
		popular: true,
		url: '+',
	},
	{
		name: 'Reddit',
		icon: RedditIcon,
		category: 'other',
		popular: false,
		url: 'reddit.com',
	},
	{
		name: 'Skype',
		icon: SkypeIcon,
		category: 'other',
		popular: false,
		url: 'My username',
	},
	{
		name: 'SnapChat',
		icon: SnapIcon,
		category: 'creative',
		popular: true,
		url: 'snapchat.com/',
	},
	{
		name: 'SoundCloud',
		icon: SoundcloudIcon,
		category: 'music',
		popular: true,
		url: 'soundcloud',
	},
	{
		name: 'Spotify',
		icon: SpotifyIcon,
		category: 'music',
		popular: true,
		url: 'open.spotify.com/artist/',
	},
	{
		name: 'Telegram',
		icon: TelegramIcon,
		category: 'messangers',
		popular: true,
		url: 'https://t.me/',
	},
	{
		name: 'TikTok',
		icon: TiktokIcon,
		category: 'creative',
		popular: true,
		url: 'tiktok.com/@',
	},
	{
		name: 'Twitch',
		icon: TwitchIcon,
		category: 'other',
		popular: true,
		url: 'twitch.tv/',
	},
	{
		name: 'Twitter',
		icon: TwitterIcon,
		category: 'other',
		popular: true,
		url: 'twitter.com/',
	},
	{
		name: 'Unsplash',
		icon: UnsplashIcon,
		category: 'creative',
		popular: false,
		url: 'unsplash.com/',
	},
	{
		name: 'Viber',
		icon: ViberIcon,
		category: 'messangers',
		popular: true,
		url: 'viber://chat?number=',
	},
	{
		name: 'Vimeo',
		icon: VimeoIcon,
		category: 'video',
		popular: false,
		url: 'vimeo.com/',
	},
	{
		name: 'Wechat',
		icon: WechatIcon,
		category: 'messangers',
		popular: false,
		url: '',
	},
	{
		name: 'WhatsApp',
		icon: WhatsappIcon,
		category: 'messangers',
		popular: false,
		url: '',
	},
	{
		name: 'YouTube',
		icon: YoutubeIcon,
		category: 'video',
		popular: false,
		url: 'youtube.com/c/',
	},
	{
		name: 'Pinterest',
		icon: PinterestIcon,
		category: 'creative',
		popular: true,
		url: 'pin.it/',
	},
	{
		name: 'OnlyFans',
		icon: OnlyFansIcon,
		category: 'social',
		popular: true,
		url: 'url/',
	},
	{
		name: 'Likee',
		icon: LikeeIcon,
		category: 'social',
		popular: true,
		url: 'url/',
	},
	{
		name: 'Discord',
		icon: DiscordIcon,
		category: 'messengers',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Diskord',
		icon: DiscordIcon,
		category: 'messengers',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Signal',
		icon: SignalIcon,
		category: 'messengers',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Pandora',
		icon: PandoraIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'AppleMusic',
		icon: AppleMusicIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Bandcamp',
		icon: BandcampIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'ITunesStore',
		icon: ITunesStoreIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'iTunes',
		icon: ITunesStoreIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'AmazonMusic',
		icon: AmazonMusicIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'YouTubeMusic',
		icon: YouTubeMusicIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Mixcloud',
		icon: MixcloudIcon,
		category: 'music',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Cameo',
		icon: CameoIcon,
		category: 'video',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Apple podcast',
		icon: ApplePodcastIcon,
		category: 'podcast',
		popular: false,
		url: 'url/',
	},
	{
		name: 'ApplePodcast',
		icon: ApplePodcastIcon,
		category: 'podcast',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Google podcast',
		icon: GooglePodcastIcon,
		category: 'podcast',
		popular: false,
		url: 'url/',
	},
	{
		name: 'GooglePodcast',
		icon: GooglePodcastIcon,
		category: 'podcast',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Clubhouse',
		icon: ClubhouseIcon,
		category: 'podcast',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Amazon',
		icon: AmazonIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Etsy',
		icon: EtsyIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Cashapp',
		icon: CashappIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Aliexpress',
		icon: AliexpressIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Alibaba',
		icon: AlibabaIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Shopify',
		icon: ShopifyIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Poshmark',
		icon: PoshmarkIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'ArtStation',
		icon: ArtStationIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Substack',
		icon: SubstackIcon,
		category: 'sell',
		popular: false,
		url: 'url/',
	},
	{
		name: 'Website',
		icon: WebsiteIcon,
		category: 'other',
		popular: true,
		url: 'url/',
	},
];

export default socialLinks;
