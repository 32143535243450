import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../assets/svg/arrow.svg';
import { getCurrentBorder } from '../../../../../helpers';
import type { BlockStyles } from '../../../../BlocksList/BlocksList';
import styles from './Button.module.scss';

// Types
interface IProps {
	title: string;
	style: BlockStyles;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = React.forwardRef(
	({ title, style, onClick }: IProps, ref: React.Ref<HTMLButtonElement>) => (
		<button
			type='button'
			ref={ref}
			onClick={onClick}
			style={{
				boxShadow: style.boxShadow,
				color: style.color,
				background: style.background,
				borderRadius: style.borderRadius,
				border: getCurrentBorder(style),
			}}
			className={styles['button']}
		>
			<span className={styles['button__text']}>{title}</span>
			<ArrowIcon className={styles['button__icon']} />
		</button>
	),
);
