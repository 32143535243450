import { ReactComponent as GlutenIcon } from '../../../../../../assets/svg/dietary/gluten.svg';
import { ReactComponent as HalalIcon } from '../../../../../../assets/svg/dietary/halal.svg';
import { ReactComponent as KosherIcon } from '../../../../../../assets/svg/dietary/kosher.svg';
import { ReactComponent as SpicyIcon } from '../../../../../../assets/svg/dietary/spicy.svg';
import { ReactComponent as VeganIcon } from '../../../../../../assets/svg/dietary/vegan.svg';
import { ReactComponent as VegetarianIcon } from '../../../../../../assets/svg/dietary/vegetarian.svg';

export const dietaryOptions = [
	{
		id: 'vegetarian',
		title: 'vegetarian',
		position: 1,
		icon: VegetarianIcon,
	},
	{
		id: 'vegan',
		title: 'vegan',
		position: 2,
		icon: VeganIcon,
	},
	{
		id: 'gluten',
		title: 'gluten',
		position: 3,
		icon: GlutenIcon,
	},
	{
		id: 'kosher',
		title: 'kosher',
		position: 4,
		icon: KosherIcon,
	},
	{
		id: 'halal',
		title: 'halal',
		position: 5,
		icon: HalalIcon,
	},
	{
		id: 'spicy',
		title: 'spicy',
		position: 6,
		icon: SpicyIcon,
	},
] as const;
