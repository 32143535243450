import { motion } from 'framer-motion';
import React from 'react';
import { OPEN_MODAL_IN_EDITOR } from '../../../constants/postMessageNames';
import { sendPostMessage } from '../../../services/sendPostMessage';
import { OverlayingPopup } from '../../elements/OverlayingPopup';
import { Header } from './Header';
import styles from './DefaultPopup.module.scss';

// Props Types
interface DefaultPopupProps {
	escapeButton?: boolean;
	onClose: () => void;
	children: React.ReactNode;
	id?: string;
	header?: string;
	classes?: {
		popup?: string;
		container?: string;
	};
}

// Animation
const popupMotion = {
	initial: 'init',
	animate: 'animate',
	exit: 'exit',
	variants: {
		init: {
			y: '10%',
			opacity: 0,
		},
		animate: {
			y: 0,
			opacity: 1,
		},
		exit: {
			y: '10%',
			opacity: 0,
		},
	},
	transition: {
		type: 'spring',
		bounce: 0.1,
		duration: 0.5,
	},
};

export const DefaultPopup: React.FC<DefaultPopupProps> = ({
	id,
	onClose,
	header,
	classes,
	children,
	escapeButton = true,
}) => {
	// Lifecycle hooks
	React.useEffect(() => {
		sendPostMessage(OPEN_MODAL_IN_EDITOR, { isOpen: true });

		return () => {
			sendPostMessage(OPEN_MODAL_IN_EDITOR, { isOpen: false });
		};
	}, []);

	return (
		<OverlayingPopup
			onClose={onClose}
			escapeButton={escapeButton}
			classes={{
				popup: `${styles['popup']} ${classes?.popup || ''}`,
			}}
		>
			<motion.div
				id={id}
				className={`${styles['popup__container']} ${classes?.container || ''}`}
				// Animation
				{...popupMotion}
			>
				{header ? <Header popupId={id} title={header} onClose={onClose} /> : ''}

				{children}
			</motion.div>
		</OverlayingPopup>
	);
};
