import React from 'react';
import { LottieAnimation } from '../../elements/LottieAnimation';
import styles from './Background.module.scss';

interface BackgroundProps {
	backgroundImage: string | null;
	themeName: string;
	backgroundStyles: {
		image: string | null;
		color: string;
		lottie?: {
			animation: string;
		} | null;
		videos?: boolean;
	};
}

export const Background: React.FC<BackgroundProps> = ({
	backgroundStyles,
	themeName,
	backgroundImage,
}) => {
	const { image, color, lottie } = backgroundStyles;

	const customStylesForThemeBackground = () => {
		const isBloomTheme =
			themeName === 'bloom' && backgroundImage?.includes(themeName);
		const isPastelTheme =
			themeName === 'pastel' && backgroundImage?.includes(themeName);
		const isMoonlitTheme =
			themeName === 'moonlit' && backgroundImage?.includes(themeName);
		const isGeometricTheme =
			themeName === 'geometric' && backgroundImage?.includes(themeName);

		if (isBloomTheme || isPastelTheme || isMoonlitTheme)
			return 'backgroundSizeAuto100';
		if (isGeometricTheme) return 'backgroundPositionTop';
		return '';
	};

	return (
		<div
			className={`
        ${styles['background']}
        ${
					customStylesForThemeBackground() !== ''
						? styles[`background_${customStylesForThemeBackground()}`]
						: ''
				}
      `}
			style={{ background: image || color }}
		>
			{lottie ? <LottieAnimation data={lottie.animation} /> : ''}
		</div>
	);
};
