import React from 'react';
import '../../assets/scss/Components/TextComponent.scss';

type TextComponentProps = {
	content: string;
	align: string;
	format: string;
	textStyles: any;
};

const TextComponent: React.FC<TextComponentProps> = ({
	content,
	align,
	format,
	textStyles,
}) => (
	<div
		className={`text-component text-component_align-${align} text-component_format-${format}`}
	>
		<p style={{ color: textStyles.color }} className='text-component__content'>
			{content}
		</p>
	</div>
);

export default TextComponent;
