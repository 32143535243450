import React from 'react';

export const useScrollCounter = (offset: number) => {
	const [reached, setReached] = React.useState(false);

	React.useEffect(() => {
		const changeHeader = () => {
			setReached(offset !== 0 ? window.scrollY > offset : false);
		};

		window.addEventListener('scroll', changeHeader);
		return () => window.removeEventListener('scroll', changeHeader);
	}, [offset]);

	return reached;
};
