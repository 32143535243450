import { motion, Variants } from 'framer-motion';
import React from 'react';
import { defaultPopupAnimation } from '../../../framer-motion/animation';
import { mainTransition } from '../../../framer-motion/transition';
import { Popup } from '../../elements/Popup';
import { Header } from './Header';
import styles from './MainPopup.module.scss';

interface MainPopupProps {
	children: React.ReactNode;
	isOpened: boolean;
	onClose: any;
	title: string;
	classes?: string;
	animation?: Variants;
	showHeader?: boolean;
}

export const MainPopup: React.FC<MainPopupProps> = ({
	children,
	isOpened,
	onClose,
	title,
	classes = '',
	animation = defaultPopupAnimation,
	showHeader,
}) => (
	<Popup isOpened={isOpened} onClose={onClose}>
		<motion.div
			initial='hide'
			animate={isOpened ? 'show' : 'hide'}
			exit='hide'
			variants={animation}
			transition={{
				...mainTransition,
				mass: 0.5,
			}}
			className={`${styles['container']} ${classes}`}
		>
			<Header title={title} onClose={onClose} showHeader={showHeader} />
			<div className={`${styles['container__content']}`}>{children}</div>
		</motion.div>
	</Popup>
);
