import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import type { BlockStyles } from '../../../BlocksList/BlocksList';
import { Button } from './Button';
import { Popup } from './Popup';
import { IMenu } from './types';
import styles from './Menu.module.scss';

// Types
interface IProps {
	data: IMenu;
	style: BlockStyles;
}

export const Menu = React.forwardRef(
	({ style, data }: IProps, ref: React.Ref<HTMLButtonElement>) => {
		// Helpers
		const memoData = React.useMemo(() => data, [data?.items]);
		// Destructuring
		const { title } = memoData;
		// States
		const [isOpened, setIsOpened] = React.useState(false);

		// Actions
		const openPopup = () => {
			setIsOpened(true);
		};
		const closePopup: () => void = React.useCallback(() => {
			setIsOpened(false);
		}, []);

		return (
			<div className={styles['menu']}>
				<Button ref={ref} title={title} style={style} onClick={openPopup} />

				<AnimatePresence exitBeforeEnter initial={false}>
					{isOpened ? <Popup data={memoData} onClose={closePopup} /> : null}
				</AnimatePresence>
			</div>
		);
	},
);
export const MMenu = motion(Menu);
