export const easing = [
	'linear',
	'easeIn',
	'easeOut',
	'easeInOut',
	'circIn',
	'circOut',
	'circInOut',
	'backIn',
	'backOut',
	'backInOut',
	'anticipate',
	'spring',
];
