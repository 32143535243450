import React from 'react';
import '../../assets/scss/Components/ButtonsList.scss';
import { ButtonComponent } from '../ButtonComponent/ButtonComponent';

type ButtonsListProps = {
	buttons: any[];
	buttonsStyles: any;
	buttonStylesAdditionally: string;
	borderColor: string;
};

const ButtonsList: React.FC<ButtonsListProps> = ({
	buttons,
	buttonsStyles,
	buttonStylesAdditionally,
	borderColor,
}) => {
	const blockStyles = {
		...buttonsStyles,
		buttonStylesAdditionally,
		borderColor,
	};

	return (
		<div className='buttons-list'>
			{buttons.map((item: any, index: number) => {
				if (!item.hidden) {
					return (
						<ButtonComponent
							key={index}
							title={item.name}
							url={item.url}
							icon={item.thumbnail}
							style={blockStyles}
							id={item.id}
							withIcon={item.refillableThumbnail}
						/>
					);
				}
				return '';
			})}
		</div>
	);
};

export default ButtonsList;
