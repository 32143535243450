import { motion } from 'framer-motion';
import React from 'react';
import type { ICategory } from '../../../../types';
import { CONSTANTS, NODE_ID } from '../../../constants';
import { usePopupContext } from '../../../context';
import styles from './Item.module.scss';

// Types
interface IProps {
	category: ICategory;
}

// Animation
const textMotion = {
	initial: false,
	variants: {
		show: {
			opacity: 1,
		},
		hide: {
			opacity: 0,
		},
	},
	transition: {
		type: 'tween',
		duration: 0.25,
	},
};

export const Item: React.FC<IProps> = ({ category }) => {
	const { id, title } = category;
	// Context
	const { activeCategory, updateActiveCategory } = usePopupContext();

	// Helpers
	const active = React.useMemo(
		() => activeCategory.id === id,
		[activeCategory.id, id],
	);

	// Active
	const onClick = () => {
		const popup = document.getElementById(NODE_ID.POPUP);
		if (popup) {
			const elementOffsetTop = document?.getElementById(id)?.offsetTop ?? 0;

			popup.scroll({
				top: elementOffsetTop - CONSTANTS.EXTRA_OFFSET,
				behavior: 'smooth',
			});
		}

		updateActiveCategory(id);
	};

	return (
		<button
			id={`button-${id}`}
			type='button'
			className={`
        ${styles['item']}
        ${active ? styles['item_active'] : ''}
      `}
			onClick={active ? undefined : onClick}
		>
			<span className={styles['item__text-hidden']}>{title}</span>
			<motion.span
				className={styles['item__text-bold']}
				// Animations
				{...textMotion}
				animate={active ? 'show' : 'hide'}
			>
				{title}
			</motion.span>
			<motion.span
				className={styles['item__text']}
				// Animations
				{...textMotion}
				animate={active ? 'hide' : 'show'}
			>
				{title}
			</motion.span>

			{active ? (
				<motion.div
					layoutId='top-bar-decor'
					transition={{ ...textMotion.transition }}
					className={styles['item__decor']}
				/>
			) : null}
		</button>
	);
};
