import React from 'react';
import type { INormalizedCategory } from '../../../types';
import { Item } from '../Item';
import styles from './Category.module.scss';

// Types
interface IProps {
	category: INormalizedCategory;
}

export const Category: React.FC<IProps> = ({ category }) => {
	const { id, title, items } = category;

	return (
		<div id={id} className={styles['category']}>
			<h3 className={styles['category__title']}>{title}</h3>

			<div className={styles['category__list']}>
				{items.map(item => (
					<Item key={item.id} item={item} />
				))}
			</div>
		</div>
	);
};
