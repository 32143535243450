const insertGoogleAnalytics = () => {
	const node = document.createElement('script');

	node.type = 'text/javascript';
	node.text = `
        console.log('Google Loaded');
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        });
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_BUILD_GTAG}', { 'send_page_view': true });
    `;
	document.getElementsByTagName('head')[0].appendChild(node);
	const noscriptEl = document.createElement('noscript');
	document.body.appendChild(noscriptEl);
};

export default insertGoogleAnalytics;
