import { APP_API, APP_CDN } from '../constants/api';
import requestInstance from '../helpers/requestInstance';

export async function getSiteData(token: string, cancelToken?: any) {
	return requestInstance(
		`${APP_CDN}/site-configs/${token}/config.json?${Date.now()}`,
		'',
		'get',
		cancelToken,
	);
}

export async function getPreviewData(name: string, cancelToken?: any) {
	return requestInstance(
		`${APP_API}/sites/${name}/preview`,
		'',
		'get',
		cancelToken,
	);
}

export async function sendReport(data: any, id: string, cancelToken?: any) {
	return requestInstance(
		`${APP_API}/sites/${id}/complaint`,
		data,
		'post',
		cancelToken,
	);
}
