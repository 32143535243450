import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as LoadingIcon } from '../../../assets/svg/loading.svg';
import { SiteContext } from '../../../context/siteContext';
import { getCurrentRadius, hexToRgb } from '../../../helpers';
import getParameterByName from '../../../helpers/getParameterByName';
import { useGetUserId } from '../../../hooks';
import { sendForm } from '../../../services/trackingApi';
import { TextField } from '../../elements/TextField';
import { FormError } from '../FormError';
import { FormFinish } from '../FormFinish';
import styles from './FormComponent.module.scss';
import 'react-phone-input-2/lib/style.css';

interface FormComponentProps {
	stylesButtons: any;
	data: any;
	stylesAdditionally: string;
	borderColor: string;
	avatar: string;
	className?: string;
}

export const FormComponent = ({
	stylesButtons,
	data,
	stylesAdditionally,
	borderColor,
	avatar,
	className,
}: FormComponentProps) => {
	const [submitted, setSubmitted] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [formHeight, setFormHeight] = useState(0);
	const [autoHeightForm, setAutoHeightForm] = useState(false);
	const [formData, setFormData] = useState<any>({
		name: {
			value: '',
			error: null,
			autoFocus: null,
		},
		email: {
			value: '',
			error: null,
			autoFocus: null,
		},
		phone: {
			value: '',
			error: null,
		},
		message: {
			value: '',
			error: null,
		},
	});

	const [disabledBtn, setDisabledBtn] = useState(true);
	const [dropdownWidth, setDropdownWidth] = useState(0);
	const [focusName, setFocusName] = useState();
	const [focusEmail, setFocusEmail] = useState();
	const [focusPhone, setFocusPhone] = useState<null | boolean>();
	const [focusMessage, setFocusMessage] = useState();

	const ref = useRef(null);
	const refForm = useRef(null);
	const { siteDataState } = useContext(SiteContext);
	const { getUserId } = useGetUserId();

	const errorAnimation = {
		init: {
			opacity: 0,
			x: -5,
		},
		animate: {
			opacity: 1,
			x: 0,
		},
		exit: {
			opacity: 0,
			x: 5,
		},
	};

	const switchFormStateAnimation = {
		init: {
			opacity: 0,
			height: 0,
		},
		animate: {
			opacity: 1,
			height: formHeight,
		},
		exit: {
			opacity: 0,
			height: 0,
		},
	};

	const updateFormData = (event: any): void => {
		// set data from inputs
		setFormData((state: any) => ({
			...state,
			[event.target.name]: {
				...state[event.target.name],
				value: event.target.value,
				error:
					event.target.value.trim() === '' ? 'This field is required' : null,
			},
		}));
	};

	const resetForm = () => {
		setFormData({
			name: {
				value: '',
				error: null,
				autoFocus: null,
			},
			email: {
				value: '',
				error: null,
				autoFocus: null,
			},
			phone: {
				value: '',
				error: null,
			},
			message: {
				value: '',
				error: null,
			},
		});
	};

	useEffect(() => {
		// @ts-ignore
		setDropdownWidth(ref.current?.offsetWidth || 0);
	}, [ref]);

	useEffect(() => {
		if (
			((data.fields.name && formData.name.value.trim()) ||
				(!data.fields.name && !formData.name.value)) &&
			((data.fields.email && formData.email.value.trim()) ||
				(!data.fields.email && !formData.email.value)) &&
			((data.fields.phone && formData.phone.value.trim()) ||
				(!data.fields.phone && !formData.phone.value)) &&
			((data.fields.message && formData.message.value.trim()) ||
				(!data.fields.message && !formData.message.value))
		) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [formData]);

	useEffect(() => {
		if (formData.message.value) {
			setAutoHeightForm(true);
		}
	}, [formData.message.value, submitted]);

	const submitForm = async () => {
		// @ts-ignore
		setFormHeight(refForm?.current?.offsetHeight);
		if (!disabledBtn) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
			if (formData.email.value && !emailRegex.test(formData.email.value)) {
				setFormData((state: any) => ({
					...state,
					email: {
						value: formData.email.value,
						error: 'Please enter valid email',
					},
				}));
			} else if (formData.phone.value && formData.phone.value.length < 12) {
				setFormData((state: any) => ({
					...state,
					phone: {
						value: formData.phone.value,
						error: 'Enter valid number',
					},
				}));
			} else {
				setSubmitting(true);
				try {
					if (!getParameterByName('previewMode')) {
						await sendForm({
							siteId: siteDataState.id,
							userId: getUserId(),
							formId: data.id,
							email: formData.email.value,
							phone: formData.phone.value,
							name: formData.name.value,
							message: formData.message.value,
						});
					}
					setAutoHeightForm(false);
					setSubmitting(false);
					resetForm();
					setSubmitted('success');
				} catch (error) {
					setAutoHeightForm(false);
					setSubmitting(false);
					resetForm();
					setSubmitted('error');
					console.log(error);
				}
			}
		}
	};

	useEffect(() => {
		if (focusName === false && !formData.name.value) {
			setFormData((state: any) => ({
				...state,
				name: {
					value: '',
					error: 'This field is required',
				},
			}));
		}
	}, [focusName]);

	useEffect(() => {
		if (focusEmail === false && !formData.email.value) {
			setFormData((state: any) => ({
				...state,
				email: {
					value: '',
					error: 'This field is required',
				},
			}));
		}
	}, [focusEmail]);

	useEffect(() => {
		if (focusPhone === false && !formData.phone.value) {
			setFormData((state: any) => ({
				...state,
				phone: {
					value: '',
					error: 'This field is required',
				},
			}));
		}
	}, [focusPhone]);

	useEffect(() => {
		if (focusMessage === false && !formData.message.value) {
			setFormData((state: any) => ({
				...state,
				message: {
					value: '',
					error: 'This field is required',
				},
			}));
		}
	}, [focusMessage]);

	useEffect(() => {
		// @ts-ignore
		setFormHeight(refForm?.current?.offsetHeight);
		// @ts-ignore
	}, [refForm?.current?.offsetHeight]);

	useEffect(() => {
		// @ts-ignore
		setFormHeight(refForm?.current?.offsetHeight);
	}, [submitted]);

	return (
		<div
			className={`${styles['form-component-wrapper']} ${
				className ? styles[className] : ''
			} form-component-wrapper`}
		>
			<AnimatePresence exitBeforeEnter>
				{!submitted ? (
					<motion.div
						key='popup'
						initial='enter'
						animate='animate'
						exit='exit'
						style={autoHeightForm ? { height: 'auto !important' } : {}}
						transition={{
							duration: 0.5,
						}}
						className={autoHeightForm ? styles['auto-height'] : ''}
						variants={switchFormStateAnimation}
					>
						<div
							ref={refForm}
							className={`
                ${styles['form-component']}
                form-component
                radius-${getCurrentRadius(stylesButtons.borderRadius)}
              `}
							style={stylesButtons}
						>
							<style>
								{stylesAdditionally === 'filled-with-border'
									? `.form-component  {border-color:${borderColor}!important}`
									: ''}
								{stylesButtons.background === 'rgba(0,0,0,0)'
									? `.form-component .form-component__button {background:${stylesButtons.background}!important}`
									: ''}
								{stylesButtons.background === 'rgba(0,0,0,0)'
									? `.form-component .form-component__button {color:${stylesButtons.color}!important}`
									: ''}
								{stylesButtons.background === 'rgba(0,0,0,0)'
									? `.form-component .form-component__button {border:${stylesButtons.border}!important}`
									: ''}
								{stylesButtons.background === 'rgba(0,0,0,0)'
									? '.form-component .form-component__button {border-width:2px !important}'
									: ''}
								{`.form-component__field path, .${styles['form-component__button_text']} path {fill:${stylesButtons.color}!important}`}
								{`.form-component input, .form-component textarea {box-shadow:${`inset 0 0 0 1px ${stylesButtons.color}`}!important}`}
								{
									'.form-component input:focus, .form-component textarea:focus {opacity:1!important}'
								}
								{`.form-component input::placeholder, .form-component textarea::placeholder {color:${`${stylesButtons.color}`}}`}
								{`.form-component input, .form-component textarea {color:${`${hexToRgb(
									stylesButtons.color,
									1,
								)}`}}`}
								{
									'.form-component input, .form-component textarea {border: none !important}'
								}
								{
									'.form-component .open {background: rgba(0,0,0,0) !important;}'
								}
								{
									'.form-component .selected-flag:hover {background: rgba(0,0,0,0) !important;}'
								}
								{'.form-component .open {transition: all .25s ease;}'}
							</style>
							{data.title ? (
								<h2
									style={!data.description ? { marginBottom: '16px' } : {}}
									className={styles['form-component__title']}
								>
									{data.title}
								</h2>
							) : (
								''
							)}
							{data.description ? (
								<p className={styles['form-component__description']}>
									{data.description}
								</p>
							) : (
								''
							)}
							{data.fields.name ? (
								<TextField
									name='name'
									inputMode='text'
									placeholder='Name*'
									value={formData.name.value}
									error={formData.name.error}
									autoFocus={formData.name.autoFocus}
									setFocus={setFocusName}
									onChange={updateFormData}
									style={stylesButtons}
									classes={{
										main: formData.name.value ? `${styles['opacity-off']}` : '',
										input: styles['form__input'],
									}}
								/>
							) : (
								''
							)}
							{data.fields.email ? (
								<TextField
									name='email'
									inputMode='text'
									placeholder='Email address*'
									value={formData.email.value}
									error={formData.email.error}
									onChange={updateFormData}
									setFocus={setFocusEmail}
									style={stylesButtons}
									classes={{
										main: formData.email.value
											? `${styles['opacity-off']}`
											: '',
										input: styles['form__input'],
									}}
								/>
							) : (
								''
							)}
							{data.fields.phone ? (
								<div className={styles['input-phone-wrapper']}>
									<PhoneInput
										onChange={value =>
											updateFormData({
												target: { value: `+${value}`, name: 'phone' },
											})
										}
										dropdownStyle={{ width: `${dropdownWidth}px` }}
										dropdownClass={styles['form-component__phone-dropdown']}
										searchStyle={{ height: '48px' }}
										containerStyle={{ height: '48px', marginBottom: '20px' }}
										inputStyle={stylesButtons}
										buttonStyle={{
											background: 'rgba(0,0,0,0)',
											boxShadow: 'none',
										}}
										buttonClass={styles['form-component__phone-btn']}
										inputClass={`${
											formData.phone.value ? `${styles['opacity-off']}` : ''
										} ${styles['form-component__phone']}`}
										onBlur={() => (setFocusPhone ? setFocusPhone(false) : {})}
										onFocus={() => (setFocusPhone ? setFocusPhone(true) : {})}
										country='us'
										inputProps={{
											ref,
											name: 'phone',
											required: true,
											placeholder: 'Phone number*',
										}}
									/>
									{formData.phone.error && (
										<motion.div
											initial='init'
											animate='animate'
											exit='exit'
											variants={errorAnimation}
											transition={{ duration: 0.35 }}
											className={styles['invalid-number-message']}
										>
											{formData.phone.error}
										</motion.div>
									)}
								</div>
							) : (
								''
							)}
							{data.fields.message ? (
								<TextField
									name='message'
									inputMode='text'
									type='textarea'
									placeholder='Message*'
									value={formData.message.value}
									error={formData.message.error}
									style={stylesButtons}
									onChange={updateFormData}
									classes={{
										main: formData.message.value
											? `${styles['opacity-off']}`
											: '',
										textarea: `${styles['input__field_textarea-dynamic-height']}`,
										input: formData.message.value
											? `${styles['opacity-off']}`
											: '',
									}}
									autoHeight
									setFocus={setFocusMessage}
								/>
							) : (
								''
							)}
							{data.buttonLabel ? (
								<a
									onClick={submitForm}
									style={{
										backgroundColor: stylesButtons.color,
										color: stylesButtons.background,
										borderRadius: stylesButtons.borderRadius,
										opacity: disabledBtn ? 0.5 : 1,
										cursor: disabledBtn ? 'default' : 'pointer',
									}}
									className={`form-component__button ${styles['form-component__button']}`}
								>
									<span
										style={
											stylesButtons.background.indexOf('linear-gradient') > -1
												? { background: stylesButtons.background }
												: {}
										}
										className={`
              ${styles['form-component__button_text']}
              ${
								stylesButtons.background.indexOf('linear-gradient') > -1
									? `${styles['form-component__button_text-gradient']}`
									: ''
							}
            `}
									>
										{submitting ? (
											<LoadingIcon className={styles['button__loading-icon']} />
										) : (
											data.buttonLabel
										)}
									</span>
								</a>
							) : (
								''
							)}
						</div>
					</motion.div>
				) : null}
			</AnimatePresence>
			<AnimatePresence initial={false} exitBeforeEnter>
				{submitted === 'success' ? (
					<motion.div
						key='success'
						initial='enter'
						animate='animate'
						exit='exit'
						transition={{
							duration: 0.3,
						}}
						style={{ height: formHeight }}
						variants={{
							init: {
								opacity: 0.5,
								height: formHeight,
							},
							animate: {
								opacity: 1,
								height: formHeight,
							},
							exit: {
								opacity: 0,
								height: formHeight,
							},
						}}
					>
						<FormFinish
							stylesButtons={stylesButtons}
							stylesAdditionally={stylesAdditionally}
							message={data.thankYouMessage}
							picture={avatar}
							borderColor={borderColor}
							setSubmitted={setSubmitted}
							formHeight={formHeight}
							className={className}
						/>
					</motion.div>
				) : null}
			</AnimatePresence>
			<AnimatePresence initial={false} exitBeforeEnter>
				{submitted === 'error' ? (
					<motion.div
						key='error'
						initial='enter'
						animate='animate'
						exit='exit'
						transition={{
							duration: 0.3,
						}}
						variants={switchFormStateAnimation}
					>
						<FormError
							stylesButtons={stylesButtons}
							stylesAdditionally={stylesAdditionally}
							borderColor={borderColor}
							setSubmitted={setSubmitted}
							formHeight={formHeight}
							className={className}
						/>
					</motion.div>
				) : null}
			</AnimatePresence>
		</div>
	);
};
