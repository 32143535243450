import React from 'react';
import { Helmet } from 'react-helmet';

type HelmetComponentProps = {
	siteData: any;
};

const HelmetComponent: React.FC<HelmetComponentProps> = ({ siteData }) => (
	<Helmet>
		<title>{siteData.name || 'Lift Bio site title'}</title>
		<link rel='icon' href={siteData.image || 'https://lift.bio/favicon.svg'} />
		<meta name='title' content={siteData.name || 'Lift Bio site title'} />
		<meta
			name='description'
			content={siteData.description || 'Lift Bio site description'}
		/>
		<meta
			name='image'
			content={siteData.image || 'https://lift.bio/favicon.svg'}
		/>
		<meta
			property='og:title'
			content={siteData.name || 'Lift Bio site title'}
		/>
		<meta
			name='og:description'
			content={siteData.description || 'Lift Bio site description'}
		/>
		<meta
			property='og:image'
			content={siteData.image || 'https://lift.bio/favicon.svg'}
		/>
		<meta
			property='og:image:secure_url'
			content={siteData.image || 'https://lift.bio/favicon.svg'}
		/>
		<meta property='og:url' content={window.location.href} />
		<meta property='profile:username' content={siteData.url} />
		<meta
			name='twitter:title'
			content={siteData.name || 'Lift Bio site title'}
		/>
		<meta
			name='twitter:description'
			content={siteData.description || 'Lift Bio site description'}
		/>
		<meta
			name='twitter:image'
			content={siteData.image || 'https://lift.bio/favicon.svg'}
		/>
		<link rel='canonical' href={window.location.href} />
		<meta property='og:image:height' content='600' />
		<meta property='og:image:type' content='image/jpg' />
		<meta property='og:image:width' content='600' />
		<meta name='twitter:card' content='summary_large_image' />
	</Helmet>
);

export default HelmetComponent;
