import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import {
	getCurrentBorder,
	getCurrentRadius,
	sortByKey,
} from '../../../../../helpers';
import { DefaultPopup } from '../../../DefaultPopup';
import { Body } from './Popup';
import { ShopCardZone } from './ShopCardZone';
import styles from './ShopMyLook.module.scss';

interface ShopMyLookProps {
	data: any;
	style: any;
}

export const ShopMyLook: React.FC<ShopMyLookProps> = ({ data, style }) => {
	const [isOpened, setIsOpened] = useState(false);
	const [actualizeArr, setActualizeArr] = useState([]);
	const openPopup = (element: number) => {
		if (data.tags.length > 1) {
			const arr: any = sortByKey(data.tags, 'position');
			const itemToFind = element > 0 ? arr[element - 1] : 0;
			const foundIdx = arr?.findIndex((el: any) => el === itemToFind);
			arr?.splice(foundIdx, 1);
			arr?.unshift(itemToFind);
			setActualizeArr(arr);
		}
		setIsOpened(true);
	};
	const closePopup = () => setIsOpened(false);

	return (
		<div
			className={`${styles['product']}`}
			style={{
				boxShadow: style.boxShadow,
				color: style.color,
				borderRadius: getCurrentRadius(style.borderRadius),
				border: getCurrentBorder(style),
			}}
		>
			<div
				className={styles['product__body']}
				style={{
					background: style.background,
				}}
			>
				<ShopCardZone data={data} openPopup={openPopup} />
				<AnimatePresence exitBeforeEnter initial={false}>
					{isOpened ? (
						<DefaultPopup
							id='shop-my-look'
							header='Products on this photo'
							onClose={closePopup}
							classes={{
								popup: styles['popup'],
								container: styles['popup__container'],
							}}
						>
							<Body
								data={data}
								actualizeArr={data.tags.length > 1 ? actualizeArr : data.tags}
							/>
						</DefaultPopup>
					) : (
						''
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};
