import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-circle.svg';
import { mainTransition } from '../../../framer-motion/transition';
import styles from './Dropdown.module.scss';

interface DropdownProps {
	placeholder: string;
	options: any[];
	classes?: string;
	onChange: any;
}

export const Dropdown: React.FC<DropdownProps> = ({
	placeholder,
	options,
	classes,
	onChange,
}) => {
	// States
	const [isOpened, setIsOpened] = React.useState(false);
	const [selectedValue, setSelectValue] = React.useState<any>(null);
	// remove focus when menu close animating
	const [disabledMenuButton, setDisabledMenuButton] =
		React.useState<boolean>(false);

	// Helpers
	const refButton = React.useRef<any>(null);
	const getDisplay = () => {
		if (selectedValue) return selectedValue.label;

		return placeholder;
	};
	const isSelected = (option: any) => {
		if (!selectedValue) return false;

		return option.value === selectedValue.value;
	};

	// Actions
	const dropdownClick = (event: any) => {
		event.stopPropagation();
		setIsOpened(state => !state);
		setDisabledMenuButton(false);
	};
	const itemClick = (option: any) => {
		onChange(option);
		refButton.current.focus();
		setSelectValue(option);
		setDisabledMenuButton(true);
	};

	// Lifecycle hooks
	React.useEffect(() => {
		const handler = () => setIsOpened(false);

		window.addEventListener('click', handler);
		return () => window.removeEventListener('click', handler);
	});

	// Animations
	const dropdownAnimation = {
		enter: {
			opacity: 0,
			y: '10%',
		},
		animate: {
			opacity: 1,
			y: 0,
		},
		exit: {
			opacity: 0,
			y: '10%',
		},
	};
	const itemSelectAnimation = {
		show: {
			opacity: 0,
			scale: 0,
		},
		hide: {
			opacity: 1,
			scale: 1,
		},
	};

	return (
		<div
			className={`
        ${styles['dropdown']}
        ${isOpened ? styles['dropdown_isOpened'] : ''}
        ${classes || ''}
      `}
		>
			<button
				ref={refButton}
				data-testid='dropdown__button'
				type='button'
				className={styles['dropdown__button']}
				onClick={dropdownClick}
			>
				<span className={styles['dropdown__button-text']}>{getDisplay()}</span>
				<ArrowIcon className={styles['dropdown__button-icon']} />
			</button>
			<AnimatePresence exitBeforeEnter>
				{isOpened ? (
					<motion.div
						key='dropdown'
						initial='enter'
						animate='animate'
						exit='exit'
						variants={dropdownAnimation}
						transition={mainTransition}
						className={styles['dropdown__list']}
						style={{
							x: '-50%',
						}}
					>
						{options.map((option, index) => (
							<div key={option.value}>
								<button
									type='button'
									ref={button => button && isSelected(option) && button.focus()}
									className={`${styles['dropdown__item']} ${
										isSelected(option) ? styles['dropdown__item_active'] : ''
									}`}
									onClick={() => itemClick(option)}
									disabled={disabledMenuButton}
								>
									<span className={styles['dropdown__item-text']}>
										{option.label}
									</span>
									<motion.span
										initial='hide'
										animate={isSelected(option) ? 'hide' : 'show'}
										variants={itemSelectAnimation}
										className={styles['dropdown__item-icon']}
									>
										<CheckIcon />
									</motion.span>
								</button>

								{options.length - 1 !== index ? (
									<div className={`${styles['dropdown__divider']}`} />
								) : (
									''
								)}
							</div>
						))}
					</motion.div>
				) : (
					''
				)}
			</AnimatePresence>
		</div>
	);
};
