export const initAmplitude = () => {
	window?.amplitude
		.getInstance()
		.init(`${process.env.REACT_APP_BUILD_AMPLITUDE}`);
};

export const setUserId = (userId: string) => {
	window?.amplitude.getInstance().setUserId(userId);
};

export const setDeviceId = (deviceId: string) => {
	window?.amplitude.getInstance().setDeviceId(deviceId);
};

export const setUserProperty = async (properties: any) =>
	window.amplitude.getInstance().setUserProperties(properties);

export const sendEvent = (
	eventType: any,
	eventProperties?: any,
	callback?: () => void,
) => {
	window?.amplitude.getInstance().logEvent(
		eventType,
		{
			...eventProperties,
			'current url': window.location.href,
		},
		callback,
	);
};
