import React from 'react';
import { Item } from '../Item';
import styles from './Body.module.scss';

// Props Types
interface BodyProps {
	data: any;
}

export const Body: React.FC<BodyProps> = ({ data }) => {
	const { image, title, links } = data;

	return (
		<div className={styles['body']}>
			<img
				id='multi-link-body-image'
				className={styles['body__image']}
				src={image}
				alt=''
			/>
			<h3 className={styles['body__title']}>{title}</h3>
			<div className={styles['body__list']}>
				{links.map((item: any, index: number) => (
					<Item key={`${item.button.value + index}`} item={item} data={data} />
				))}
			</div>
		</div>
	);
};
