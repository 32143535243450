import React from 'react';
import { ReactComponent as NotFoundIcon } from '../../assets/svg/notFoundIcon.svg';
import { ReactComponent as NotFoundTitle } from '../../assets/svg/notFoundTitle.svg';
import { Button } from '../../Components/elements/Button';
import { Header } from '../../Components/modules/Header';
import styles from './NotFound.module.scss';

export const NotFound: React.FC = () => {
	const onClick = () => {
		window.location.href =
			`${
				process.env.REACT_APP_BUILD_LIFT_BIO_LINK
			}?utm_source=404&utm_user_referrer=${window.location.pathname.slice(
				1,
			)}` ?? '';
	};

	return (
		<div className={styles['wrapper']}>
			<Header />
			<div className={`${styles['notFound']} ${styles['notFound-page']}`}>
				<div className={styles['notFound__container']}>
					<NotFoundIcon
						className={`${styles['notFound__decor-svg']} ${styles['notFound__decor-svg_top']}`}
					/>
					<NotFoundIcon
						className={`${styles['notFound__decor-svg']} ${styles['notFound__decor-svg_bottom']}`}
					/>

					<div className={styles['notFound__info']}>
						<NotFoundTitle className={`${styles['notFound__title']}`} />
						<p className={styles['notFound__subtitle']}>Sorry</p>
						<p className={styles['notFound__description']}>
							We can’t find the page you’re looking for
						</p>
						<div className={styles['notFound__action']}>
							<Button
								text='Create your free Lift Bio site'
								onclick={onClick}
								classes={{
									button: styles['notFound__button'],
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
