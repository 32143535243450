import React from 'react';
import arrowIcon from '../../../assets/svg/arrow2.svg';
import logo from '../../../assets/svg/logo.svg';
import styles from './FeaturedBioSiteBanner.module.scss';

type FeaturedBioSiteBannerProps = { url: string };

export const FeaturedBioSiteBanner: React.FC<FeaturedBioSiteBannerProps> = ({
	url,
}) => (
	<div className={`${styles['featured-bio-site-banner']}`}>
		<div className={`${styles['featured-bio-site-banner__logo']}`}>
			<img src={logo} alt='' />
		</div>
		<h3 className={`${styles['featured-bio-site-banner__title']}`}>
			Proficient influencers use Lift Bio
		</h3>
		<p className={`${styles['featured-bio-site-banner__description']}`}>
			to get the most from their socials
		</p>
		<a
			href={`https://${process.env.REACT_APP_BUILD_ENV}edit.lift.bio/funnel-split-router${window.location.search}&featured_biosite=${url}`}
			rel='noreferrer'
			target='_blank'
			className={styles['create-bio-site-button']}
			data-testid='create-bio-site-button'
			id='create-bio-site-button'
		>
			<img
				className={styles['create-bio-site-button__icon']}
				src={logo}
				alt='logo'
			/>
			<p className={styles['create-bio-site-button__text']}>
				Create a Lift bio site
			</p>
			<img
				src={arrowIcon}
				alt='arrow'
				className={styles['create-bio-site-button__icon-arrow']}
			/>
		</a>
	</div>
);
