import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { isDesktop } from 'react-device-detect';
import '../../assets/scss/Components/DonationComponent.scss';
import { OPEN_LINK_IN_EDITOR } from '../../constants/postMessageNames';
import { getCurrentBorder, getCurrentRadius } from '../../helpers';
import getParameterByName from '../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../hooks';
import { sendPostMessage } from '../../services/sendPostMessage';

type DonationComponentProps = {
	data: any;
	styles: any;
	stylesAdditionally: string;
	borderColor: string;
	blockStyles: any;
};

export const DonationComponent: React.FC<DonationComponentProps> = forwardRef(
	(
		{ data, styles, stylesAdditionally, borderColor, blockStyles },
		ref: any,
	) => {
		// Helpers
		const getLinkAttr = () => {
			if (isDesktop) {
				return { href: data.buttonUrl, target: '_blank', rel: 'noreferrer' };
			}
			return {};
		};
		// - hooks
		const sendAnalyticsAndOpenLink: (event: any) => void =
			useSendAnalyticsEvent({
				type: data.type,
				url: data.buttonUrl,
				data: {
					blockId: data.id,
					eventAction: 'out-click',
				},
			});

		// Actions
		const onButtonClick = (event: any) => {
			if (getParameterByName('previewMode')) {
				event?.preventDefault();
				sendPostMessage(OPEN_LINK_IN_EDITOR, { url: data.buttonUrl });

				return;
			}

			sendAnalyticsAndOpenLink(event);
		};

		return (
			<div
				className='donation-component block-component'
				style={{
					...styles,
					borderRadius: getCurrentRadius(blockStyles?.borderRadius),
					border: getCurrentBorder(blockStyles),
				}}
			>
				<style>
					{stylesAdditionally === 'filled-with-border'
						? `.donation-component  {border-color:${borderColor}!important}`
						: ''}
					{styles.background === 'rgba(0,0,0,0)'
						? `.donation-component .donation-component__button {background:${styles.background}!important}`
						: ''}
					{styles.background === 'rgba(0,0,0,0)'
						? `.donation-component .donation-component__button {color:${styles.color}!important}`
						: ''}
					{styles.background === 'rgba(0,0,0,0)'
						? `.donation-component .donation-component__button {border:${styles.border}!important}`
						: ''}
					{styles.background === 'rgba(0,0,0,0)'
						? '.donation-component .donation-component__button {border-width:2px !important}'
						: ''}
				</style>
				<h3
					className='donation-component__title'
					style={{
						color: styles.color,
					}}
				>
					{data.title}
				</h3>
				<p
					className='donation-component__description'
					style={{
						color: styles.color,
					}}
				>
					{data.description}
				</p>
				<a
					{...getLinkAttr()}
					ref={ref}
					style={{
						backgroundColor: styles.color,
						color: styles.background,
						borderRadius: styles.borderRadius,
					}}
					className='donation-component__button'
					onClick={onButtonClick}
				>
					<span
						style={
							styles.background.indexOf('linear-gradient') > -1
								? { background: styles.background }
								: {}
						}
						className={`
              donation-component__button-text
              ${
								styles.background.indexOf('linear-gradient') > -1
									? 'donation-component__button-text_gradient'
									: ''
							}
            `}
					>
						{data.buttonText}
					</span>
				</a>
			</div>
		);
	},
);

export const MDonationComponent = motion(DonationComponent);
