import React from 'react';
import headerLogo from '../../../assets/svg/logo.svg';
import styles from './Header.module.scss';

export const Header: React.FC = () => (
	<div className={styles['header']}>
		<a
			className={styles['header__logo']}
			href={
				`${
					process.env.REACT_APP_BUILD_LIFT_BIO_LINK
				}?utm_source=404&utm_user_referrer=${window.location.pathname.slice(
					1,
				)}` ?? ''
			}
		>
			<img className={styles['header__logo-img']} src={headerLogo} alt='logo' />
		</a>
	</div>
);
