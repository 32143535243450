import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { getCurrentBorder } from '../../../../../../helpers';
import { DefaultPopup } from '../../../../DefaultPopup';
import { Body, Header } from '../Popup';
import { Link } from './Link';
import styles from './Actions.module.scss';

// Props Types
interface ActionsProps {
	data: any;
	style: any;
}

export const Actions: React.FC<ActionsProps> = ({ data, style }) => {
	const { links } = data || {};

	// States
	const [isOpened, setIsOpened] = React.useState(false);

	// Helpers
	const getCurrentStyles = (
		type: 'background' | 'color' | 'border',
		currentStyle: any,
	) => {
		if (type === 'background') {
			return currentStyle.background === 'rgba(0,0,0,0)'
				? currentStyle.background
				: currentStyle.color;
		}

		if (type === 'color') {
			return currentStyle.background === 'rgba(0,0,0,0)'
				? currentStyle.color
				: currentStyle.background;
		}

		if (type === 'border') {
			return currentStyle.background === 'rgba(0,0,0,0)'
				? getCurrentBorder(currentStyle, '2px')
				: getCurrentBorder(currentStyle);
		}
	};
	const modifiedLinksList = React.useMemo(() => {
		if (links.length > 2) {
			return [
				links[0],
				{
					url: 'popup',
					button: {
						type: 'text',
						value: 'More',
					},
				},
			];
		}

		return links;
	}, [links]);

	// Actions
	const openPopup = () => setIsOpened(true);
	const closePopup = () => setIsOpened(false);

	return (
		<div
			className={styles['actions']}
			style={{
				border: getCurrentStyles('border', style),
				borderRadius: style.borderRadius,
			}}
		>
			{links.length > 1 ? (
				<div
					className={styles['actions__divider']}
					style={{
						background: getCurrentStyles('color', style),
					}}
				/>
			) : (
				''
			)}
			{modifiedLinksList.map((item: any, index: number) => {
				const { button } = item;
				return (
					<Link
						data={data}
						key={`${button.value + index}`}
						linkData={item}
						style={style}
						getCurrentStyles={getCurrentStyles}
						openPopup={openPopup}
					/>
				);
			})}
			<AnimatePresence exitBeforeEnter initial={false}>
				{isOpened ? (
					<DefaultPopup
						id='multi-link'
						onClose={closePopup}
						classes={{
							popup: styles['popup'],
							container: styles['popup__container'],
						}}
					>
						<Header
							popupId='multi-link'
							image={data.image}
							onClose={closePopup}
						/>
						<Body data={data} />
					</DefaultPopup>
				) : (
					''
				)}
			</AnimatePresence>
		</div>
	);
};
