import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import '../../assets/scss/Components/TweetComponent.scss';
import getParameterByName from '../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../hooks';

type TweetComponentProps = {
	type: string;
	url: string;
	placeholder: any;
	onLoad: () => void;
	onExit: () => void;
	analyticsData: {
		blockId: string;
		eventAction: 'out-click' | 'copy';
	};
};

const TweetComponent: React.FC<TweetComponentProps> = ({
	type,
	url,
	placeholder,
	onLoad,
	onExit,
	analyticsData,
}) => {
	const sendAnalytics: () => void = useSendAnalyticsEvent({
		type,
		data: analyticsData,
	});
	React.useEffect(() => {
		if (!getParameterByName('previewMode')) {
			sendAnalytics();
		}

		return onExit();
	}, []);

	return (
		<div className='tweet-component block-component'>
			<TwitterTweetEmbed
				onLoad={onLoad}
				placeholder={placeholder}
				options={{
					width: '100%',
				}}
				tweetId={url.split('/')[url.split('/').length - 1]}
			/>
		</div>
	);
};

export default TweetComponent;
