import React from 'react';
import Logo from '../../../assets/svg/bigLogo.svg';
import Spinner from '../ProgressBar/Spinner';
import styles from './Loader.module.scss';

interface LoaderProps {
	spinner?: boolean;
	classes?: any;
}

export const Loader: React.FC<LoaderProps> = ({ spinner, classes }) => (
	<div
		className={`
      ${styles['loader']}
      ${classes ? classes.loader : ''}
      ${spinner ? styles['loader_spinner'] : ''}
    `}
	>
		{spinner ? (
			<Spinner
				classes={{
					spinner: `${styles['loader__spinner']} ${
						classes ? classes.spinner : ''
					}`,
					body: `${styles['loader__spinner-body']} ${
						classes ? classes.body : ''
					}`,
				}}
				animationDuration={250}
			/>
		) : (
			<div className={styles['loader__logo']}>
				<img src={Logo} alt='Lift logo' />
			</div>
		)}
	</div>
);
