import React from 'react';
import type { IContext } from '../../types';

const initialContext: IContext = {
	title: 'title',
	currency: 'USD',
	currencySymbol: '$',
	categories: [
		{
			id: 'category1',
			title: 'title',
			position: 0,
			hidden: false,
			items: [],
		},
	],
	items: [],
	itemsWithoutCategory: [],
	activeCategory: {
		id: 'category1',
	},
	activeProduct: {
		isShown: false,
		item: null,
	},
	onClose: () => {},
	updateActiveCategory: () => {},
	updateActiveItem: () => {},
};
export const PopupContext = React.createContext(initialContext);
