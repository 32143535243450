export function sortByKey(arr: any[], key: string) {
	arr?.sort((a: any, b: any) => {
		const keyA = a[key];
		const keyB = b[key];
		// Compare the 2 dates
		if (keyA < keyB) return -1;
		if (keyA > keyB) return 1;
		return 0;
	});
	return arr;
}
