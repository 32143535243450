import React, { useEffect, useState } from 'react';
import thumbIcon from '../../../assets/svg/thumb.svg';
import { getCurrentRadius } from '../../../helpers';
import styles from './FormFinish.module.scss';

interface FormFinishProps {
	message: string;
	picture: string;
	stylesButtons: any;
	stylesAdditionally: string;
	borderColor: string;
	setSubmitted: React.Dispatch<React.SetStateAction<string>>;
	formHeight: number;
	className?: string;
}

export const FormFinish = ({
	message,
	picture,
	stylesButtons,
	stylesAdditionally,
	borderColor,
	setSubmitted,
	formHeight,
	className,
}: FormFinishProps) => {
	const btnClick = () => {
		setSubmitted('');
	};

	const [innerStyles, setInnerStyles] = useState(stylesButtons);

	useEffect(() => {
		setInnerStyles((state: any) => ({
			...state,
			minHeight: formHeight,
		}));
	}, [stylesButtons]);

	return (
		<div
			className={`
        ${className ? styles[className] : ''}
        ${styles['form-finish']}
        form-finish
        radius-${getCurrentRadius(stylesButtons.borderRadius)}
        `}
			style={innerStyles}
		>
			<style>
				{stylesAdditionally === 'filled-with-border'
					? `.form-finish  {border-color:${borderColor}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-finish .form-finish__button {background:${stylesButtons.background}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-finish .form-finish__button {color:${stylesButtons.color}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.form-finish .form-finish__button {border:${stylesButtons.border}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? '.form-finish .form-finish__button {border-width:2px !important}'
					: ''}
				{'.form-finish .open {box-shadow: inset 0 0 0 2px #40BCA3}'}
				{'.form-finish .open {border-radius: 3px 0 0 3px !important;}'}
			</style>
			{message ? (
				<h2 className={`${styles['form-finish__title']}`}>
					Your request submitted
				</h2>
			) : (
				''
			)}
			<div className={`${styles['content-wrapper']}`}>
				<img
					className={`${styles['form-finish__avatar']} ${
						!message ? styles['form-finish__avatar-default'] : ''
					}`}
					src={message ? picture : thumbIcon}
					alt=''
				/>
				{!message ? (
					<h2 className={`${styles['form-finish__title']}`}>
						Your request submitted
					</h2>
				) : (
					''
				)}
				{message ? (
					<p className={styles['form-finish__message']}>{message}</p>
				) : (
					''
				)}
			</div>
			<a
				onClick={btnClick}
				style={{
					backgroundColor: stylesButtons.color,
					color: stylesButtons.background,
					borderRadius: stylesButtons.borderRadius,
				}}
				className={`form-finish__button ${styles['form-finish__button']}`}
			>
				<span
					style={
						stylesButtons.background.indexOf('linear-gradient') > -1
							? { background: stylesButtons.background }
							: {}
					}
					className={`
            ${styles['form-finish__button_text']}
            ${
							stylesButtons.background.indexOf('linear-gradient') > -1
								? `${styles['form-finish__button_text-gradient']}`
								: ''
						}
          `}
				>
					Ok, great
				</span>
			</a>
		</div>
	);
};
