import React from 'react';
import { DefaultPopup } from '../../../DefaultPopup';
import type { IMenu } from '../types';
import { Body } from './components/Body';
import { PopupProvider } from './context';
import styles from './Popup.module.scss';

// Types
interface IProps {
	data: IMenu;
	onClose: () => void;
}

export const Popup: React.FC<IProps> = React.memo(({ data, onClose }) => (
	<DefaultPopup
		onClose={onClose}
		escapeButton={false}
		classes={{
			popup: styles['popup'],
			container: styles['popup__container'],
		}}
	>
		<PopupProvider data={data} onClose={onClose}>
			<Body />
		</PopupProvider>
	</DefaultPopup>
));
