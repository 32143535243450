export const chooseFont = (name: string) => {
	switch (name) {
		case 'Noto Sans':
			return 'NotoSans';
		case 'Noto Serif':
			return 'NotoSerif';
		default:
			return name;
	}
};
