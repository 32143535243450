import { useEffect } from 'react';

export default function useOutsideClicker(
	ref: any,
	data: boolean,
	passData?: any,
) {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				!ref.current ||
				(ref.current && !ref.current.contains(event.target))
			) {
				if (passData) {
					passData(false);
				} else {
					passData(true);
				}
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
			document.addEventListener('touchstart', handleClickOutside);
		};
	}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}
