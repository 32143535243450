import requestInstance from '../helpers/requestInstance';

export async function pageViewEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/page-view`,
		data,
		'post',
	);
	return result;
}

export async function blockClickEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/block-click`,
		data,
		'post',
	);
	return result;
}

export async function buttonClickEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/button-click`,
		data,
		'post',
	);
	return result;
}

export async function socialLinkClickEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/social-link-click`,
		data,
		'post',
	);
	return result;
}

export async function shareClickEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/share-click`,
		data,
		'post',
	);
	return result;
}

export async function wannaSiteLikeClickEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/wanna-site-like-this-click`,
		data,
		'post',
	);
	return result;
}

export async function sendForm(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/form-fill`,
		data,
		'post',
	);
	return result;
}

export async function referralTestsVisitEvent(data: object) {
	const result = await requestInstance(
		`${process.env.REACT_APP_BUILD_API}/analytics/event/referral-tests-visit`,
		data,
		'post',
	);
	return result;
}
