import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow.svg';
import { DefaultPopup } from '../DefaultPopup';
import { List } from './List';
import { useGetOpeningHours } from './useGetOpeningHours';
import styles from './WorkHours.module.scss';

// Props Types
type HourTypes = {
	hour: number;
	minute: number;
};
type FromToTypes = {
	from: HourTypes;
	to: HourTypes;
};
type WeeksTypes = {
	sunday: FromToTypes;
	monday: FromToTypes;
	tuesday: FromToTypes;
	wednesday: FromToTypes;
	thursday: FromToTypes;
	friday: FromToTypes;
	saturday: FromToTypes;
};
type DataTypes = {
	format: string;
	weeks: WeeksTypes;
};
interface WorkHoursProps {
	data: DataTypes;
	style: any;
}

export const WorkHours: React.FC<WorkHoursProps> = ({ data, style }) => {
	// States
	const [isOpened, setIsOpened] = React.useState(false);

	// Helpers
	const color = style?.page.color;
	const align = style?.page.align;
	const [openingHoursToday, openingHours] = useGetOpeningHours(data);

	// Actions
	const openPopup = () => setIsOpened(true);
	const closePopup = () => setIsOpened(false);

	return (
		<div
			className={styles['work-hours']}
			style={{
				color,
				textAlign: align,
			}}
		>
			<button
				type='button'
				className={styles['work-hours__button']}
				onClick={openPopup}
				data-testid='work-hours-button'
			>
				<span>{openingHoursToday}</span>
				<ArrowIcon className={styles['work-hours__icon']} />
			</button>

			<AnimatePresence exitBeforeEnter initial={false}>
				{isOpened ? (
					<DefaultPopup
						id='work-hours'
						header='Opening Hours'
						onClose={closePopup}
						classes={{
							popup: styles['popup'],
							container: styles['popup__container'],
						}}
					>
						<div className={styles['popup__body']}>
							<List openingHours={openingHours} />
						</div>
					</DefaultPopup>
				) : (
					''
				)}
			</AnimatePresence>
		</div>
	);
};
