import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import styles from './LottieAnimation.module.scss';

interface LottieAnimationProps {
	data: string;
}

export const LottieAnimation: React.FC<LottieAnimationProps> = ({ data }) => {
	const preserveAspectRatioCondition = () =>
		data.includes('waves') ? 'none' : 'xMidYMid slice';

	return (
		<Player
			loop
			autoplay
			src={data}
			className={styles['lottie']}
			rendererSettings={{ preserveAspectRatio: preserveAspectRatioCondition() }}
		/>
	);
};
