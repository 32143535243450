export const getCurrentBorder = (styleForBorder: any, borderWidth?: string) => {
	const { border, buttonStylesAdditionally, borderColor } = styleForBorder;

	if (!border) return 'none';

	const borderSplitter = border.split(' ');
	const getBorderWidth = borderWidth || borderSplitter[0];
	const getBorderStyle = borderSplitter[1];
	const getBorderColor = borderSplitter[2];

	if (buttonStylesAdditionally === 'filled-with-border') {
		return `${getBorderWidth} ${getBorderStyle} ${borderColor}`;
	}
	if (buttonStylesAdditionally === 'transparent') {
		return `${getBorderWidth} ${getBorderStyle} ${
			getBorderColor.includes('linear-gradient') ? borderColor : getBorderColor
		}`;
	}
	if (buttonStylesAdditionally === 'shadow') {
		return `${getBorderWidth} ${getBorderStyle} ${getBorderColor}`;
	}
	if (buttonStylesAdditionally === 'default') {
		return 'none';
	}
};
