import React from 'react';
import '../../assets/scss/Components/LinksList.scss';
import LinkComponent from '../LinkComponent/LinkComponent';

type LinksListProps = {
	links: any[any];
	color: string;
};

const LinksList: React.FC<LinksListProps> = ({ links, color }) => (
	<div className='links-list'>
		{links.map((item: any, index: number) => {
			if (!item.hidden) {
				return (
					<LinkComponent link={item} key={index} color={color} id={item.id} />
				);
			}
			return '';
		})}
	</div>
);

export default LinksList;
