import React from 'react';
import { isMobile } from 'react-device-detect';
import getParameterByName from '../../../helpers/getParameterByName';
import { PopupWrapper } from './PopupWrapper';
import styles from './Report.module.scss';

interface ReportProps {
	color: object;
}

export const Report: React.FC<ReportProps> = ({ color }) => {
	// States
	const [isOpened, setIsOpened] = React.useState<boolean>(false);
	const [isSent, setIsSent] = React.useState<boolean>(false);

	// Helpers
	const previewMode = !!getParameterByName('previewMode');

	// Actions
	const closePopup = () => {
		setIsOpened(false);
	};
	const formDataSent = () => {
		setIsSent(true);
		if (isMobile) setIsOpened(false);
	};
	const resetReport = () => {
		setIsOpened(false);
		setIsSent(false);
	};

	return (
		<>
			<button
				type='button'
				className={`
        ${styles['report-button']}
        `}
				style={{
					...color,
				}}
				onClick={() => setIsOpened(state => !state)}
				disabled={previewMode}
			>
				Report
			</button>
			<PopupWrapper
				isOpened={isOpened}
				isSent={isSent}
				onClose={closePopup}
				formDataSent={formDataSent}
				resetReport={resetReport}
			/>
		</>
	);
};
