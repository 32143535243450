import { useEffect, useState } from 'react';

interface Size {
	width: number | undefined;
	height: number | undefined;
}

export default function useWindowSize(): Size {
	const [windowSize, setWindowSize] = useState<Size>({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (/mobi/i.test(navigator.userAgent)) {
			window.addEventListener('orientationchange', handleResize);
		} else {
			window.addEventListener('resize', handleResize);
		}
		handleResize();
		return () => {
			if (/mobi/i.test(navigator.userAgent)) {
				window.addEventListener('orientationchange', handleResize);
			} else {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, []);
	return windowSize;
}
