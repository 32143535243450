import React from 'react';
import { isIOS } from 'react-device-detect';
import Cookies from 'universal-cookie';
import arrowIcon from '../../../assets/svg/arrow2.svg';
import logo from '../../../assets/svg/logo.svg';
import getParameterByName from '../../../helpers/getParameterByName';
import { useGetUserId } from '../../../hooks';
import { wannaSiteLikeClickEvent } from '../../../services/trackingApi';
import styles from './CreateBioSiteButton.module.scss';

type CreateBioSiteButtonProps = {
	setClicked: React.Dispatch<React.SetStateAction<boolean>>;
	siteData: any;
};

export const CreateBioSiteButton: React.FC<CreateBioSiteButtonProps> = ({
	setClicked,
	siteData,
}) => {
	const cookies = new Cookies();
	const { getUserId } = useGetUserId();
	const previewMode = !!getParameterByName('previewMode');

	const btnClick = () => {
		setClicked(true);
		const expiryDate = new Date();
		expiryDate.setMonth(expiryDate.getMonth() + 1);
		cookies.set('CreateBioSiteButtonClicked', expiryDate, { path: '/' });
		wannaSiteLikeClickEvent({
			siteId: siteData.id,
			userId: getUserId(),
		}).then();
	};

	const defaultUrl = `https://${process.env.REACT_APP_BUILD_ENV}edit.lift.bio/funnel-split-router?utm_source=referral_lift_bio&utm_user_referrer=${siteData.url}`;
	const iosUrl = 'https://lift.onelink.me/KndT/gjnlxs63';

	const renderButtonBody = () => (
		<>
			<img
				className={styles['create-bio-site-button__icon']}
				src={logo}
				alt='logo'
			/>
			<p className={styles['create-bio-site-button__text']}>
				Create a Lift bio site
			</p>
			<img
				src={arrowIcon}
				alt='arrow'
				className={styles['create-bio-site-button__icon-arrow']}
			/>
		</>
	);

	return !previewMode ? (
		<a
			href={isIOS ? iosUrl : defaultUrl}
			rel='noreferrer'
			target='_blank'
			className={styles['create-bio-site-button']}
			data-testid='create-bio-site-button'
			onClick={btnClick}
			id='create-bio-site-button'
		>
			{renderButtonBody()}
		</a>
	) : (
		<div
			id='create-bio-site-button'
			className={`${styles['create-bio-site-button']} ${styles['create-bio-site-button_disabled']}`}
		>
			{renderButtonBody()}
		</div>
	);
};
