import React from 'react';
import type { DietaryInfoType } from '../../../types';
import { dietaryOptions } from '../../data';
import styles from './Diet.module.scss';

// Types
interface IProps {
	options?: Array<DietaryInfoType>;
	withTitle?: boolean;
	classes?: {
		diet?: string;
		item?: string;
		icon?: string;
	};
}

export const Diet: React.FC<IProps> = ({ classes, options, withTitle }) => {
	const { diet, item, icon } = classes ?? {};

	const normalizedOptions = React.useMemo(() => {
		const filteredOptions = dietaryOptions.filter(option =>
			options?.includes(option.id),
		);
		const sortedOptions = [...filteredOptions].sort(
			(a, b) => a.position - b.position,
		);

		return options ? sortedOptions : dietaryOptions;
	}, [options]);

	return (
		<div className={`${styles['diet']} ${diet ?? ''}`}>
			{normalizedOptions.map(option => {
				const Icon = option.icon;

				return (
					<div
						key={option.id}
						className={`${styles['diet__item']} ${item ?? ''}`}
					>
						<Icon
							className={`
                ${styles['diet__icon']}
                ${withTitle ? styles['diet__icon_with-title'] : ''}
                ${icon ?? ''}
              `}
							title={option.title}
						/>
						{withTitle ? (
							<div className={styles['diet__title']}>{option.title}</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
};
