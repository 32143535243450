import { NODE_ID } from '../constants';

export const scrollToActiveCategoryButton = (id: string) => {
	const list = document.getElementById(NODE_ID.POPUP_HEADER_LIST);
	if (list) {
		const listWidth = list.offsetWidth ?? 0;
		const elementOffsetLeft =
			document?.getElementById(`button-${id}`)?.offsetLeft ?? 0;
		const elementOffsetWidth =
			document?.getElementById(`button-${id}`)?.offsetWidth ?? 0;

		list.scroll({
			left: elementOffsetLeft + elementOffsetWidth / 2 - listWidth / 2,
			behavior: 'smooth',
		});
	}
};
