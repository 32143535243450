import { motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';
import styles from './Header.module.scss';

// Props Types
interface HeaderProps {
	title: string;
	onClose: () => void;
	popupId?: string;
}

export const Header: React.FC<HeaderProps> = ({ title, popupId, onClose }) => {
	// States
	const [scrolling, setScrolling] = React.useState({
		sticky: false,
	});

	// Helpers
	const goTop = () => {
		if (!popupId) return;

		const scrollContainer = document.getElementById(popupId);
		scrollContainer?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// Lifecycle hooks
	React.useEffect(() => {
		if (!popupId) return;

		const scrollContainer = document.getElementById(popupId);
		const scrollHandler = () => {
			if (scrollContainer) {
				setScrolling(state => ({
					...state,
					sticky: scrollContainer.scrollTop > 1,
				}));
			}
		};

		scrollContainer?.addEventListener('scroll', scrollHandler);
		return () => scrollContainer?.removeEventListener('scroll', scrollHandler);
	}, []);

	return (
		<motion.div
			animate={{
				boxShadow: scrolling.sticky
					? '0 2px 10px rgba(13, 13, 13, 0.15)'
					: '0 2px 10px rgba(13, 13, 13, 0)',
			}}
			transition={{
				duration: 0.25,
			}}
			className={styles['header']}
			data-testid='DefaultPopup-header'
		>
			<div
				onClick={popupId ? goTop : undefined}
				className={styles['header__wrapper']}
				data-testid='DefaultPopup-wrapper'
			>
				<h2
					className={styles['header__title']}
					data-testid='DefaultPopup-title'
				>
					{title}
				</h2>
				<motion.div
					onClick={onClose}
					className={styles['header__close']}
					whileTap={{ scale: 0.8 }}
					whileHover={{ rotate: '90deg' }}
					transition={{
						duration: 0.25,
					}}
				>
					<CloseIcon className={styles['header__close-icon']} />
				</motion.div>
			</div>
		</motion.div>
	);
};
