export const defaultPopupAnimation = {
	show: {
		opacity: 1,
		y: 0,
	},
	hide: {
		opacity: 0,
		y: '50%',
	},
};
