import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow.svg';
import { getCurrentBorder, getCurrentRadius } from '../../../helpers';
import { useDebounce } from '../../../hooks';
import styles from './Accordion.module.scss';

interface AccordionProps {
	accordionIndex: number;
	contentLoader?: boolean | 'disabled';
	type: string;
	preview: any;
	style: any;
	expanded: number | boolean;
	setExpanded: any;
	children: any;
}

// Animations
const previewAnimation = {
	hide: {
		opacity: 0,
		height: 0,
	},
	show: {
		opacity: 1,
		height: 'auto',
	},
};
const bodyAnimation = {
	init: {
		opacity: 0,
		height: 0,
	},
	animate: {
		opacity: 1,
		height: 'auto',
	},
	exit: {
		opacity: 0,
		height: 0,
	},
};
const contentAnimation = {
	init: {
		height: 200,
	},
	animate: (loader: boolean) => ({
		height: loader ? 'auto' : 200,
	}),
};

// Transition
const defaultTransition = {
	duration: 0.5,
};

export const Accordion: React.FC<AccordionProps> = ({
	accordionIndex,
	contentLoader,
	type,
	preview,
	style,
	expanded,
	setExpanded,
	children,
}) => {
	// Data
	const { image, text, title, Icon, expandIcon } = preview;

	// Helpers
	const isOpen = accordionIndex === expanded;
	const refBody: any = React.useRef(null);
	// - hooks
	const debounceScrollToBlock = useDebounce(() => {
		const extraOffset = 10;
		const titleHeight = 60;
		const offsetTop =
			refBody.current.getBoundingClientRect().top -
			document.body.getBoundingClientRect().top -
			(titleHeight + extraOffset);
		window.scrollTo({
			top: offsetTop,
			behavior: 'smooth',
		});
	}, 500);
	const isForm = type === 'form';
	const getBorderRadius = () => {
		if (isForm) {
			if (isOpen) {
				return getCurrentRadius(style.borderRadius);
			}

			return style.borderRadius;
		}

		return getCurrentRadius(style.borderRadius);
	};

	// Lifecycle hooks
	React.useEffect(() => {
		// scroll to the block when he is open
		if (isOpen && contentLoader === 'disabled') {
			debounceScrollToBlock();
		}
	}, [isOpen]);
	React.useEffect(() => {
		// scroll to the block when he is open and loading
		if (isOpen && contentLoader) {
			debounceScrollToBlock();
		}
	}, [contentLoader]);

	// Actions
	const openAccordion = () => {
		setExpanded(accordionIndex);
	};

	return (
		<div
			className={`
        ${styles['accordion']}
        ${styles[`accordion_${type}`]}
        ${isOpen ? styles['accordion_isOpen'] : ''}
        ${image ? styles['accordion_withImage'] : ''}
        ${expandIcon ? styles['accordion_withExpandIcon'] : ''}
      `}
			style={{
				background: style.background,
				boxShadow: style.boxShadow,
				color: style.color,
				borderRadius: getBorderRadius(),
				border: getCurrentBorder(style),
			}}
		>
			<motion.button
				animate={!isOpen ? 'show' : 'hide'}
				variants={previewAnimation}
				transition={defaultTransition}
				onClick={openAccordion}
				data-testid='accordion__preview'
				className={styles['accordion__preview']}
			>
				<div className={styles['accordion__wrapper']}>
					{image ? (
						<div className={styles['accordion__picture']}>
							<img
								className={styles['accordion__image']}
								style={{ borderRadius: getCurrentRadius(style.borderRadius) }}
								src={image}
								alt='logo'
							/>
							{Icon ? <Icon className={styles['accordion__icon']} /> : ''}
						</div>
					) : (
						''
					)}
					<div className={styles['accordion__info']}>
						<div className={styles['accordion__title']}>{title}</div>
						<div className={styles['accordion__text']}>{text}</div>
					</div>
					{expandIcon ? (
						<ArrowIcon className={styles['accordion__icon-expand']} />
					) : (
						''
					)}
				</div>
			</motion.button>
			<AnimatePresence exitBeforeEnter initial={false}>
				{isOpen ? (
					<motion.div
						ref={refBody}
						key={type + accordionIndex}
						initial='init'
						animate='animate'
						exit='exit'
						variants={bodyAnimation}
						transition={defaultTransition}
						className={styles['accordion__body']}
					>
						<motion.div
							initial='init'
							animate='animate'
							variants={contentLoader === 'disabled' ? {} : contentAnimation}
							custom={contentLoader}
							transition={defaultTransition}
							className={styles['accordion__content']}
						>
							{children}
						</motion.div>
						<div className={styles['accordion__actions']}>
							<button
								type='button'
								className={styles['accordion__button-arrow']}
								onClick={() => setExpanded((state: any) => !state)}
							>
								<ArrowIcon className={styles['accordion__icon-arrow']} />
							</button>
						</div>
					</motion.div>
				) : (
					''
				)}
			</AnimatePresence>
		</div>
	);
};
