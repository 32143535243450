import React from 'react';

export function useEventScroll(
	target: string,
	callback: (e: any) => void,
	event?: string,
	eventOptions?: Object,
) {
	React.useEffect(() => {
		const element = document.getElementById(target);

		if (element) {
			const handler = (e: any) => {
				callback(e);
			};

			element.addEventListener(event ?? 'scroll', handler, eventOptions);

			return () => {
				element.removeEventListener(event ?? 'scroll', handler);
			};
		}
	}, []);
}
