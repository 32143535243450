import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { RWebShare } from 'react-web-share';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import defaultAvatar from '../assets/images/defaultAvatar.png';
import defaultLogo from '../assets/images/logo.png';
import '../assets/scss/Pages/Site.scss';
import shareIcon from '../assets/svg/share.svg';
import BlocksList from '../Components/BlocksList/BlocksList';
import ButtonsList from '../Components/ButtonsList/ButtonsList';
import { Link } from '../Components/elements/Link';
import { Loader } from '../Components/elements/Loader';
import { ProgressBar } from '../Components/elements/ProgressBar';
import HelmetComponent from '../Components/HelmetComponent/HelmetComponent';
import LinksList from '../Components/LinksList/LinksList';
import LogoComponent from '../Components/LogoComponent/LogoComponent';
import { Background } from '../Components/modules/Background';
import { CreateBioSiteButton } from '../Components/modules/CreateBioSiteButton';
import { CreateBioSiteLink } from '../Components/modules/CreateBioSiteLink';
import { FeaturedBioSiteBanner } from '../Components/modules/FeaturedBioSiteBanner';
import { GoogleInformation } from '../Components/modules/GoogleInformation';
import { Report } from '../Components/modules/Report';
import { Share } from '../Components/modules/Share';
import { Title } from '../Components/modules/Title';
import { WorkHours } from '../Components/modules/WorkHours';
import { PreloadingData } from '../Components/PreloadingData/PreloadingData';
import { SiteContext } from '../context/siteContext';
import { chooseFont, sortByKey } from '../helpers';
import getParameterByName from '../helpers/getParameterByName';
import {
	useDebounce,
	useFetch,
	useUpdateCssScrollWidthVariable,
} from '../hooks';
import { useMedia } from '../hooks/useCustomMediaQuery';
import { getPreviewData, getSiteData } from '../services/apiLiftBio';
import getCountryCode from '../services/getCountryCode';
import { pageViewEvent, shareClickEvent } from '../services/trackingApi';
import { NotFound } from './NotFound';

const initialSiteState: any = {
	url: '',
	name: '',
	paid: false,
	description: '',
	appearance: {
		background: {
			image: null,
			colorGradient: '',
			layers: [
				{
					type: '',
					value: '',
				},
			],
		},
		color: '',
		font: '',
		theme: '',
		headerLayout: {
			align: '',
			imageShape: '',
			bgStyle: '',
		},
	},
	blocks: [],
	buttons: [],
	buttonsAppearance: {
		borderColor: '',
		background: '',
		color: '',
		cornerRadius: '',
		style: '',
	},
	socialLinks: [],
	image: '',
	logo: '',
	id: '',
	structure: {
		buttonsPosition: 2,
		socialLinksPosition: 1,
	},
	verified: false,
	workHours: null,
	location: null,
};

// Animation
const previewShowAnimation = {
	init: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};
const previewShowTransition = {
	ease: 'easeIn',
	duration: 0.25,
};

// Canceling request
const { CancelToken } = axios;

const Site: React.FC = () => {
	const cookies = new Cookies();
	const createBioSiteButtonClicked = cookies.get('CreateBioSiteButtonClicked');

	// States
	// TODO need refactoring and decompose
	const [siteDataState, setSiteDataState] = useState(initialSiteState);

	const [nameColor, setNameColor] = useState('#0C1329');
	const [nameFont, setNameFont] = useState('inherit');
	const [background, setBackground] = useState('#FFFFFF');
	const [theme, setTheme] = useState('#FFFFFF');
	const [descriptionColor, setDescriptionColor] = useState('#0C1329');
	const [contentTextColor, setContentTextColor] = useState('#000000');
	const [buttonBackground, setButtonBackground] = useState('#0C1329');
	const [buttonColor, setButtonColor] = useState('#FFFFFF');
	const [buttonCornerRadius, setButtonCornerRadius] = useState('4px');
	const [buttonStyle, setButtonStyle] = useState('default');
	const [pageStyles, setPageStyles] = useState({
		background: '',
		color: '#FFFFFF',
		align: 'center',
	});

	const [firstLoading, setFirstLoading] = useState(true);
	const [previewLoading, setPreviewLoading] = useState({
		isAnimating: false,
		key: 0,
	});
	const [siteNotFound, setSiteNotFound] = useState(false);
	const [updatePreview, setUpdatePreview] = useState(null);
	const [clicked, setClicked] = useState(createBioSiteButtonClicked || false);
	const [blocksWithHighlight, setBlocksWithHighlight] = useState(0);
	const [showSharedMenu, setShowSharedMenu] = useState(false);
	const [userId, setUserId]: string | any = useState(null);
	const [googleData, setGoogleData] = useState(null);
	const [workHours, setWorkHours] = useState<any>(null);

	// Helpers
	const getSiteName = window.location.pathname.split('/')[1];
	const getSiteToken = md5(getSiteName);
	const previewMode = !!getParameterByName('previewMode');
	const onBoarding = !!getParameterByName('onboarding');
	const landingFeaturedBioSites =
		getParameterByName('test_name') === 'landing_featured_biosites';
	const showLogo =
		siteDataState.logo &&
		(onBoarding ||
			!clicked ||
			(siteDataState.logo?.includes('logo/lift_light.svg') === false &&
				siteDataState.logo?.includes('logo/lift.svg') === false));
	const showCreateBioSiteButton =
		!previewMode &&
		!onBoarding &&
		!clicked &&
		!siteDataState.paid &&
		(siteDataState.logo?.includes('logo/lift_light.svg') === true ||
			siteDataState.logo?.includes('logo/lift.svg') === true);
	const showCreateBioSiteLink =
		!previewMode &&
		!onBoarding &&
		clicked &&
		!siteDataState.paid &&
		(siteDataState.logo?.includes('logo/lift_light.svg') === true ||
			siteDataState.logo?.includes('logo/lift.svg') === true);
	const styles = {
		name: {
			color: nameColor,
		},
		description: {
			color: descriptionColor,
		},
		page: {
			background: pageStyles.background || theme,
			color: pageStyles.color,
			align: pageStyles.align,
		},
		button: {
			background:
				buttonStyle === 'transparent' ? 'rgba(0,0,0,0)' : buttonBackground,
			color: siteDataState.buttonsAppearance.color || buttonColor,
			borderRadius: buttonCornerRadius,
			border:
				buttonStyle !== 'shadow'
					? `1px solid ${buttonBackground}`
					: '1px solid #000000',
			boxShadow: buttonStyle === 'shadow' ? '2.4px 2.4px 0 #000000' : '',
		},
		text: {
			color: contentTextColor,
		},
	};
	// - hooks
	const cancelSource = React.useRef<any>(null);
	const [fetchData, siteData]: any = useFetch(
		async () => {
			generateToken(); // eslint-disable-line @typescript-eslint/no-use-before-define

			return previewMode
				? getPreviewData(getSiteName, cancelSource.current?.token)
				: getSiteData(getSiteToken, cancelSource.current?.token);
		},
		siteDataState.id ? siteDataState : initialSiteState,
	);
	const debounceLoading = useDebounce(
		(isLoading: boolean) => setFirstLoading(isLoading),
		500,
	);
	const memoizationContext: { userId: string; siteDataState: any } =
		React.useMemo(
			() => ({
				userId,
				siteDataState,
			}),
			[userId, siteDataState],
		);
	const isMediaMobile = useMedia('s');
	// - methods
	const setUserIdInCookieAndState = () => {
		if (!navigator.cookieEnabled) {
			const uid = uuidv4();
			setUserId(uid);
			return;
		}

		if (!cookies.get('uid')) {
			const uid = uuidv4();
			setUserId(uid);

			const timestamp = new Date().getTime();
			const expireTime = timestamp + 60 * 60 * 24 * 1000 * 365;

			cookies.set('uid', uid, {
				path: window.location.pathname,
				maxAge: expireTime,
			});
		} else {
			setUserId(cookies.get('uid'));
		}
	};
	const generateToken = () => {
		cancelSource.current = CancelToken.source();
	};
	const cancelingRequest = () => cancelSource.current?.cancel();
	const pageEventRun = (countryCode: string) => {
		pageViewEvent({
			siteId: siteDataState.id,
			userId: cookies.get('uid') ?? userId,
			countryCode,
			referrer: document?.referrer,
		})
			.then()
			.catch(error => console.log('pageViewEvent - ', error));
	};
	const normalizedWorkHours = () => {
		const onlyWeeks = Object.keys(siteDataState.workHours)
			.filter(key => key !== 'format')
			.reduce((days, currentDay) => {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				days[currentDay] = siteDataState.workHours[currentDay];
				return days;
			}, {});

		return {
			format: siteDataState.workHours.format,
			weeks: onlyWeeks,
		};
	};

	// Lifecycle hooks

	useEffect(() => {
		debounceLoading(false);
		setUserIdInCookieAndState();

		const editorListener = (event: {
			data: { type: string; timestamp: any };
		}): void => {
			const { type, timestamp } = event.data;
			if (type === 'refresh') setUpdatePreview(timestamp);
		};

		window.addEventListener('message', editorListener, false);
		return () => window.removeEventListener('message', editorListener);
	}, []);
	useEffect(() => {
		setSiteDataState(siteData.data);
		setPreviewLoading(state => ({
			...state,
			isAnimating: siteData.loading,
			/* eslint no-bitwise: ["error", { "allow": ["^"] }] */
			key: state.isAnimating ? state.key : state.key ^ 1,
		}));
		setSiteNotFound(siteData.error);
	}, [siteData]);
	useEffect(() => {
		cancelingRequest();

		fetchData().then();
	}, [updatePreview]); // eslint-disable-line
	useEffect(() => {
		if (!previewMode && siteDataState.id && userId) {
			if (localStorage.getItem('countryCode')) {
				pageEventRun(localStorage.getItem('countryCode') || '');
			} else {
				getCountryCode()
					.then(({ data }) => {
						localStorage.setItem('countryCode', data.country_code);
						pageEventRun(data.country_code);
					})
					.catch(error => console.log('getCountryCode - ', error));
			}
		}

		if (siteDataState) {
			setNameColor(siteDataState.appearance.color);
			setDescriptionColor(siteDataState.appearance.color);
			setContentTextColor(siteDataState.appearance.color);
			setBackground(
				(siteDataState.appearance.background.image
					? `center / cover no-repeat url('${siteDataState.appearance.background.image}') `
					: '') + siteDataState.appearance.background.colorGradient,
			);
			setTheme(siteDataState.appearance.theme);
			setButtonBackground(siteDataState.buttonsAppearance.background);
			setButtonColor(siteDataState.buttonsAppearance.color);
			setButtonStyle(siteDataState.buttonsAppearance.style);
			setButtonCornerRadius(siteDataState.buttonsAppearance.cornerRadius);
			setPageStyles({
				color: siteDataState.appearance.color,
				align: siteDataState.appearance?.headerLayout?.align,
				background:
					(siteDataState.appearance.background.image
						? `center / cover no-repeat url('${siteDataState.appearance.background.image}') `
						: '') + siteDataState.appearance.background.colorGradient,
			});

			setGoogleData(siteDataState.location);
			setWorkHours(siteDataState.workHours ? normalizedWorkHours() : null);

			if (siteDataState.appearance.font) {
				const getFontData: any = chooseFont(siteDataState.appearance.font);
				setNameFont(getFontData);
			}
			let blocksAnimationCounter = 0;
			// eslint-disable-next-line
			siteDataState.blocks && siteDataState.blocks.length ? sortByKey(siteDataState.blocks, 'position').map((block) => {
						if (block.highlightAnimation === 'pulse' && !block.hidden) {
							blocksAnimationCounter++;
						}
				  })
				: '';
			setBlocksWithHighlight(blocksAnimationCounter);
		}
	}, [siteDataState]);

	useUpdateCssScrollWidthVariable();

	return (
		<SiteContext.Provider value={memoizationContext}>
			<HelmetComponent siteData={siteDataState} />
			<PreloadingData preloadingFont={nameFont} previewMode={previewMode} />
			<AnimatePresence exitBeforeEnter>
				{firstLoading ? (
					<motion.div
						key='loading'
						initial='init'
						animate='animate'
						exit='exit'
						variants={previewShowAnimation}
						transition={previewShowTransition}
						className='site-loading'
					>
						<Loader spinner={false} />
					</motion.div>
				) : siteNotFound ? (
					<motion.div
						key='notFound'
						initial='init'
						animate='animate'
						exit='exit'
						variants={previewShowAnimation}
						transition={previewShowTransition}
					>
						<NotFound />
					</motion.div>
				) : (
					<motion.div
						key='site'
						initial='init'
						animate='animate'
						exit='exit'
						variants={previewShowAnimation}
						transition={previewShowTransition}
						className='site-wrapper'
					>
						<div
							className={`
                site-page
                ${previewMode ? 'site-page_preview' : ''}
                ${
									siteDataState.appearance?.headerLayout
										? `site-page_${siteDataState.appearance?.headerLayout?.align}`
										: ''
								}
              `}
						>
							<Background
								backgroundImage={siteDataState.appearance.background.image}
								themeName={siteDataState.appearance.theme}
								backgroundStyles={{
									image: background,
									color: theme,
									lottie:
										siteDataState.appearance.background.layers &&
										siteDataState?.appearance?.background.layers[0].type ===
											'lottie'
											? {
													animation:
														siteDataState?.appearance?.background?.layers[0]
															.value,
											  }
											: null,
								}}
							/>

							{/* - INFO: header layout - */}
							{siteDataState.appearance.headerLayout &&
							siteDataState.appearance.headerLayout.bgStyle !== 'none' ? (
								<div
									className={`
                    site-page__bg-blur
                    site-page__bg-blur_${siteDataState.appearance?.headerLayout?.bgStyle}
                    site-page__bg-blur_${siteDataState.appearance?.headerLayout?.imageShape}
                  `}
								>
									<picture>
										<source srcSet={siteDataState.image} />
										<img src={defaultAvatar} alt='img' />
									</picture>
								</div>
							) : (
								''
							)}
							{/* - header layout - */}

							<div
								className={`site-page__container ${
									!onBoarding && (isMobile || isMediaMobile)
										? 'site-page__container_with-offset'
										: ''
								} container`}
							>
								<ProgressBar
									isAnimating={previewLoading.isAnimating}
									steps={false}
									spinner={false}
									incrementDuration={0}
									key={previewLoading.key}
								/>

								{/* - INFO: image user - */}
								<picture
									className={`
                    site-page__image
                    ${
											siteDataState.appearance.headerLayout
												? `site-page__image_${siteDataState.appearance.headerLayout.imageShape}`
												: ''
										}
                  `}
								>
									<img
										src={
											siteDataState.image ? siteDataState.image : defaultAvatar
										}
										alt='avatar'
									/>
								</picture>
								{/* - image user - */}

								{/* - INFO: share functional - */}
								{!previewMode ? (
									<Share
										siteData={siteData.data}
										setShowSharedMenu={setShowSharedMenu}
										showSharedMenu={showSharedMenu}
									/>
								) : (
									''
								)}
								{!previewMode ? (
									<RWebShare
										data={{
											url: window.location.href,
											title: 'Check out this Lift.bio',
										}}
									>
										<button
											onTouchStart={() =>
												shareClickEvent({
													siteId: siteData.data.id,
													platform: 'mobile',
													type: 'share',
													userId: cookies.get('uid'),
												})
											}
											className='mob-share'
											type='button'
										>
											<img src={shareIcon} alt='share' />
										</button>
									</RWebShare>
								) : (
									''
								)}
								{/* - share functional - */}

								{/* - INFO: user information - */}
								<Title
									styles={styles.name}
									title={siteDataState.name}
									verified={siteDataState.verified}
								/>
								{siteDataState.description ? (
									<p
										className='site-page__description'
										style={styles.description}
									>
										{siteDataState.description}
									</p>
								) : (
									''
								)}
								{googleData ? (
									<GoogleInformation data={googleData} style={styles} />
								) : (
									''
								)}
								{workHours ? <WorkHours data={workHours} style={styles} /> : ''}
								{/* - user information - */}

								{/* - INFO: blocks - */}
								<div className='site-page__wrapper'>
									{siteDataState.socialLinks.length ? (
										<div className='site-page__links'>
											<LinksList
												links={sortByKey(siteDataState.socialLinks, 'position')}
												color={nameColor}
											/>
										</div>
									) : (
										''
									)}

									{siteDataState.blocks && siteDataState.blocks.length ? (
										<div
											className='site-page__blocks'
											style={
												siteDataState.structure.buttonsPosition === 1
													? { order: -1 }
													: {}
											}
										>
											<BlocksList
												blocksWithHighlight={blocksWithHighlight}
												isLoading={previewLoading.isAnimating}
												blocks={sortByKey(siteDataState.blocks, 'position')}
												buttonsStyles={styles.button}
												textStyles={styles.text}
												paginationColor={styles.text.color}
												buttonStylesAdditionally={buttonStyle}
												borderColor={
													siteDataState.buttonsAppearance.borderColor
												}
												avatar={siteDataState.image}
											/>
										</div>
									) : (
										''
									)}

									{siteDataState.buttons &&
									siteDataState.buttons.length &&
									!siteDataState.blocks ? (
										<div
											className='site-page__buttons'
											style={
												siteDataState.structure.buttonsPosition === 1
													? { order: -1 }
													: {}
											}
										>
											<ButtonsList
												buttons={sortByKey(siteDataState.buttons, 'position')}
												buttonsStyles={styles.button}
												buttonStylesAdditionally={buttonStyle}
												borderColor={
													siteDataState.buttonsAppearance.borderColor
												}
											/>
										</div>
									) : (
										''
									)}
								</div>
								{/* - blocks - */}

								{showLogo &&
								!showCreateBioSiteButton &&
								!showCreateBioSiteLink ? (
									<LogoComponent icon={siteDataState.logo || defaultLogo} />
								) : (
									''
								)}
								{showCreateBioSiteLink ? (
									<CreateBioSiteLink
										siteData={siteData.data}
										icon={siteDataState.logo}
									/>
								) : (
									''
								)}
							</div>

							{/* - INFO: overlay for share popup - */}
							<AnimatePresence>
								{showSharedMenu ? (
									<motion.div
										initial={{
											opacity: 0,
										}}
										animate={{
											opacity: 1,
										}}
										exit={{
											opacity: 0,
										}}
										transition={{
											type: 'spring',
											damping: 10,
											stiffness: 100,
										}}
										className='mask-shared'
									/>
								) : (
									''
								)}
							</AnimatePresence>
							{/* - overlay for share popup - */}
						</div>
						{showCreateBioSiteButton && !landingFeaturedBioSites ? (
							<CreateBioSiteButton
								siteData={siteData.data}
								setClicked={setClicked}
							/>
						) : (
							''
						)}
						{landingFeaturedBioSites ? (
							<FeaturedBioSiteBanner url={siteData.data.url} />
						) : (
							''
						)}
						<div className='link-group'>
							{!onBoarding ? <Report color={styles.name} /> : null}
							<Link
								href={process.env.REACT_APP_BUILD_LIFT_BIO_LINK}
								target='_blank'
								className={`
									lift-bio-link
									${previewMode ? 'lift-bio-link_disabled' : ''}
								`}
								style={{ ...styles.name }}
							>
								Made with Lift Bio
							</Link>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</SiteContext.Provider>
	);
};

export default Site;
