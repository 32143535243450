import React, { useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import defaultImage from '../../../../../../../assets/images/Image_sceleton.jpg';
import { ReactComponent as ShopIcon } from '../../../../../../../assets/svg/shoppble.svg';
import { OPEN_LINK_IN_EDITOR } from '../../../../../../../constants/postMessageNames';
import { beautifyPrice } from '../../../../../../../helpers';
import getParameterByName from '../../../../../../../helpers/getParameterByName';
import { useSendAnalyticsEvent } from '../../../../../../../hooks';
import useOnLoadImages from '../../../../../../../hooks/useOnloadImages';
import { sendPostMessage } from '../../../../../../../services/sendPostMessage';
import styles from './Item.module.scss';

// Props Types
interface ItemProps {
	blockId: string;
	data: any;
	amount: number;
	tooLongPrice: boolean;
}

export const Item: React.FC<ItemProps> = ({
	blockId,
	data,
	amount,
	tooLongPrice,
}) => {
	const itemRef = useRef(null);
	const imagesLoaded = useOnLoadImages(itemRef);

	const getLinkAttr = () => {
		if (isDesktop) {
			return { href: data.url, target: '_blank', rel: 'noreferrer' };
		}
		return {};
	};

	const sendAnalyticsAndOpenLink: (event: any) => void = useSendAnalyticsEvent({
		type: data.type,
		url: data.url,
		data: {
			blockId,
			eventAction: 'out-click',
			subBlockId: data.id,
		},
	});

	const onClick = (event: any) => {
		if (getParameterByName('previewMode')) {
			event.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url: data.url });
			return;
		}

		sendAnalyticsAndOpenLink(event);
	};

	return (
		<a
			{...getLinkAttr()}
			ref={itemRef}
			onClick={onClick}
			className={`${styles['item']} ${
				amount === 1 ? styles['item_single'] : ''
			}`}
			data-testid='item'
		>
			<picture>
				<img
					className={styles['item__image']}
					src={imagesLoaded ? data.image : defaultImage}
					alt='product'
				/>
				<div className={styles['item__icon']}>
					<ShopIcon
						className={`${styles['icon-svg']} ${styles['item__icon-svg_shop']}`}
					/>
				</div>
			</picture>
			<h4 className={styles['item__title']}>{data.title}</h4>
			{data.price.value !== 0 ? (
				<p
					className={`${styles['item__price']} ${
						tooLongPrice ? styles['small'] : ''
					}`}
					title={data.price.currency}
				>
					<span className={styles['item__price-currency']}>
						{data.price.currency}
					</span>{' '}
					<span className={styles['item__price-number']}>
						{beautifyPrice(data.price.value)}
					</span>
				</p>
			) : (
				''
			)}
		</a>
	);
};
