import React from 'react';
import { isMobile } from 'react-device-detect';
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { SingleProduct } from '../SingleProduct';
import styles from './CarouselProducts.module.scss';
import 'swiper/swiper.scss';

interface CarouselProductsProps {
	style: any;
	data: any;
	blockId: string;
}

export const CarouselProducts: React.FC<CarouselProductsProps> = ({
	style,
	data,
	blockId,
}) => {
	if (data.length === 1) {
		return (
			<SingleProduct
				type='single'
				style={style}
				data={data[0]}
				blockId={blockId}
			/>
		);
	}

	return (
		<div className={styles['carousel']}>
			<Swiper
				initialSlide={0}
				spaceBetween={8}
				slidesPerView={1.5}
				grabCursor={isMobile}
				simulateTouch={isMobile}
				allowTouchMove={isMobile}
				noSwiping={isMobile}
				speed={650}
				roundLengths
				mousewheel={
					!isMobile
						? {
								forceToAxis: true,
								sensitivity: 3,
								thresholdDelta: 0,
								thresholdTime: 0,
						  }
						: false
				}
				modules={[Mousewheel]}
				touchEventsTarget='container'
				className={styles['carousel__swiper']}
				breakpoints={{
					679: {
						slidesPerView: 'auto',
						spaceBetween: 16,
					},
				}}
			>
				{data.map(
					(product: any, index: number) =>
						!product.hidden && (
							<SwiperSlide key={index} className={styles['carousel__item']}>
								<SingleProduct
									key={index}
									type='carousel'
									style={style}
									data={product}
									blockId={blockId}
								/>
							</SwiperSlide>
						),
				)}
			</Swiper>
		</div>
	);
};
