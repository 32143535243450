import React from 'react';
import styles from './ProgressBar.module.scss';

interface ContainerProps {
	animationDuration: number;
	isFinished: boolean;
}

const Container: React.FC<ContainerProps> = ({
	animationDuration,
	children,
	isFinished,
}) => (
	<div
		className={styles.progressbar}
		style={{
			opacity: isFinished ? 0 : 1,
			transition: `opacity ${animationDuration}ms linear`,
		}}
	>
		{children}
	</div>
);

export default Container;
