import React from 'react';
import styles from './List.module.scss';

// Props Types
type OpeningHoursTypes = {
	day: string;
	time: string;
};
interface ListProps {
	openingHours: OpeningHoursTypes[];
}

export const List: React.FC<ListProps> = ({ openingHours }) => (
	<div className={styles['list']}>
		<div className={`${styles['list__column']}`}>
			{openingHours.map(({ day }, index: number) => (
				<div
					key={index}
					className={`${styles['list__item']} ${styles['list__item_bold']}`}
				>
					{day}
				</div>
			))}
		</div>
		<div className={`${styles['list__column']}`}>
			{openingHours.map(({ time }, index: number) => (
				<div key={index} className={styles['list__item']}>
					{time}
				</div>
			))}
		</div>
	</div>
);
