import React from 'react';

export function useKeys(keys: any, onPress: any) {
	// eslint-disable-next-line no-param-reassign
	keys = keys?.split(' ').map((key: any) => key.toLowerCase());
	const isSingleKey = keys.length === 1;
	const pressedKeys = React.useRef([]);

	const keyIsRequested = (key: any) => {
		// eslint-disable-next-line no-param-reassign
		key = key?.toLowerCase();
		return keys?.includes(key);
	};

	const addPressedKey = (key: any) => {
		// eslint-disable-next-line no-param-reassign
		key = key?.toLowerCase();
		const update = pressedKeys.current.slice();
		// @ts-ignore
		update.push(key);
		pressedKeys.current = update;
	};

	const removePressedKey = (key: any) => {
		// eslint-disable-next-line no-param-reassign
		key = key.toLowerCase();
		let update = pressedKeys.current.slice();
		const index = update.findIndex(sKey => sKey === key);
		update = update.slice(0, index);
		pressedKeys.current = update;
	};

	const downHandler = ({ key }: any) => {
		const isKeyRequested = keyIsRequested(key);
		if (isKeyRequested) {
			addPressedKey(key);
		}
	};

	const upHandler = ({ key }: any) => {
		const isKeyRequested = keyIsRequested(key);
		if (isKeyRequested) {
			if (isSingleKey) {
				pressedKeys.current = [];
				onPress();
			} else {
				const containsAll = keys.every((i: any) =>
					// @ts-ignore
					pressedKeys.current.includes(i),
				);
				removePressedKey(key);
				if (containsAll) {
					onPress();
				}
			}
		}
	};

	React.useEffect(() => {
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);
		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	}, []);
}

// export function useKeys(key: string, action: () => void) {
//   React.useEffect(() => {
//     function onKeyup(e: any) {
//       if (e.key === key) action();
//     }
//     window.addEventListener('keyup', onKeyup);
//     return () => window.removeEventListener('keyup', onKeyup);
//   }, []);
// }
