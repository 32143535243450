import React from 'react';
import { isIOS } from 'react-device-detect';
import '../../assets/scss/Components/LogoComponent.scss';
import getParameterByName from '../../helpers/getParameterByName';

type LogoComponentProps = {
	icon?: string;
};

const LogoComponent: React.FC<LogoComponentProps> = ({ icon }) => {
	const previewMode = !!getParameterByName('previewMode');

	const logoClick = () => {
		if (isIOS) {
			window.open('https://lift.onelink.me/KndT/gjnlxs63', '_blank');
		} else if (
			icon?.includes('logo/lift_light.svg') === true ||
			icon?.includes('logo/lift.svg') === true
		) {
			window.open(
				`/?utm_source=lift_bio_user&utm_user_referrer=${
					window.location.pathname.split('/')[1]
				}`,
				'_blank',
			);
		}
	};

	return (
		<img
			className={`
        ${
					icon?.includes('logo/lift_light.svg') ||
					icon?.includes('logo/lift.svg')
						? 'logo-component default-logo'
						: 'logo-component'
				}
        ${previewMode ? 'default-logo_disabled' : ''}
      `}
			src={icon}
			alt='logo'
			data-testid='logo-component'
			onClick={!previewMode ? logoClick : () => {}}
		/>
	);
};

export default LogoComponent;
