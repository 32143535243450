import { AnimateSharedLayout } from 'framer-motion';
import React from 'react';
import type { ICategory } from '../../../../types';
import { NODE_ID } from '../../../constants';
import { Item } from '../Item';
import styles from './List.module.scss';

// Types
interface IProps {
	categories: ICategory[];
}

export const List: React.FC<IProps> = ({ categories }) => (
	<AnimateSharedLayout type='crossfade'>
		<div id={NODE_ID.POPUP_HEADER_LIST} className={styles['list']}>
			{categories.map(category => (
				<Item key={category.id} category={category} />
			))}
		</div>
	</AnimateSharedLayout>
);
