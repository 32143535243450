import React from 'react';
import styles from './IconComponent.module.scss';

/**
 * IconComponentInstance *
 * You should to pass the React component to Icon (
 * You can path to disabled boolean value to set disabled or enabled state
 * You can set a with of icon svg
 * You can set a height of icon svg
 */

interface IconComponentComponentProps {
	Icon: any;
	disabled?: boolean;
	width?: number;
	height?: number;
}

export const IconComponent = ({
	Icon,
	disabled,
	width = 32,
	height = 32,
}: IconComponentComponentProps) => (
	<div
		className={
			disabled ? styles['icon-wrapper-disabled'] : styles['icon-wrapper']
		}
	>
		<Icon width={width} height={height} />
	</div>
);
