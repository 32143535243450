import { motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/svg/cross.svg';
import { useEventScroll } from '../../../../../../../hooks';
import { NODE_ID } from '../../constants';
import { usePopupContext } from '../../context';
import { List } from './List';
import styles from './Header.module.scss';

// Props Types
interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
	// Context
	const { title, categories, onClose } = usePopupContext();

	// States
	const [sticky, setSticky] = React.useState(false);

	// Helpers
	const goTop = () => {
		const popup = document.getElementById(NODE_ID.POPUP);
		if (popup) {
			popup.scroll({ top: 0, behavior: 'smooth' });
		}
	};
	useEventScroll(NODE_ID.POPUP, e => {
		setSticky(e.target.scrollTop > 1);
	});

	return (
		<motion.div
			animate={{
				boxShadow: sticky
					? '0 2px 10px rgba(13, 13, 13, 0.15)'
					: '0 2px 10px rgba(13, 13, 13, 0)',
			}}
			transition={{ duration: 0.25 }}
			className={styles['header']}
		>
			<div onClick={goTop} className={styles['header__wrapper']}>
				<h2 className={styles['header__title']}>{title}</h2>
				<motion.button
					onClick={onClose}
					className={styles['header__close']}
					whileTap={{ scale: 0.8 }}
					whileHover={{ rotate: '90deg' }}
					transition={{ duration: 0.25 }}
				>
					<CloseIcon className={styles['header__close-icon']} />
				</motion.button>
			</div>

			{categories.length ? <List categories={categories} /> : null}
		</motion.div>
	);
};
