import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as VerifiedIcon } from '../../../assets/svg/verified.svg';
import { useScrollCounter } from '../../../hooks';
import { useMedia } from '../../../hooks/useCustomMediaQuery';

interface TitleProps {
	title: string;
	styles: {};
	verified: boolean;
}

export const Title = ({ title, styles, verified }: TitleProps) => {
	// States
	const [offsetTitle, setOffsetTitle] = useState(0);

	// Helpers
	const refTitle: any = useRef(null);
	const reached = useScrollCounter(offsetTitle - 10);
	const isMobile = useMedia('s');

	// Animation
	const stickyAnimation = {
		show: {
			paddingTop: isMobile ? 16 : 20,
			paddingBottom: isMobile ? 16 : 20,
			opacity: 1,
			WebkitBackdropFilter: 'saturate(200%) blur(20px)',
			backdropFilter: 'saturate(200%) blur(20px)',
			backgroundColor: 'rgba(255,255,255, .5)',
			transition: {
				ease: 'easeIn',
				duration: 0.25,

				backdropFilter: {
					ease: 'easeIn',
					duration: 0.25,
					delay: 0.15,
				},
				backgroundColor: {
					ease: 'easeIn',
					duration: 0.25,
					delay: 0.15,
				},
				paddingBottom: {
					ease: 'easeIn',
					duration: 0.25,
					delay: 0.1,
				},
				paddingTop: {
					ease: 'easeIn',
					duration: 0.25,
					delay: 0.1,
				},
			},
		},
		hide: {
			paddingTop: isMobile ? 10 : 16,
			paddingBottom: isMobile ? 10 : 16,
			opacity: 0,
			backgroundColor: 'rgba(255,255,255, 0)',
			WebkitBackdropFilter: 'saturate(100%) blur(0px)',
			backdropFilter: 'saturate(100%) blur(0px)',
			transition: {
				ease: 'easeOut',
				duration: 0.5,
				delay: 0.1,
			},
		},
	};
	const stickyTitleAnimation = {
		show: {
			fontSize: isMobile ? '14px' : '16px',
			transition: {
				ease: 'easeIn',
				duration: 0.25,
				delay: 0.1,
			},
		},
		hide: {
			fontSize: isMobile ? '14px' : '24px',
			transition: {
				ease: 'easeOut',
				duration: 0.5,
				delay: 0.1,
			},
		},
	};

	// Hooks
	useEffect(() => {
		if (refTitle?.current) {
			setOffsetTitle(refTitle?.current?.getBoundingClientRect().top);
		}
	}, []);

	return (
		<motion.div className='site-page__title' ref={refTitle}>
			<AnimatePresence exitBeforeEnter>
				{reached && (
					<motion.div
						initial='hide'
						animate='show'
						exit='hide'
						variants={stickyAnimation}
						className='site-page__sticky'
						onTap={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					>
						<div className='container'>
							<motion.h1
								initial='hide'
								className='site-page__name site-page__name_isSticky'
								animate={reached ? 'show' : 'hide'}
								variants={stickyTitleAnimation}
							>
								{title}
							</motion.h1>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			<h1 data-testid='title-h1' className='site-page__name' style={styles}>
				{title}
				{verified ? <VerifiedIcon className='site-page__name_verified' /> : ''}
			</h1>
		</motion.div>
	);
};
