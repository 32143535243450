import { motion } from 'framer-motion';
import React from 'react';
import '../../assets/scss/Components/BlocksList.scss';
import { ReactComponent as TwitterIcon } from '../../assets/svg/socialLinks/twitter.svg';
import { SITE_FOR_AB_TEST } from '../../constants/SiteForABTest';
import { easing } from '../../data/easingData';
import { sortByKey } from '../../helpers';
import {
	ButtonComponent,
	MButtonComponent,
} from '../ButtonComponent/ButtonComponent';
import {
	DonationComponent,
	MDonationComponent,
} from '../DonationComponent/DonationComponent';
import { Loader } from '../elements/Loader';
import GalleryComponent from '../GalleryComponent/GalleryComponent';
import { Accordion } from '../modules/Accordion';
import { Menu, MMenu } from '../modules/Blocks/Menu';
import {
	CarouselProducts,
	GridProducts,
	MultiLinkProduct,
	SingleProduct,
} from '../modules/Blocks/Shoppable';
import { ShopMyLook } from '../modules/Blocks/Shoppable/ShopMyLook';
import { FormComponent } from '../modules/FormComponent';
import { PromoCodeComponent } from '../modules/PromoCodeComponent';
import TextComponent from '../TextComponent/TextComponent';
import TweetComponent from '../TweetComponent/TweetComponent';
import VideoComponent from '../VideoComponent/VideoComponent';

interface BlocksListProps {
	avatar: string;
	blocks: any[];
	buttonsStyles: any;
	textStyles: any;
	paginationColor: string;
	buttonStylesAdditionally: string;
	borderColor: string;
	isLoading: boolean;
	blocksWithHighlight: number;
}
export interface BlockStyles {
	background: string;
	border: string;
	borderColor: string;
	borderRadius: string;
	boxShadow: string;
	buttonStylesAdditionally: string;
	color: string;
}

const BlocksList: React.FC<BlocksListProps> = ({
	avatar,
	blocks,
	buttonsStyles,
	textStyles,
	paginationColor,
	buttonStylesAdditionally,
	borderColor,
	isLoading,
	blocksWithHighlight,
}) => {
	// Data
	const staggerDelay = 0.3;
	const transition = {
		type: 'tween',
		ease: easing[1],
		duration: 1,
	};

	const blockStyles: BlockStyles = {
		...buttonsStyles,
		buttonStylesAdditionally,
		borderColor,
	};

	// States
	const [expanded, setExpanded] = React.useState<false | number>(false);
	const [accordionContentLoader, setAccordionContentLoader] =
		React.useState(false);

	// Animation
	const staggerAnimation = {
		animate: (delay: number) => ({
			transition: {
				staggerChildren: delay,
			},
		}),
	};
	const pulseAnimation = {
		animate: (
			options: [
				buttonsCount: number,
				animations?: any,
				delay?: number,
				index?: number,
			],
		) => {
			const [buttonsCount, animations, delay = 0.3] = options;
			// const duration = transition.duration ?? 1;
			const repeatDelay = buttonsCount * delay;

			return {
				scale: [1, 1.05, 1],
				transition: {
					repeat: Infinity,
					repeatDelay,
					...animations,
				},
			};
		},
	};

	const getSiteName = window.location.pathname.split('/')[1];

	return (
		<motion.div
			className='blocks-list'
			variants={staggerAnimation}
			animate={isLoading ? '' : 'animate'}
			custom={staggerDelay}
		>
			{blocks.map((block, index) => {
				if (block.type === 'button' && !block.hidden) {
					if (block.highlightAnimation === 'pulse') {
						return (
							<MButtonComponent
								key={block.id}
								id={block.id}
								title={block.name}
								url={
									getSiteName === SITE_FOR_AB_TEST
										? `${block.url}${window.location.search}`
										: block.url
								}
								icon={block.thumbnail}
								withIcon={block.refillableThumbnail}
								style={blockStyles}
								// Animation
								variants={pulseAnimation}
								custom={[
									blocksWithHighlight,
									transition,
									staggerDelay,
									index + 1,
								]}
							/>
						);
					}
					return (
						<ButtonComponent
							key={block.id}
							title={block.name}
							url={
								getSiteName === SITE_FOR_AB_TEST
									? `${block.url}${window.location.search}`
									: block.url
							}
							icon={block.thumbnail}
							id={block.id}
							withIcon={block.refillableThumbnail}
							style={blockStyles}
						/>
					);
				}
				if (block.type === 'text' && !block.hidden) {
					return (
						<TextComponent
							key={index}
							content={block.content}
							align={block.style.align}
							format={block.style.format}
							textStyles={textStyles}
						/>
					);
				}
				if (block.type === 'video' && !block.hidden) {
					return (
						<VideoComponent
							key={block.video.id}
							service={block.video.service}
							url={block.video.url}
							id={block.video.id}
							style={blockStyles}
						/>
					);
				}
				if (block.type === 'gallery' && !block.hidden) {
					return (
						<GalleryComponent
							key={block.id}
							paginationColor={paginationColor}
							style={blockStyles}
							images={sortByKey(block.images, 'position')}
						/>
					);
				}
				if (block.type === 'tweet' && !block.hidden) {
					return (
						<Accordion
							key={block.id}
							accordionIndex={index}
							contentLoader={accordionContentLoader}
							type={block.type}
							preview={{
								image: block.tweet.authorImage,
								text: block.tweet.text,
								title: block.tweet.authorName,
								Icon: TwitterIcon,
							}}
							style={blockStyles}
							expanded={expanded}
							setExpanded={setExpanded}
						>
							<TweetComponent
								type={block.type}
								analyticsData={{
									blockId: block.id,
									eventAction: 'out-click',
								}}
								url={block.tweet.url}
								onLoad={() => setAccordionContentLoader(true)}
								onExit={() => setAccordionContentLoader(false)}
								placeholder={<Loader spinner />}
							/>
						</Accordion>
					);
				}
				if (block.type === 'donation' && !block.hidden) {
					if (block.highlightAnimation === 'pulse') {
						return (
							<MDonationComponent
								key={block.id}
								styles={buttonsStyles}
								stylesAdditionally={buttonStylesAdditionally}
								blockStyles={blockStyles}
								borderColor={borderColor}
								data={block}
								// Animation
								variants={pulseAnimation}
								custom={[
									blocksWithHighlight,
									transition,
									staggerDelay,
									index + 1,
								]}
							/>
						);
					}
					return (
						<DonationComponent
							key={index}
							styles={buttonsStyles}
							stylesAdditionally={buttonStylesAdditionally}
							blockStyles={blockStyles}
							borderColor={borderColor}
							data={block}
						/>
					);
				}
				if (block.type === 'promoCode' && !block.hidden) {
					return (
						<PromoCodeComponent
							key={block.id}
							stylesButtons={buttonsStyles}
							data={block}
							stylesAdditionally={buttonStylesAdditionally}
							borderColor={borderColor}
						/>
					);
				}
				if (block.type === 'product' && !block.hidden) {
					return (
						<SingleProduct
							key={block.id}
							type='single'
							data={block}
							style={blockStyles}
						/>
					);
				}
				if (block.type === 'multiLinkCard' && !block.hidden) {
					return (
						<MultiLinkProduct key={block.id} data={block} style={blockStyles} />
					);
				}
				if (block.type === 'shopMyLook' && !block.hidden && block.tags.length) {
					return <ShopMyLook key={block.id} data={block} style={blockStyles} />;
				}
				if (block.type === 'productsList' && !block.hidden) {
					if (block.listingView === 'grid') {
						return (
							<GridProducts
								key={block.id}
								blockId={block.id}
								data={sortByKey(block.products, 'position')}
								style={blockStyles}
							/>
						);
					}
					if (block.listingView === 'carousel') {
						return (
							<CarouselProducts
								key={block.id}
								blockId={block.id}
								data={sortByKey(block.products, 'position')}
								style={blockStyles}
							/>
						);
					}
				}
				if (block.type === 'form' && !block.hidden) {
					if (block.display === 'short') {
						return (
							<Accordion
								key={block.id}
								accordionIndex={index}
								contentLoader='disabled'
								type={block.type}
								preview={{
									title: block.title,
									expandIcon: true,
								}}
								style={blockStyles}
								expanded={expanded}
								setExpanded={setExpanded}
							>
								<FormComponent
									className='inside-accordion'
									key={block.id}
									stylesButtons={buttonsStyles}
									data={block}
									stylesAdditionally={buttonStylesAdditionally}
									borderColor={borderColor}
									avatar={avatar}
								/>
							</Accordion>
						);
					}
					if (block.display === 'long') {
						return (
							<FormComponent
								key={block.id}
								stylesButtons={buttonsStyles}
								data={block}
								stylesAdditionally={buttonStylesAdditionally}
								borderColor={borderColor}
								avatar={avatar}
							/>
						);
					}
				}
				if (block.type === 'menu' && !block.hidden) {
					if (block.highlightAnimation === 'pulse') {
						return (
							<MMenu
								key={block.id}
								data={block}
								style={blockStyles}
								// Animation
								variants={pulseAnimation}
								custom={[
									blocksWithHighlight,
									transition,
									staggerDelay,
									index + 1,
								]}
							/>
						);
					}

					return <Menu key={block.id} data={block} style={blockStyles} />;
				}

				return '';
			})}
		</motion.div>
	);
};

export default BlocksList;
