import React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
	children: React.ReactNode;
	elementId?: HTMLElement;
}

export const Portal: React.FC<PortalProps> = ({ children, elementId }) =>
	ReactDOM.createPortal(children, elementId ?? document.body);
