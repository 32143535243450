import { motion } from 'framer-motion';
import React from 'react';
import { useKeys } from '../../../hooks';
import { Portal } from '../Portal';
import styles from './OverlayingPopup.module.scss';

// Props Types
interface OverlayingPopupProps {
	children: React.ReactNode;
	onClose: () => void;
	escapeButton?: boolean;
	classes?: any;
}

// Animation
const popupMotion = {
	initial: 'init',
	animate: 'animate',
	exit: 'exit',
	onAnimationStart: () => {
		document.body.style.overflow = 'hidden';
	},
	onAnimationComplete: (variant: string) => {
		// popup is open
		if (variant === 'animate') {
			document.body.style.overflow = 'hidden';

			return;
		}

		// more than 1 popup is open
		if (document?.querySelectorAll('[role="dialog"]')?.length > 1) {
			document.body.style.overflow = 'hidden';

			return;
		}

		// popup is closed
		document.body.style.overflow = '';
	},
};
const overlayMotion = {
	variants: {
		init: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
		exit: {
			opacity: 0,
		},
	},
};

export const OverlayingPopup: React.FC<OverlayingPopupProps> = ({
	children,
	escapeButton = true,
	onClose,
	classes,
}) => {
	const { popup, overlay } = classes || {};

	// Helpers
	useKeys('Escape', escapeButton ? onClose : () => {});

	return (
		<Portal>
			<motion.div
				role='dialog'
				className={`${styles['popup']} ${popup || ''}`}
				// Animation
				{...popupMotion}
			>
				<motion.button
					type='button'
					onClick={onClose}
					aria-label='Close'
					className={`${styles['popup__overlay']} ${overlay || ''}`}
					// Animation
					{...overlayMotion}
				/>
				{children}
			</motion.div>
		</Portal>
	);
};
