import { motion } from 'framer-motion';
import React from 'react';
import { SiteContext } from '../../../../context/siteContext';
import { sendReport } from '../../../../services/apiLiftBio';
import { Button } from '../../../elements/Button';
import { Dropdown } from '../../../elements/Dropdown';
import { TextField } from '../../../elements/TextField';
import styles from './Form.module.scss';

interface FormProps {
	onClose: () => void;
}

const dropdownData = [
	{
		name: 'problem',
		value: 'fake',
		label: 'Fake',
	},
	{
		name: 'problem',
		value: 'inappropriateContent',
		label: 'Inappropriate content',
	},
	{
		name: 'problem',
		value: 'other',
		label: 'Other',
	},
];

export const Form: React.FC<FormProps> = React.forwardRef(
	({ onClose }, ref: any) => {
		// Context
		const { siteDataState } = React.useContext(SiteContext);

		// States
		const [formData, setFormData] = React.useState<any>({
			name: {
				value: '',
				error: null,
				maxLength: {
					length: 50,
					showIndicator: false,
				},
				autoFocus: {
					focus: true,
					delay: 500,
				},
			},
			email: {
				value: '',
				error: null,
				autoFocus: null,
			},
			problem: {
				value: '',
				error: null,
			},
			details: {
				value: '',
				error: null,
				maxLength: {
					length: 500,
					showIndicator: false,
				},
				autoFocus: null,
			},
		});
		const [button, setButton] = React.useState<any>({
			disabled: true,
			loading: false,
		});

		// Helpers
		const validationRequireFields = () => {
			const { name, email, details, problem } = formData;

			const problemCondition = problem?.value.trim().length;
			const nameCondition = name?.value.trim().length;
			const emailCondition = email?.value.trim().length;
			const detailsCondition = details?.value.trim().length;

			return (
				!nameCondition ||
				!emailCondition ||
				!detailsCondition ||
				!problemCondition
			);
		};
		const updateFormData = (event: any): void => {
			// set data from dropdown
			if (event.name === 'problem') {
				setFormData((state: any) => ({
					...state,
					problem: {
						...state.problem,
						value: event.label,
						error: event.label.trim() === '' ? 'This field is required' : null,
					},
				}));
				return;
			}

			// set data from inputs
			setFormData((state: any) => ({
				...state,
				[event.target.name]: {
					...state[event.target.name],
					value: event.target.value,
					error:
						event.target.value.trim() === '' ? 'This field is required' : null,
				},
			}));
		};

		// Handlers
		const sendFormData = async () => {
			setButton((state: any) => ({
				...state,
				loading: true,
				disabled: true,
			}));

			await sendReport(
				{
					name: formData.name.value,
					email: formData.email.value,
					problem: formData.problem.value,
					details: formData.details.value,
				},
				siteDataState.id,
			)
				.then(() => onClose())
				.catch(error => {
					console.log('sendReport - ', error);

					const { errors } = error.response.data;
					if (errors?.email === 'This value is not a valid email address.') {
						const emailData = {
							value: '',
							error: 'Incorrect email format, please try the correct one',
							autoFocus: {
								focus: true,
							},
						};
						setFormData((state: any) => ({
							...state,
							email: {
								...state.email,
								...emailData,
							},
						}));

						// remove focus on email
						setTimeout(() => {
							setFormData((state: any) => ({
								...state,
								email: {
									...state.email,
									...emailData,
									autoFocus: null,
								},
							}));
						});
					}
				})
				.finally(() => {
					setButton((state: any) => ({
						...state,
						loading: false,
						disabled: false,
					}));
				});
		};

		// Lifecycle hooks
		React.useEffect(() => {
			// remove focus on title field, only for create mode
			setTimeout(() => {
				setFormData((state: any) => ({
					...state,
					name: {
						...state.name,
						autoFocus: null,
					},
				}));
			});
		}, []);
		React.useEffect(() => {
			// validation for button
			setButton((state: any) => ({
				...state,
				disabled: validationRequireFields() || button.loading,
			}));
		}, [formData, button.loading]);

		return (
			<div
				ref={ref}
				className={`
        ${styles['form']}
      `}
			>
				<div className={styles['form__fields']}>
					<TextField
						name='name'
						inputMode='text'
						placeholder='Your name'
						value={formData.name.value}
						error={formData.name.error}
						maxLength={formData.name.maxLength}
						autoFocus={formData.name.autoFocus}
						onChange={updateFormData}
						classes={{
							main: styles['form__text-field'],
							input: styles['form__input'],
						}}
					/>
					<TextField
						type='email'
						name='email'
						inputMode='email'
						placeholder='Your email'
						value={formData.email.value}
						error={formData.email.error}
						maxLength={formData.email.maxLength}
						autoFocus={formData.email.autoFocus}
						onChange={updateFormData}
						classes={{
							main: styles['form__text-field'],
							input: styles['form__input'],
						}}
					/>
					<Dropdown
						options={dropdownData}
						onChange={updateFormData}
						placeholder='Select a problem'
						classes={styles['form__dropdown']}
					/>
					<TextField
						name='details'
						type='textarea'
						inputMode='text'
						placeholder='Details'
						value={formData.details.value}
						error={formData.details.error}
						maxLength={formData.details.maxLength}
						autoFocus={formData.details.autoFocus}
						onChange={updateFormData}
						classes={{
							main: styles['form__text-field'],
							textarea: styles['form__textarea'],
						}}
					/>
				</div>
				<div className={styles['form__action']}>
					<Button
						text='Submit'
						onclick={sendFormData}
						loading={button.loading}
						disabled={button.disabled}
						classes={{
							button: styles['form__send-button'],
						}}
					/>
				</div>
			</div>
		);
	},
);

export const MotionForm = motion(Form);
