import React from 'react';
import type { IItem, INormalizedCategory } from '../../../types';
import { CONSTANTS } from '../../constants';
import { Category } from '../Category';
import { WithoutCategory } from '../WithoutCategory';
import styles from './List.module.scss';

// Types
interface IProps {
	categories: INormalizedCategory[];
	itemsWithoutCategory: IItem[];
}

export const List: React.FC<IProps> = ({
	itemsWithoutCategory,
	categories,
}) => (
	<div className={styles['list']}>
		{categories.map(category => (
			<Category key={category.id} category={category} />
		))}

		{itemsWithoutCategory.length ? (
			<WithoutCategory
				key={CONSTANTS.NONE_CATEGORY}
				category={{
					id: CONSTANTS.NONE_CATEGORY,
					items: itemsWithoutCategory,
				}}
			/>
		) : null}
	</div>
);
