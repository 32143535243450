import Cookies from 'universal-cookie';

declare global {
	interface Window {
		gtag: any;
	}
}

export default function generateClientId() {
	const cookies = new Cookies();
	if (window.gtag) {
		window.gtag(
			'get',
			`${process.env.REACT_APP_BUILD_GTAG}`,
			'client_id',
			(clientID: string) => {
				cookies.set('client_id', clientID, { path: '/' });
			},
		);
	}
}
