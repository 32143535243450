import React, { useEffect, useState } from 'react';
import { Item } from '../Item';
import styles from './Body.module.scss';

// Props Types
interface BodyProps {
	data: any;
	actualizeArr: any[];
}

export const Body: React.FC<BodyProps> = ({ data, actualizeArr = [] }) => {
	const { tags, id } = data;
	const [tooLongPrice, setTooLongPrice] = useState(false);

	useEffect(() => {
		tags.forEach((tag: any) => {
			if (tag.price.value.toString().length > 7 && !tooLongPrice) {
				setTooLongPrice(true);
			}
		});
	});

	return (
		<div className={styles['body']}>
			<div
				className={`${styles['body__list']} ${
					tags?.length === 1 ? styles['body__list_single'] : ''
				}`}
			>
				{actualizeArr.map((item: any, index: number) =>
					!item.hidden ? (
						<Item
							blockId={id}
							data={item}
							amount={tags?.length}
							key={index}
							tooLongPrice={tooLongPrice}
						/>
					) : (
						''
					),
				)}
			</div>
		</div>
	);
};
