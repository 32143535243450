import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check.svg';
import { ReactComponent as CopyIcon } from '../../../assets/svg/copycode.svg';
import { ReactComponent as Mask0px } from '../../../assets/svg/promoMasks/maskDesktop-0px.svg';
import { ReactComponent as Mask4px } from '../../../assets/svg/promoMasks/maskDesktop-4px.svg';
import { ReactComponent as Mask16px } from '../../../assets/svg/promoMasks/maskDesktop-16px.svg';
import { ReactComponent as MaskMob0px } from '../../../assets/svg/promoMasks/maskMob-0px.svg';
import { ReactComponent as MaskMob4px } from '../../../assets/svg/promoMasks/maskMob-4px.svg';
import { ReactComponent as MaskMob16px } from '../../../assets/svg/promoMasks/maskMob-16px.svg';
import { OPEN_LINK_IN_EDITOR } from '../../../constants/postMessageNames';
import { getCurrentRadius, hexToRgb } from '../../../helpers';
import getParameterByName from '../../../helpers/getParameterByName';
import { useMediaQuery, useSendAnalyticsEvent } from '../../../hooks';
import { sendPostMessage } from '../../../services/sendPostMessage';
import { IconComponent } from '../../IconComponent/IconComponent';
import styles from './PromoCodeComponent.module.scss';

interface PromoCodeComponentProps {
	stylesButtons: any;
	data: any;
	stylesAdditionally: string;
	borderColor: string;
}

export const PromoCodeComponent = ({
	stylesButtons,
	data,
	stylesAdditionally,
	borderColor,
}: PromoCodeComponentProps) => {
	// States
	const [isCopied, setIsCopied] = React.useState(false);
	const [changeTitleSize, setChangeTitleSize] = useState(false);
	const [svgMask, setSvgMask] = useState<any>(Mask0px);

	// Helpers
	const previewMode = !!getParameterByName('previewMode');
	const refField = useRef(null);
	const useIsMobile = useMediaQuery(`(max-width: ${679}px)`);
	// - hooks
	const sendAnalyticsAndOpenLink: (event: any) => void = useSendAnalyticsEvent({
		type: data.type,
		url: data.url,
		data: {
			blockId: data.id,
			eventAction: 'out-click',
		},
	});

	const getLinkAttr = () => {
		if (isDesktop) {
			return { href: data.url, target: '_blank', rel: 'noreferrer' };
		}
		return {};
	};

	const copyClick = async () => {
		if (isCopied) return;

		await navigator.clipboard?.writeText(data.promoCode.toUpperCase());

		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1500);
	};
	const onButtonClick = async (event: any) => {
		if (getParameterByName('previewMode')) {
			event?.preventDefault();
			sendPostMessage(OPEN_LINK_IN_EDITOR, { url: data.url });
			return;
		}

		sendAnalyticsAndOpenLink(event);
	};

	useEffect(() => {
		if (getCurrentRadius(stylesButtons.borderRadius) === '16px') {
			// @ts-ignore
      useIsMobile ? setSvgMask(MaskMob16px) : setSvgMask(Mask16px);  // eslint-disable-line
		} else if (getCurrentRadius(stylesButtons.borderRadius) === '4px') {
			// @ts-ignore
      useIsMobile ? setSvgMask(MaskMob4px) : setSvgMask(Mask4px);  // eslint-disable-line
		} else {
			// @ts-ignore
      useIsMobile ? setSvgMask(MaskMob0px) : setSvgMask(Mask0px);  // eslint-disable-line
		}
	}, [useIsMobile, stylesButtons.borderRadius]);

	useEffect(() => {
		if (data.title.length > 34) {
			setChangeTitleSize(true);
		}
	}, [data.title]);

	return (
		<div
			className={`
        ${styles['promo-code-component']}
        promo-code-component
        radius-${getCurrentRadius(stylesButtons.borderRadius)}
        ${
					!data.image && !data.discount
						? styles['promo-code-component-no-data-discount']
						: ''
				}
        ${previewMode ? styles['promo-code-component-preview-mode'] : ''}
        ${
					previewMode && !data.image
						? styles['promo-code-component-no-data-preview-mode']
						: ''
				}
      `}
			style={stylesButtons}
		>
			<div className={`${styles['mask']} mask-promo-code`}>
				<IconComponent Icon={svgMask} />
			</div>
			<style>
				{'.promo-code-component  {mask-image:none}'}
				{'.promo-code-component  {-webkit-mask-image:none}'}
				{stylesAdditionally !== 'default'
					? `.mask-promo-code path  {stroke:${borderColor}}`
					: ''}
				{stylesAdditionally === 'shadow'
					? '.mask-promo-code path  {stroke-width:2}'
					: ''}
				{stylesAdditionally === 'shadow'
					? '.mask-promo-code path  {stroke:#000000}'
					: ''}
				{stylesAdditionally === 'shadow'
					? '.promo-code-component  {box-shadow:none !important}'
					: ''}
				{stylesAdditionally === 'filled-with-border'
					? `.promo-code-component  {border-color:${borderColor}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.promo-code-component .promo-code-component__button, .promo-code-component__top_right-discount {background:${stylesButtons.background}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.promo-code-component .promo-code-component__button, .promo-code-component__top_right-discount {color:${stylesButtons.color}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? `.promo-code-component .promo-code-component__button, .promo-code-component__top_right-discount {border:${stylesButtons.border}!important}`
					: ''}
				{stylesButtons.background === 'rgba(0,0,0,0)'
					? '.promo-code-component .promo-code-component__button, .promo-code-component__top_right-discount {border-width:2px !important}'
					: ''}
				{`.promo-code-component__field path {fill:${stylesButtons.color}!important}`}
				{`.promo-code-component__field {border-color:${`${hexToRgb(
					stylesButtons.color,
					0.2,
				)}`}!important}`}
				{'.promo-code-component {background:rgba(0,0,0,0)!important}'}
				{stylesButtons.background.indexOf('linear-gradient') > -1
					? ''
					: `.mask-promo-code path {fill:${stylesButtons.background}`}
			</style>
			<div className={`${styles['promo-code-component__top']}`}>
				{data.image ? (
					<img
						className={`${
							styles['promo-code-component__top_image']
						} radius-${getCurrentRadius(stylesButtons.borderRadius)}`}
						src={data.image}
						alt='promo-code'
					/>
				) : (
					''
				)}
				<div
					style={data.discount ? { justifyContent: 'flex-start' } : {}}
					className={`${styles['promo-code-component__top_right']}`}
				>
					{data.discount ? (
						<div
							style={{
								backgroundColor: stylesButtons.color,
								color: stylesButtons.background,
								borderRadius: stylesButtons.borderRadius,
							}}
							className={`${styles['promo-code-component__top_right-discount']} promo-code-component__top_right-discount`}
						>
							<span
								style={
									stylesButtons.background.indexOf('linear-gradient') > -1
										? { background: stylesButtons.background }
										: {}
								}
								className={`
            ${styles['promo-code-component__top_right-discount_text']}
            ${
							stylesButtons.background.indexOf('linear-gradient') > -1
								? `${styles['promo-code-component__top_right-discount_text-gradient']}`
								: ''
						}
          `}
							>
								{data.discount}%
							</span>
						</div>
					) : (
						''
					)}
					<h3
						className={`${
							previewMode && changeTitleSize
								? styles['promo-code-component__title-small-preview']
								: ''
						} ${styles['promo-code-component__title']} ${
							changeTitleSize ? styles['promo-code-component__title-small'] : ''
						}`}
						style={!data.discount ? { marginTop: 0 } : {}}
					>
						{data.title}
					</h3>
				</div>
			</div>
			<p
				style={{ color: stylesButtons.color }}
				className={`${styles['promo-code-component__description']}`}
			>
				Copy the code and go to the store to claim your discount
			</p>
			<div
				data-testid='promo-code-component__field'
				ref={refField}
				style={
					stylesAdditionally !== 'shadow'
						? stylesButtons
						: {
								border: stylesButtons.border,
								borderRadius: stylesButtons.borderRadius,
						  }
				}
				className={`${styles['promo-code-component__field']} promo-code-component__field`}
				onClick={copyClick}
			>
				{data.promoCode}
				<AnimatePresence>
					{!isCopied ? (
						<motion.div
							key='copy-icon'
							initial={{
								opacity: 0,
								y: '-50%',
								scale: 0,
							}}
							animate={{
								opacity: 1,
								scale: 1,
							}}
							exit={{
								opacity: 0,
								scale: 0,
							}}
							className={`${styles['promo-code-component__field-icon']}`}
						>
							<CopyIcon width={16} height={16} />
						</motion.div>
					) : (
						<motion.div
							key='check-icon'
							initial={{
								opacity: 0,
								y: '-50%',
								scale: 0,
							}}
							animate={{
								opacity: 1,
								scale: 1,
							}}
							exit={{
								opacity: 0,
								scale: 0,
							}}
							className={`${styles['promo-code-component__field-icon']}`}
						>
							<CheckIcon width={16} height={16} />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
			<a
				{...getLinkAttr()}
				onClick={onButtonClick}
				style={{
					backgroundColor: stylesButtons.color,
					color: stylesButtons.background,
					borderRadius: stylesButtons.borderRadius,
				}}
				className={`${styles['promo-code-component__button']} promo-code-component__button`}
			>
				<span
					style={
						stylesButtons.background.indexOf('linear-gradient') > -1
							? { background: stylesButtons.background }
							: {}
					}
					className={`
            ${styles['promo-code-component__button_text']}
            ${
							stylesButtons.background.indexOf('linear-gradient') > -1
								? `${styles['promo-code-component__button_text-gradient']}`
								: ''
						}
          `}
				>
					Visit store
				</span>
			</a>
		</div>
	);
};
