import React from 'react';
import fontData from '../../data/fontData';

interface PreloadingDataProps {
	preloadingFont: string;
	previewMode: boolean;
}
export const PreloadingData = ({
	preloadingFont,
	previewMode,
}: PreloadingDataProps) => (
	<div
		style={{
			position: 'fixed',
			top: '-100%',
			left: 0,
			width: 0,
			opacity: 0,
			visibility: 'hidden',
		}}
	>
		<style>
			{`
          body * {
            font-family: ${preloadingFont};
          }
        `}
		</style>
		{previewMode ? (
			fontData.map((item: any) => (
				<div key={item}>
					<span style={{ fontFamily: item, fontWeight: 400 }}>{item}</span>
					<span style={{ fontFamily: item, fontWeight: 600 }}>{item}</span>
					<span style={{ fontFamily: item, fontWeight: 700 }}>{item}</span>
				</div>
			))
		) : (
			<>
				<span style={{ fontFamily: preloadingFont, fontWeight: 400 }}>
					{preloadingFont}
				</span>
				<span style={{ fontFamily: preloadingFont, fontWeight: 600 }}>
					{preloadingFont}
				</span>
				<span style={{ fontFamily: preloadingFont, fontWeight: 700 }}>
					{preloadingFont}
				</span>
			</>
		)}
	</div>
);
