import { motion, Variants } from 'framer-motion';
import React from 'react';
import { defaultPopupAnimation } from '../../../framer-motion/animation';
import { mainTransition } from '../../../framer-motion/transition';
import { Popup } from '../../elements/Popup';
import { Header } from '../MainPopup/Header';
import styles from './MobileMainPopup.module.scss';

interface MobileMainPopupProps {
	children: React.ReactNode;
	isOpened: boolean;
	onClose: any;
	title?: string;
	name?: string;
	animation?: Variants;
	classes?: string;
}

export const MobileMainPopup: React.FC<MobileMainPopupProps> = ({
	children,
	isOpened,
	onClose,
	title,
	classes = '',
	name,
	animation = defaultPopupAnimation,
}) => (
	<Popup name={name} isOpened={isOpened} onClose={onClose} mobile>
		<motion.div
			initial='hide'
			animate={isOpened ? 'show' : 'hide'}
			exit='hide'
			variants={animation}
			transition={{
				...mainTransition,
				mass: 0.5,
			}}
			className={`${styles['container']} ${classes}`}
		>
			{title ? <Header title={title} onClose={onClose} /> : ''}
			<div className={`${styles['container__content']}`}>{children}</div>
		</motion.div>
	</Popup>
);
