import { motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/svg/cross.svg';
import styles from './Header.module.scss';

// Props Types
interface HeaderProps {
	popupId: string;
	onClose: () => void;
	image: string;
}

export const Header: React.FC<HeaderProps> = ({ image, popupId, onClose }) => {
	// States
	const [scrolling, setScrolling] = React.useState({
		sticky: false,
		reached: false,
	});

	// Lifecycle hooks
	React.useEffect(() => {
		const scrollContainer = document?.getElementById(popupId);
		const getImageHeight = document
			?.getElementById('multi-link-body-image')
			?.getBoundingClientRect().height;

		const scrollHandler = () => {
			if (scrollContainer && getImageHeight) {
				setScrolling(state => ({
					...state,
					sticky: scrollContainer.scrollTop > 1,
					reached:
						getImageHeight !== 0
							? scrollContainer.scrollTop > getImageHeight
							: false,
				}));
			}
		};

		scrollContainer?.addEventListener('scroll', scrollHandler);
		return () => scrollContainer?.removeEventListener('scroll', scrollHandler);
	}, []);

	const goTop = () => {
		const scrollContainer = document?.getElementById(popupId);
		scrollContainer?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<motion.div
			animate={{
				boxShadow: scrolling.sticky
					? '0 2px 10px rgba(13, 13, 13, 0.15)'
					: '0 2px 10px rgba(13, 13, 13, 0)',
			}}
			transition={{
				duration: 0.25,
			}}
			className={`
        ${styles['header']}
      `}
		>
			<div onClick={goTop} className={styles['header__wrapper']}>
				<motion.img
					animate={{
						scale: scrolling.reached ? 1 : 0.9,
						opacity: scrolling.reached ? 1 : 0,
					}}
					transition={{
						duration: 0.25,
					}}
					className={styles['header__image']}
					src={image}
					alt=''
				/>
				<h4 className={styles['header__title']}>Links</h4>
				<motion.div
					onClick={onClose}
					className={styles['header__close']}
					whileTap={{ scale: 0.8 }}
					whileHover={{ rotate: '90deg' }}
					transition={{
						duration: 0.25,
					}}
				>
					<CloseIcon className={styles['header__close-icon']} />
				</motion.div>
			</div>
		</motion.div>
	);
};
