import React from 'react';
import ReactPlayer from 'react-player';
import '../../assets/scss/Components/VideoComponent.scss';
import { getCurrentRadius } from '../../helpers';

type VideoComponentProps = {
	service: string;
	url: string;
	id: string;
	style: any;
};

const VideoComponent: React.FC<VideoComponentProps> = ({
	service,
	url,
	id,
	style,
}) => (
	<div
		className={`video-component video-component_${service}`}
		style={{
			borderRadius: getCurrentRadius(style.borderRadius),
		}}
	>
		{service !== 'tiktok' ? (
			<ReactPlayer
				className='video-component__player'
				width='100%'
				height='100%'
				url={url}
				config={{
					twitch: {
						options: {
							controls: true,
							parent: [
								'localhost',
								'stage.lift.bio',
								'lift.bio',
								'local.lift.bio',
								'stage-edit.lift.bio',
								'edit.lift.bio',
							],
						},
					},
					facebook: {
						attributes: {
							'data-width':
								window.innerWidth <= 679 ? window.innerWidth - 32 : '588px',
						},
					},
					vimeo: {
						playerOptions: {
							className: 'vimeo-video',
							loop: true,
							muted: false,
							showPortrait: true,
							width: '100vw',
							responsive: true,
							controls: true,
							autoplay: false,
						},
					},
				}}
			/>
		) : (
			<iframe
				title='tiktokFrame'
				src={`https://www.tiktok.com/embed/${id}`}
				className='video-component__tiktok-player'
				allowFullScreen
				scrolling={window.innerWidth <= 330 ? 'yes' : 'no'}
				allow='encrypted-media;'
			/>
		)}
	</div>
);

export default VideoComponent;
